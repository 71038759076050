import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Button } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import img from '../images/card-bg2.jpg'
import { sendEmail } from '../services/operations/miscellaneousAPI';

function Newsletter() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState("");

  const handleSignUp = () => {
    if(!email){
      console.error("All fields are mandatory");
      return;
    }
  
    const submitFunc = sendEmail(email, "DeployByDesign");
    submitFunc().then(
      console.log('Newsletter Sign Up Successful')
    ).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Modal size={'xl'} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
            <ModalCloseButton />

            <ModalBody  className='flex gap-x-4'>
            <div className='w-[300px] h-[350px]' style={{backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            </div>
            <div>
            <p className='my-10 font-semibold text-2xl'>Newsletter <br/> Sign-up</p>
            <p>
                Keep up with the latest news and updates about DeployByDesign.
            </p>
            <input type='email' className='mt-6 w-full border rounded-md p-2' placeholder='Email Address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
            <div className='flex bottom-0 mt-6 justify-between'>
            <Button mt={6} colorScheme="blue" onClick={() => {
                handleSignUp();
                onClose();
            }}
            >
                Sign Up
            </Button>
            </div>
            </div>
            </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Newsletter;
