import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addConnection, fetchInfo, listConnection, saveInfo } from '../services/operations/profileAPI';

const Profile = () => {
    const nav = useNavigate();
    const {isLoggedIn, username} = useSelector((state) => state.auth); //getting login state from redux
    const [connections, setConnections] = useState([]);
    const [personalData, setPersonalData] = useState('');

    useEffect(() => {
        if(!isLoggedIn) {
            nav('/login');
        }
    }, [isLoggedIn, nav]);

    const handleBack = () => {
        window.history.back();
    }

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        address: '',
    });

    const handleEditClick = () => {
        setIsEditing((prev) => !prev);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

    const [connectionName, setConnectionName] = useState("")
    const [type, setType] = useState("")
    const [awsUsername, setAwsUsername] = useState("")
    const [awsAccessKey, setAwsAccessKey] = useState("");

    const handleSave = async () => {
        // console.log(formData);
        try {
            const { firstName, lastName, phone, address } = formData;
            
            await saveInfo(firstName, lastName, username, phone, address );
            setIsEditing(false);
        } catch (error) {
            console.error("Error saving personal info: ", error);
        }
    };

    const saveConnection = async () => {
        try {
            await addConnection(username, connectionName, type, awsUsername, awsAccessKey);
            fetchUserConnections();
            fetchUserInfo();

        } catch (error) {
            console.error("Error adding connection: ", error);
        }
    };

    const fetchUserConnections = async () => {
        try {
            const res = await listConnection(username);
            console.log(res.data.connections);
            if (res && res.data.connections) {
                setConnections(res.data.connections);
            }
        } catch (error) {
            console.error("Error fetching connections: ", error);
        }
    };
    
    useEffect(() => {
        if (username) {
            fetchUserConnections();
        }
    }, [username]);

    
    const fetchUserInfo = async () => {
        try {
            const personalInfo = await fetchInfo(username);
            if(personalInfo && personalInfo.data.personal_info){
                setPersonalData(personalInfo.data.personal_info);
            }
            else {
                console.log("No Personal Info");
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    };
    
    useEffect(() => {
        if (username) {
            fetchUserInfo();
        }
    }, [username]);
    


  return (
    <div  className='pt-24'>
        <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
         </div> 
        <div className='flex flex-col sm:w-[60%] mx-auto bg-white rounded-lg'>
            <div className='p-10 flex flex-col gap-y-6'>
                <p className='font-semibold text-base mb-2'>My Profile</p>
                <div className='w-full p-8 shadow-sm flex border justify-between items-center rounded-lg'>
                    <div className='flex gap-x-4'>
                        <div className='flex flex-col justify-evenly'>
                            <p>{username}</p>
                        </div>
                    </div>            
                </div>
                <div className='w-full p-8 shadow-sm flex flex-col border justify-between items-center gap-y-7 rounded-lg'>
                        <div className='w-full flex justify-between'>
                            <div className='font-semibold text-base'>Personal Information</div>
                                <svg onClick={handleEditClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer hover:bg-gray-50 size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                        </div>
                        <div className='w-full flex justify-between'>
                            <div className='w-1/2 flex flex-col gap-y-1'>
                                <p className='text-xs text-gray-500'>First Name</p>
                                {isEditing ? (
                                    <input
                                    type='text'
                                    name='firstName'
                                    value={formData.firstName || ''}
                                    onChange={handleChange}
                                    className='text-sm border p-1 rounded'
                                    />
                                ) : (
                                    <p className='text-sm'>{personalData.first_name}</p>
                                )}
                            </div>
                            <div className='flex-grow flex flex-col gap-y-1'>
                                  <p className='text-xs text-gray-500'>Last Name</p>
                                  {isEditing ? (
                                        <input
                                        type='text'
                                        name='lastName'
                                        value={formData.lastName || ''}
                                        onChange={handleChange}
                                        className='text-sm border p-1 rounded'
                                        />
                                    ) : (
                                        <p className='text-sm'>{personalData.last_name}</p>
                                    )}
                            </div>
                        </div>
                        <div className='w-full flex justify-between'>
                            <div className='w-1/2  flex flex-col gap-y-1'>
                                <p className='text-xs text-gray-500'>Email Id</p>
                                    <p className='text-sm'>{username}</p>
                                                                       
                            </div>
                            <div className='flex-grow flex flex-col gap-y-1'>
                                  <p className='text-xs text-gray-500'>Phone</p>
                                  {isEditing ? (
                                        <input
                                        type='text'
                                        name='phone'
                                        value={personalData.phone}
                                        onChange={handleChange}
                                        className='text-sm border p-1 rounded'
                                        />
                                    ) : (
                                        <p className='text-sm'>{personalData.phone}</p>
                                    )}
                            </div>
                        </div>
                        <div className='w-full flex justify-between'>
                            <div className='w-1/2  flex flex-col gap-y-1'>
                                <p className='text-xs text-gray-500'>Address</p>
                                {isEditing ? (
                                    <input
                                    type='text'
                                    name='address'
                                    value={personalData.address}
                                    onChange={handleChange}
                                    className='text-sm border p-1 rounded'
                                    />
                                ) : (
                                    <p className='text-sm'>{personalData.address}</p>
                                )}
                           </div>
                        </div>
                        {isEditing && (
                        <button className='btn' onClick={handleSave}>
                            Submit
                        </button>
                        )}
                </div>
                <div className='w-full p-8 shadow-sm flex flex-col border justify-between items-center gap-y-7 rounded-lg'>
                    <div className='w-full flex justify-between'>
                        <div className='font-semibold text-base'>Add Connection</div>
                        <div className='flex gap-x-4'>
                            <svg onClick={()=>document.getElementById('my_modal_5').showModal()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="cursor-pointer hover:bg-gray-50 size-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                            </svg>

                            <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
                                <div className="modal-box">
                                    <div className='flex justify-between items-center mb-6'>
                                        <div className='flex flex-col'>
                                            <p className='font-semibold'>Share Connection</p>
                                            <p>Connection Name</p>
                                        </div>
                                        <div className="modal-action">
                                            <form method="dialog">
                                                <button className="btn -mt-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                                </button>
                                            </form>
                                        </div>
                                    </div>

                                    <div className='flex flex-col text-sm gap-y-2'>
                                        <p>Share the group you want to share the connection with</p>
                                        <div className="overflow-x-auto">
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>
                                                    <label>
                                                        <input type="checkbox" className="checkbox" />
                                                    </label>
                                                    </th>
                                                    <td>
                                                    <div className="flex text-start justify-between gap-3">
                                                        <div className="font-bold">ADMIN</div>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </td>                                                 
                                                </tr>
                                                <tr>
                                                    <th>
                                                    <label>
                                                        <input type="checkbox" className="checkbox" />
                                                    </label>
                                                    </th>
                                                    <td>
                                                    <div className="flex text-start justify-between gap-3">
                                                        <div className="font-bold">DEFAULT</div>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </td>                                                 
                                                </tr>
                                                <tr>
                                                    <th>
                                                    <label>
                                                        <input type="checkbox" className="checkbox" />
                                                    </label>
                                                    </th>
                                                    <td>
                                                    <div className="flex text-start justify-between gap-3">
                                                        <div className="font-bold">VIEW_ALL_USER_ENTITIES</div>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </td>                                                 
                                                </tr>
                                                <tr>
                                                    <th>
                                                    <label>
                                                        <input type="checkbox" className="checkbox" />
                                                    </label>
                                                    </th>
                                                    <td>
                                                    <div className="flex text-start justify-between gap-3">
                                                        <div className="font-bold">CLOUD_ARCHITECT</div>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </td>                                                 
                                                </tr>
                                                <tr>
                                                    <th>
                                                    <label>
                                                        <input type="checkbox" className="checkbox" />
                                                    </label>
                                                    </th>
                                                    <td>
                                                    <div className="flex text-start justify-between gap-3">
                                                        <div className="font-bold">ENVIRONMENT_VIEWER</div>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </td>                                                 
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="form-control">
                                            <label className="cursor-pointer gap-x-3 label">
                                                <input type="checkbox" defaultChecked className="checkbox checkbox-warning" />
                                                <span className="label-text">Warning! Other users can access the share connection to connect to virtual machines while deploying environments.</span>
                                            </label>
                                        </div>
                                        <div className='flex justify-between'>
                                            <div></div>
                                            <button className='btn btn-primary'>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </dialog>
                        </div>
                    </div>
                    <div class="w-full flex gap-x-8">
                        <div class="relative z-0 w-full mb-8 group">
                            <input type="text" name="connectionName" value={connectionName} onChange={(e) => setConnectionName(e.target.value)} id="connectionName" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="connectionName" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Connection Name</label>
                        </div>
                        <div class="relative z-0 w-full mb-8 group">
                            <input type="text" name="type" value={type} onChange={(e) => setType(e.target.value)} id="type" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="type" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Type</label>
                        </div>
                    </div>
                    <div className='w-full flex gap-x-8'>
                        <div class="relative z-0 w-full mb-8 group">
                            <input type="text" name="awsUsername" id="awsUsername" value={awsUsername} onChange={(e) => setAwsUsername(e.target.value)} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="awsUsername" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">AWS Username</label>
                        </div>
                        <div class="relative z-0 w-full mb-8 group">
                            <input type="text" name="awsAccessKey" value={awsAccessKey} onChange={(e) => setAwsAccessKey(e.target.value)} id="awsAccessKey" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="awsAccessKey" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">AWS Access Key</label>
                        </div>
                    </div>
                    <div className='w-full flex justify-between'>
                        <button onClick={saveConnection} className='btn'>Save</button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="table">
                        {/* head */}
                        <thead>
                        <tr>
                            <th>Connection Name</th>
                            <th>Type</th>
                            <th>AWS Username</th>
                            <th>AWS Access Key</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(connections) && connections.length > 0 ? (
                                connections.map((connection, index) => (
                                    <tr key={index}>
                                        <td>{connection.connection_name}</td>
                                        <td>{connection.type}</td>
                                        <td>{connection.aws_username}</td>
                                        <td>{connection.aws_access_key}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">No connections found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profile