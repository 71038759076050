import { FormControl, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList, Textarea, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const Email = () => {
    const nav = useNavigate();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); //getting login state from redux
    useEffect(() => {
      if(!isLoggedIn) {
          nav('/login');
      }
  }, [isLoggedIn, nav]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const handleBack = () => {
    window.history.back();
  }

  return (
    <div  className='pt-20'>
        <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
      </svg>
      </div> 
    <div className='px-6 flex flex-col'>
        
        <div className='flex justify-between'>
        <p className='pt-6 pl-6 text-xl font-bold'>Email Automation</p>
        <Menu>
            <MenuButton className='flex'>
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Connect to CRM
                    </button>              
            </MenuButton>
            <MenuList>
                <MenuItem>My Account</MenuItem>
                <MenuItem>Payments </MenuItem>
            </MenuList>

        </Menu>
        </div>
        <div className='flex flex-col md:flex-row p-5 gap-x-3'>
            <div className='border shadow-md bg-white rounded-md sm:w-[100%] md:w-[45%]'>
                <div className='p-2 justify-between flex items-center'>
                    <div className='font-bold text-md'>Sample Customer Complaint Generation</div>
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Send
                    </button>

                </div>
                <div className='py-1 px-3 border flex flex-col'>
                    <VStack align='start'>
                    <p className=' text-gray-500 '>From</p> 
                        <FormControl id="from" isRequired>
                            <Input
                            value={from}
                            type="from"
                            placeholder="demo.user1@astradigit.com"
                            onChange={(e) => setFrom(e.target.value)}
                            />
                        </FormControl>
                        <p className=' text-gray-500 '>To</p> 
                        <FormControl id="to" isRequired>
                            <InputGroup>
                            <Input
                                value={to}
                                type='to'
                                placeholder="demo.user2@astradigit.com"
                                onChange={(e) => setTo(e.target.value)}
                            />
                            </InputGroup>
                        </FormControl>
                        <p className=' text-gray-500 '>Subject</p> 
                        <FormControl id="subject" isRequired>
                            <InputGroup>
                            <Input
                                value={subject}
                                type='subject'
                                placeholder="Assistance Required"
                                onChange={(e) => setSubject(e.target.value)}
                            />
                            </InputGroup>
                        </FormControl>
                        <p className='text-gray-500 '>Body</p> 
                        <FormControl id="body" isRequired>
                            <InputGroup>
                            <Textarea
                                placeholder="Enter the body of the email"
                                value={body}
                                type='body'
                                onChange={(e) => setBody(e.target.value)}
                                h="200px" // Height of the box
                                minH="150px" // Minimum height
                                resize="vertical" // Allow resizing vertically
                                fontSize="lg" // Font size for readability
                            />
                            </InputGroup>
                        </FormControl>
                        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Edit
                    </button>
                        </VStack>
                    </div>
            </div>
            <div className='border shadow-md bg-white rounded-md w-[100%]'>
                <div className='p-3 justify-between flex items-center'>
                    <div className='font-bold text-md'>Gen AI Response Preview</div>
                    <div className='flex gap-x-3'>
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Preview Email
                    </button>                   
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Generate AI Response
                    </button>
                    </div>
                </div>
                <div>
                    <p className='ml-3'>Preview</p>
                    <div className='m-3'>
                    <Textarea
                        // value={body}
                        h="450px" // Height of the box
                        minH="150px" // Minimum height
                        resize="vertical" // Allow resizing vertically
                        fontSize="lg" // Font size for readability
                            />
                    </div>
                    <div className='m-3 flex gap-x-2'>
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Edit
                    </button>                    
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Send
                    </button>                   
                     </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Email