// flowSlice.js
import { createSlice } from '@reduxjs/toolkit';

const flowSlice = createSlice({
  name: 'flow',
  initialState: {
    nodes: [],
    edges: [],
  },
  reducers: {
    clearFlow: (state) => {
      state.nodes = [];
      state.edges = [];
    },
  },
});

export const { clearFlow } = flowSlice.actions;
export default flowSlice.reducer;
