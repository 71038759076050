import { addEdge, Background, Controls, ReactFlow, SelectionMode, useEdgesState, useNodesState } from '@xyflow/react';
import React, { useCallback, useEffect, useState } from 'react'
import { createMetadataAPI, deleteMetadataAPI, deleteMetadataParamAPI, retrieveMetadataAPI, updateMetadataAPI } from '../services/operations/metadataAPI';
import { useSelector } from 'react-redux';

const FlowCanvas = ({filename, version, metadataResponse}) => {
        console.log("refreshed")
        const { username } = useSelector((state) => state.auth);
        console.log("Received Metadata Response: ", metadataResponse)

        const [nodes, setNodes, onNodesChange] = useNodesState([]);
        const [edges, setEdges, onEdgesChange] = useEdgesState([]);
        const [userInput, setUserInput] = useState(false);


        const [selectedNode, setSelectedNode] = useState(null);
        const [port, setPort] = useState("");
        const [user, setUser] = useState("");
        const [password, setPassword] = useState("");
        const [tokens, setTokens] = useState(null);

        const [drawerOpen, setDrawerOpen] = useState(true); 
        const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

          // UseEffect to update fields when selectedNode or metadataResponse changes
          useEffect(() => {
            if (selectedNode && selectedNode?.data?.label)
            {
              console.log(selectedNode);
              const label = selectedNode.data.label;
              console.log("Label: ", label)

              console.log("Metadata Reponse: ", metadataResponse)

              console.log("Metadata Response Data Metadata: ", metadataResponse?.data.metadata)

              if(metadataResponse && metadataResponse?.data.metadata){
                const nodeMetadata = metadataResponse.data.metadata[label];
        
                if (nodeMetadata) {
                  console.log("Mapping metadata to config settings:", nodeMetadata);
                  setPort(nodeMetadata.port || "");
                  setUser(nodeMetadata.user || "");
                  setPassword(nodeMetadata.password || "");
                  setTokens(nodeMetadata.tokens || null);
                } else {
                  console.log(`No metadata found for label: ${label}`);
                  setPort("");
                  setUser("");
                  setPassword("");
                  setTokens(null);
                }
              }
             }
          }, [selectedNode, metadataResponse]);

          

        const onConnect = useCallback(
          (connection) => setEdges((eds) => addEdge(connection, eds)),
          [setEdges],
        );
        
          

      // Update metadata
      const handleUpdate = async () => {
        if (!filename) {
          console.log("File is not created");
          alert("File not created");
          return;
        }
        console.log(username);
        console.log(filename);

        try {
          const metadataContent = {
            [selectedNode.data.label]: {
              port,
              user,
              password,
              tokens: selectedNode.data.category === 'Models' ? tokens : undefined
            }
          };

          console.log("New metadata content:", metadataContent);

          // Update metadata
          const res = await updateMetadataAPI(username, filename, metadataContent);

          if(res){
            console.log("Metadata updated successfully");
          }
        } catch (error) {
          console.error("Error updating metadata:", error);
          alert("Failed to update metadata");
        }
      }


      // delete metadata key
      const handleDeleteKey = () => {
        if(!filename){
          console.log("File is not created");
          handleDeleteNode();
          return;
        }

        console.log(username);
        console.log(filename);
        console.log(selectedNode.data.label);

        const success = deleteMetadataParamAPI(username, filename, selectedNode.data.label);
        
        if(success){
          setNodes((nds) => nds.filter((n) => n.id !== selectedNode.id));
          closeDrawer();
          setIsDeleteModalOpen(false);  
        }
      }

        // on node clicks
        const onNodeClick = useCallback((event, node) => {
          setSelectedNode(node);
          setDrawerOpen(true); // Open drawer when a node is clicked        
        }, []); 

        const onDragOver = useCallback((event) => {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
          }, []);
        
          const onDrop = useCallback(
            (event) => {
              event.preventDefault();
        
              const reactFlowBounds = event.target.getBoundingClientRect();
              const nodeData = JSON.parse(event.dataTransfer.getData('application/reactflow'));

              if(nodeData.label === 'User Input'){
                setUserInput(true);
              }

              const position = {
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
              };
        
              const newNode = {
                id: `${+new Date()}`,
                type: nodeData,
                position,
                data: { label: nodeData.label, category: nodeData.category },
              };
        
              setNodes((nds) => nds.concat(newNode));
            },
            [setNodes]
          );
          
          
        const defaultEdgeOptions = { 
          markerEnd: {
            type: 'arrowclosed',
            style: { stroke: 'black' },
            width: 25,
            height: 25,
          }
        };

        const closeDrawer = () => setDrawerOpen(false);

  const handleDeleteNode = () => {
    setNodes((nds) => nds.filter((n) => n.id !== selectedNode.id));
    closeDrawer();
    setIsDeleteModalOpen(false);
  };

  return (
    <div className='dndflow xs:w-full mx-auto h-[90%] md:w-[90vw] bg-white'>
    <ReactFlow
      className='w-full border p-2'
      nodes={nodes}
      edges={edges}
      onNodeClick={onNodeClick} // Disable node changes if locked
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onDrop={onDrop}
      onDragOver={onDragOver}
      selectionOnDrag={SelectionMode.Partial}
      defaultEdgeOptions={defaultEdgeOptions}
    >
      <Controls />
      <Background variant='dots' />
    </ReactFlow>
    {selectedNode && selectedNode.data.label !== 'user input' && drawerOpen && (
    <div
      id="small-modal"
      tabIndex="-1"
      className={`fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black bg-opacity-50 ${
        drawerOpen ? '' : 'hidden'
      }`}
    >
    <div className="relative w-full max-w-md max-h-full">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <div className='flex flex-col'>
            <div className='flex '>
            <h3 className="text-md font-semibold mr-3">Configuration</h3>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
            />
          </svg>
          </div>
            <p className="text-sm text-gray-500">{selectedNode ? selectedNode.data.label : ''}</p>
          </div>
         
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="small-modal"
            onClick={closeDrawer}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>

            </span>
          </button>
        </div>


        <div className="flex text-sm flex-col gap-y-5 p-4">
          <div className="flex flex-col">
            <label htmlFor="port">Port</label>
            <input
              type="text"
              name="port"
              id="port"
              className="border p-1 rounded-md"
              value={port}
              onChange={(e) => setPort(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="user">Username</label>
            <input
              type="text"
              name="user"
              id="user"
              className="border p-1 rounded-md"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              className="border p-1 rounded-md"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {selectedNode?.data?.category === 'Models' && (
            <div className="flex flex-col">
              <label htmlFor="tokens">No of Tokens</label>
              <input
                type="number"
                name="tokens"
                id="tokens"
                className="border p-1 rounded-md"
                value={tokens}
                onChange={(e) => setTokens(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="flex justify-between items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button onClick={handleUpdate}
            data-modal-hide="small-modal"
            type="button"
            className="text-black border w-fit p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
          >
            Update
          </button>
          <button
            data-modal-hide="small-modal"
            type="button"
            className="text-red-500 border w-fit p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </button>

          {isDeleteModalOpen && (
            <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
              <div className="modal-box bg-white p-5 rounded-md">
                <p className="py-4">Do you really want to delete this resource?</p>
                <div className="modal-action flex justify-end">
                  <button
                    className="text-red-500 border mr-2 w-fit p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
                    onClick={handleDeleteKey}
                  >
                    Yes
                  </button>
                  <button
                    className="w-fit border p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
                    onClick={() => setIsDeleteModalOpen(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)}
    { selectedNode && selectedNode.data.label === 'user input' &&
      (
        <div
            id="small-modal"
            tabIndex="-1"
            className={`fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black bg-opacity-50 ${
              drawerOpen ? '' : 'hidden'
            }`}
          >
          <div className="relative w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <div className='flex flex-col'>
                  <div className='flex '>
                  <h3 className="text-md font-semibold mr-3">Settings</h3>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                  />
                </svg>
                </div>
                  <p className="text-sm text-gray-500">{selectedNode ? selectedNode.data.label : ''}</p>
                </div>
              
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="small-modal"
                  onClick={closeDrawer}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>

                  </span>
                </button>
              </div>


              <div className="flex text-sm flex-col gap-y-5 p-4">
                <div className="flex flex-col">
                  <label htmlFor="user">Username</label>
                  <input
                    type="text"
                    name="user"
                    id="user"
                    className="border p-1 rounded-md"
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="border p-1 rounded-md"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex justify-between items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                    data-modal-hide="small-modal"
                    type="button"
                    className="text-blue-500 border w-fit p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
                    onClick={() => {
                      const chatbotUrl = 'http://localhost:3000/user-input';
                      if (chatbotUrl) {
                        window.open(chatbotUrl, '_blank');
                      } else {
                        alert('Please provide a valid Chatbot URL.');
                      }
                    }}
                  >
                    Open Chatbot
                  </button>
             <button
                  data-modal-hide="small-modal"
                  type="button"
                  className="text-red-500 border w-fit p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Delete
                </button>

                {isDeleteModalOpen && (
                  <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="modal-box bg-white p-5 rounded-md">
                      <p className="py-4">Do you really want to delete this resource?</p>
                      <div className="modal-action flex justify-end">
                        <button
                          className="text-red-500 border mr-2 w-fit p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
                          onClick={handleDeleteKey}
                        >
                          Yes
                        </button>
                        <button
                          className="w-fit border p-2 bg-gray-50 hover:bg-gray-100 rounded-md"
                          onClick={() => setIsDeleteModalOpen(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    
    </div>
  )
}

export default FlowCanvas
