import React from 'react';
import img1 from "../images/card-bg1.jpg";
import img2 from "../images/card-bg2.jpg";
import img3 from "../images/card-bg3.jpg";
import img4 from "../images/card-bg4.jpg";
import img5 from "../images/card-bg9.jpg";
import img6 from "../images/card-bg6.jpg";

const Features = () => {

  return (
    <div>
        <div className='features mt-28 w-full'>
        <div class="card1 shadow-2xl" style={{backgroundImage: `url(${img1})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className='md:w-[65%] xs:w-full'>
            <h1>Autonomous Decision Making</h1>
            <p className='mt-5 text-sm'>Our platform empowers AI agents to act independently by evaluating data, analyzing trends, and making decisions. From automating customer interactions to processing complex queries, these agents ensure faster and more accurate outcomes.</p>
            </div>
        </div>
        <div class="card2" style={{backgroundImage: `url(${img2})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div  className='md:w-[65%] xs:w-full'>
        <h1>Real-Time Adaptability</h1>
            <p className='mt-5 text-sm'>The AI agents can dynamically adapt workflows based on real-time data, enabling businesses to respond instantly to market changes, customer behavior, or operational needs.</p>
            </div>
        </div>
        <div class="card3" style={{backgroundImage: `url(${img3})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div  className='md:w-[65%] xs:w-full'>
        <h1>Task Automation</h1>
            <p className='mt-5 text-sm'>Routine and repetitive tasks like data entry, document processing, and customer service inquiries are seamlessly automated, freeing up valuable resources and time for more critical tasks.</p>            
            </div>
        </div>
        <div class="card4" style={{backgroundImage: `url(${img4})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div  className='md:w-[65%] xs:w-full'>
        <h1>Personalization at Scale</h1>
            <p className='mt-5 text-sm'>AI agents can customize user experiences across the platform by analyzing user preferences, behavior, and interaction history. Whether it's delivering tailored recommendations or adapting content, personalization becomes effortless.</p>
            </div>
        </div>
        <div class="card5" style={{backgroundImage: `url(${img2})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div className='md:w-[65%] xs:w-full'>
            <h1>Seamless Integration</h1>
            <p className='mt-5 text-sm'>Our workflows integrate with your existing systems, databases, and APIs, ensuring a unified and smooth operational flow across all business processes. AI agents can access and process information from multiple sources, making informed decisions that drive efficiency.</p>
            </div>
        </div>
        <div class="card6" style={{backgroundImage: `url(${img6})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div  className='md:w-[65%] xs:w-full'>
            <h1>Data-Driven Insights</h1>
            <p  className='mt-5 text-sm'>With continuous monitoring and learning, AI agents provide actionable insights and analytics, helping you refine workflows, improve decision-making, and optimize operations based on real-time data.</p>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Features