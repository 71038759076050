import React, { useState } from 'react'

const Datasets = () => {

  const [files, setFiles] = useState([{
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }
  ]);

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const currentFiles = files.slice(firstItemIndex, lastItemIndex);

  const totalPages = Math.ceil(files.length / itemsPerPage);

  const handlePageChange = (pgNumber) => {
      setCurrentPage(pgNumber);
  };

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between mb-8'>
        <p className='text-2xl font-semibold'>Datasets</p>
        <button className='btn'  onClick={()=>document.getElementById('my_modal_6').showModal()}>Explore & Request Datasets</button>
        <dialog id="my_modal_6" className="modal">
            <div className="modal-box">
              <p className='text-base font-semibold mb-5'>Datasets</p>
              <div className='bg-gray-200 h-0.5 w-full'></div>
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
              
              <div>
                
              </div>
              <div className='mt-4 flex justify-between'>
                <button className='btn'>Ok</button>
                <form method="dialog">
                    <button className='btn'>Cancel</button>
                  </form>
              </div>
            </div>
          </dialog>
      </div>
      <div className="overflow-x-auto">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th>Dataset Name</th>
              <th>Data Documentation Link</th>
              <th>Data Dictionary</th>
              <th>Coding Instructions</th>
              <th>Analysis Guideslines</th>
            </tr>
          </thead>
          <tbody>
            {currentFiles.length > 0 ? (
            currentFiles.map((file, index) => (
              <tr key={index}>
                <td>{file.dataset_name}</td>
                <td>{file.data_documentation_link}</td>
                <td>{file.data_dictionary}</td>
                <td>{file.coding_instructions}</td>
                <td>{file.analysis_guidelines}</td>
            </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No datasets found</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      <div className="join mt-8 justify-end">
            {Array.from({length: totalPages}, (_, i) => (
                <button key={i+1} className={`join-item btn ${currentPage === i + 1 ? 'btn-active' : '' }`}
                    onClick={() => handlePageChange(i+1)}
                >
                    {i+1}
                </button>

            ))}
        </div>
    </div>
  )
}

export default Datasets