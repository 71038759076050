import { toast } from 'react-hot-toast';
import { apiConnector } from '../apiConnector'
import { uploadDownloadEndpoints } from '../apis'

const {
    LIST_ALL_FILES,
    UPLOAD_FILE,
    DOWNLOAD_FILE,
    DELETE_FILE,
} = uploadDownloadEndpoints;


export async function listAllFiles(email_id) {
    console.log({email_id});
    const toastID = toast.loading('Loading');
    return new Promise(async (resolve, reject) => {
    if (!email_id) {
        toast.error("Please fill all the fields") 
        return;
    }

    try{
        const res = await apiConnector("POST", LIST_ALL_FILES, {
            email_id,
        })

        console.log("List files API Response: ", res)

        if(res.status !== 200){
            throw new Error(res.message)
        }
        resolve(res);
        
        toast.success('Files fetched successfully');
    }
    catch(error) {
        console.log("List files API error: ", error)
        toast.error('Invalid email or password');
        reject(error);
    }
    finally{
        
    toast.dismiss(toastID)
    }
}
)
}

export async function uploadFiles(email_id, file) {
    
    console.log({email_id, file});

    const toastID = toast.loading('Loading');

    if (!email_id || !file) {
        toast.error("No email or file") 
        return;
    }

    try{
        const formData = new FormData();
        formData.append('email_id', email_id); // Append username
        formData.append('file', file);         // Append file

        const res = await apiConnector("POST", UPLOAD_FILE,
            formData
        )

        console.log("UPLOAD FILE API Response: ", res)

        if(res.status !== 200){
            throw new Error(res.message)
        }
        
        toast.success('Uploaded Successfully');
    }
    catch(error) {
        console.log("UPLOAD FILE API ERROR: ", error)
        toast.error('File upload failed ');
    }
    toast.dismiss(toastID)
}

export async function downloadFiles(file_name, email_id) {
    console.log({file_name, email_id});
    const toastID = toast.loading('Loading');
    return new Promise(async (resolve, reject) => {

    if (!email_id) {
        toast.error("No email id!") 
        return;
    }

    try{
        const url = `https://apis.deploybydesign.com/file/download_file/?file_name=${(file_name)}`;
        const res = await apiConnector("POST", url, {
            email_id
        })

        console.log("DOWNLOAD FILE API Response: ", res)

        if(res.status !== 200){
            throw new Error(res.message)
        }
        
        resolve(res);
        toast.success('Download Successful');
    }
    catch(error) {
        console.log("DOWNLOAD FILE API ERROR: ", error)
        toast.error('File Download Failed');
        reject(error);
    }
    finally{

        toast.dismiss(toastID)
    }
})}

export async function deleteFile(email_id, filename) {
    console.log({email_id, filename});
    const toastID = toast.loading('Loading');

    if (!email_id) {
        toast.error("No email id!") 
        return;
    }

    try{
        const res = await apiConnector("POST", DELETE_FILE, {
            email_id, filename
        })

        console.log("Delete File API Response: ", res)

        if(res.status !== 200){
            throw new Error(res.message)
        }
        
        toast.success('File Deleted Successfully');
    }
    catch(error) {
        console.log("Delete File API Error: ", error)
        toast.error('File Delete Failed');
    }
    finally{
        toast.dismiss(toastID)
    }
}