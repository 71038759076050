import React from 'react';
import Footer from './Footer';
import img1 from '../images/bg6.jpg';
import Navbar from './Navbar';

const TermsOfService = () => {

const handleBack = () => {
  window.history.back();
}
  return (
    <div>
      <div className="bg-cover pt-20" style={{ backgroundImage: `url(${img1})` }}>
      <Navbar/>
      <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
      </svg>
      </div> 
        <div className='p-6 mx-auto md:w-[60%] bg-white'>
            <p className='font-extrabold text-2xl p-5'>Terms and Conditions for DeployByDesign</p>
                <p className='text-xl p-5 mb-4'>Welcome to DeployByDesign</p>    

                <p className='text-lg ml-5 mb-3 font-semibold'>These Terms of Service ("Terms") govern your use of the DeployByDesign website at deploybydesign.com ("Website") and the services provided by SURANA DIGITAL (OPC) PRIVATE LIMITED, operating as AstraDigit.
                   By using our Website and services, you agree to these Terms.</p>
                
                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Description of DeployByDesign</p>
                
                
                <p className='ml-5 mb-10'>DeployByDesign is a SaaS platform that offers finance-related products to assist customers in their financial journey.</p>

                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Ownership and Usage Rights</p>

                <p className='ml-5 mb-10'>
                When you purchase a package from our platform, you obtain the right to use it according to the package terms.
                </p>

                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>User Data and Privacy</p>

                <p className='ml-5 mb-10'>
                We collect and store user data, including name, email, and payment information, necessary for providing our services. 
                For more details on how we handle your data, please refer to our Privacy Policy.
                </p>

                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Non-Personal Data Collection</p>

                
                <p className='ml-5 mb-10'>
                We use web cookies to collect non-personal data for the purpose of improving our services and user experience.
                </p>
                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>
                Governing Law
                </p>

                <p className='ml-5 mb-10'>
                These Terms are governed by the laws of the relevant jurisdiction.
                </p>

                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Refund and Cancellation Policy</p>

                <p className='ml-5 mb-10'>
                All purchases made on our platform are final and non-refundable. 
                Please ensure that you have selected the correct product before making a payment.
                </p>

                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Updates to the Terms</p>

                <p className='ml-5 mb-10'>
                We may update these Terms from time to time. Users will be notified of any changes via email.
                </p>

                <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Contact Information</p>
                <p className='ml-5 mb-10'>
                For any questions or concerns regarding these Terms of Service, please contact us at:
                </p>

                <p className='ml-9 mb-10'>
                Email: support@deploybydesign.com<br/>
                Address: C-26, Vidhyut Nagar, Chitrakoot Marg, Near Ajmer Road, Jaipur, India<br/><br/>

                Thank you for using DeployByDesign!<br/>
                </p>
        </div>
        <Footer/>
        </div>
    </div>
  )
}

export default TermsOfService