import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { chatbotAPI } from '../services/operations/chatbotAPI';

const Chatbot = () => {
    const nav = useNavigate();
    const {isLoggedIn, username} = useSelector((state) => state.auth);
    const chatWindowRef = useRef(null);

    useEffect(() => {
      if(!isLoggedIn) {
          nav('/login');
      }
  }, [isLoggedIn, nav]);

  const [msg, setMsg] = useState('');
  const [messages, setMessages] = useState([
     {sender: 'ai' , text: 'Welcome! How can I assist you today?'} 
  ])
  const [isStreaming, setIsStreaming] = useState(false);
  const [streamInterval, setStreamInterval] = useState(null);

  useEffect(() => {
    if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
}, [messages]);

  const handleBack = () => {
    window.history.back();
  }

  function sanitizeHTML(str) {
    const temp = document.createElement('div');
    temp.textContent = str;
    return temp.innerHTML;
  }

  const streamResponse = (text) => {
    setIsStreaming(true);
    let index = -1;
    const interval = setInterval(() => {

      index++;

      if (index < text.length) {
        setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.sender === 'ai') {
                return [
                    ...prevMessages.slice(0, -1),
                    { ...lastMessage, text: lastMessage.text + text[index] }
                ];
            } else {
                return [
                    ...prevMessages,
                    { sender: 'ai', text: text[index] }
                ];
            }
        });
      } else {
        clearInterval(interval);
        setIsStreaming(false);
      }
    }, 10); // Adjust speed here
    setStreamInterval(interval);
};


const stopStreaming = () => {
  clearInterval(streamInterval);
  setIsStreaming(false);
};


  // sendMessage function
 const sendMessage = async() => {
    if(msg.trim().length === 0 || username.trim().length === 0){
      return;
    }

    setMessages((prevMsg) => [
      ...prevMsg,
      { sender: 'user', text: sanitizeHTML(msg) }
    ]);

    setMsg('');

    const typingMessage = { sender: 'ai', text: '...' };
    setMessages((prevMsg) => [...prevMsg, typingMessage]);

    setIsStreaming(true);

    try {
      const res = await chatbotAPI(username, msg);
      let receivedText = res.data.response;

      setMessages((prevMessages) => prevMessages.slice(0, -1));

            // Stream the response text
            streamResponse(receivedText);

    } catch (error) {
      console.log('Chatbot API error:', error);
      setMessages((prevMessages) =>
        prevMessages.slice(0, -1).concat({ sender: 'ai', text: 'Sorry, an error occurred. Please try again later.' })
      );
      setIsStreaming(false);
    }
}

const handleEnterKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    isStreaming? stopStreaming() : sendMessage();
  }
};

const handleReset = () => {
  setMessages([{ sender: 'ai', text: 'Welcome! How can I assist you today?' }]);
};


  return (
    <div className='pt-20'>
        <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
        </div> 
        <div className='flex flex-col mx-auto bg-white shadow-md sm:w-[90%] md:w-[70%] rounded-md border p-4 gap-y-3'>
          <div className='flex justify-between'>
            <div className='flex flex-col'>
                <p className='font-bold text-2xl'>
                  Virtual Assistant
                </p>
                <p className='text-gray-500 text-sm'>Your personal guide to smarter solutions and seamless assistance!</p>
            </div>
            <button className='btn' onClick={handleReset}>   
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
            </button>
          </div>
       

          <div className='bg-gray-300 w-[100%] h-[1px]'></div>
          <div className='h-[400px]' ref={chatWindowRef} id='chat-window'>
              <div id="messages">
                  {messages.map((message, index) => (
                    <div 
                    key={index}
                    className={`message ${message.sender} border p-5 w-fit max-w-[80%] shadow-lg rounded-lg ${
                      message.sender === 'user' ? 'text-right' : 'text-left'
                    }`}
                    >
                        <span className='message-content' style={{ whiteSpace: 'pre-line' }}>{message.text}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div className='float-left w-full flex justify-between'>
              <div className='float-start justify-center items-center w-[100%]'>
                <input
                  type='text'
                  id='topic'
                  name='topic'
                  value={msg}
                  onChange={(e)=>setMsg(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                  className='border border-gray-600 rounded-md p-2 float-left w-full'
                  placeholder='Enter your message...'
                  />
              </div>
              <button onClick={isStreaming? stopStreaming : sendMessage} id='send-message'>
                {isStreaming ? (
                      <i className="ri-stop-line ml-2 text-4xl text-red-600 hover:text-red-800"></i>
                  ) : (
                      <i className="ri-send-plane-2-line ml-2 text-4xl text-gray-600 hover:text-black"></i>
                  )}              
              </button>
            </div>
        </div>
    </div>
  )
}

export default Chatbot