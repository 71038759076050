import React from 'react'
import { logout } from '../redux/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

const Navbar = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const handleLogout = () => {
        dispatch(logout());
        nav('/');
      };

    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div className='w-[100vw]'>
        <div className={`fixed z-10 top-0 shadow-base-content w-screen p-6 flex mb-20 pb-7 justify-between ${
        isLoggedIn ? 'backdrop-blur-xl' : 'bg-white'
      }`}>
            {/* logo */}
            <Link to={('/')} className='pl-6 font-bold text-2xl cursor-pointer'>
                DeployByDesign
            </Link>
            {/* tabs on navbar */}
            <ul className='flex gap-x-9 pr-8 font-semibold text-lg'>
                <div className='md:flex xs:hidden sm:flex sm:gap-x-2 md:gap-x-4'>
                <Link to={isLoggedIn ? '/workspace' : '/workspace-prev'} className='group cursor-pointer flex flex-col'>
                    <p className='group-hover:text-sky-900'>Workspace</p>
                </Link>
                <Menu>
                    <MenuButton className='group cursor-pointer flex flex-col'>
                        <p className='group-hover:text-sky-900'>Support</p>
                    </MenuButton>
                    <MenuList>
                        <MenuItem>
                            <Link to='/tos'>Terms of Service</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/privacy-policy'>Privacy Policy</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/faq">FAQ</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/technical-support">Technical Support</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/data-upload-policy">Data Upload Policy</Link>
                        </MenuItem>
                    </MenuList>
                </Menu>
                <Link to='/contact-us' className='group cursor-pointer flex flex-col'>
                    <p className='group-hover:text-sky-900'>Contact Us</p>
                </Link>
                </div>

                {/* daisyui for xs */}
                <div className="dropdown dropdown-left -mt-2 xs:block sm:hidden md:hidden lg:hidden">
                  <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h7" />
                    </svg>
                  </div>
                <ul
                  tabIndex={0}
                  className="menu menu-md dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                  <li><Link to='/workspace'>Workspace</Link></li>
                  <li>
                  <details open>
                    <summary>Support</summary>
                    <ul>
                      <li><Link to='/tos'>Terms Of Service</Link></li>
                      <li><Link to='/faq'>FAQs</Link></li>
                      <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                      <li><Link to='/technical-support'>Technical Support</Link></li>
                      <li><Link to='/data-upload-policy'>Data Upload Policy</Link></li>
                    </ul>
                    </details>
                  </li>
                  <li><Link to='/contact-us'>Contact Us</Link></li>
                </ul>
                </div>
                {
            !isLoggedIn ?
            (
                <Link to='/login' className='group cursor-pointer flex flex-col'>
                    <button type="button" class="">
                        Login <i class="ri-login-circle-line text-xl"></i>
                    </button>  
                </Link>
            ) : (
    
            <div className='flex pr-6 justify-center items-center'>
            <Menu>
                <MenuButton className='hover:text-sky-800'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                </MenuButton>
                <MenuList>
                    <MenuItem>
                        <Link to='/profile'>My Profile</Link>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>

                </MenuList>
                </Menu>
                </div>
            )
            
            }
            </ul>
          
           
            </div>
        </div>
  )
}

export default Navbar