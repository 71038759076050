import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import img1 from '../images/bg6.jpg';
import { Link } from 'react-router-dom';

const DataUploadPolicy = () => {

    
const handleBack = () => {
    window.history.back();
  }
  
  return (
    <div className="bg-cover pt-20" style={{ backgroundImage: `url(${img1})` }}>
     <Navbar/>
     <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
     <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
   </svg>
   </div> 
   <div className='p-6 mx-auto md:w-[60%] bg-white'>
  <p className='font-extrabold text-3xl p-5'>Data Upload Policy for DeployByDesign</p>
  <p className='text-xl p-5 mb-4'>Data Upload Policy</p>

  <p className='text-lg ml-5 mb-3 font-semibold'>Effective Date: Nov 1, 2024</p>

  <p className='font-semibold ml-5 mb-3 text-md'>Data Upload Policy Overview</p>
  <p className='ml-9 mb-10'>This Data Upload Policy outlines the requirements and guidelines for uploading data to DeployByDesign. By using our platform, you agree to the terms outlined below.</p>

  <p className='font-semibold ml-5 mb-3 text-md'>Guidelines for Data Upload</p>
  <ul className='ml-9 mb-10 list-disc'>
    <li>You must remain the Data Controller for all data uploaded to DeployByDesign and ensure compliance with applicable data protection laws.</li>
    <li>Do not upload any Personal Health Information (PHI), Personal Identifiable Information (PII), or any sensitive information classified by data protection regulations unless authorized.</li>
    <li>DeployByDesign reserves the right to review uploaded data to ensure it complies with the terms. Unauthorized content may be removed without notice.</li>
  </ul>

  <p className='font-semibold ml-5 mb-3 text-md'>Prohibited Content</p>
  <ul className='ml-9 mb-10 list-disc'>
    <li>No virus, malware, spyware, or any harmful content is permitted in uploads.</li>
    <li>Do not upload obscene, defamatory, infringing, or misleading content that violates our Terms of Service (TOS).</li>
  </ul>

  <p className='font-semibold ml-5 mb-3 text-md'>Authorization and Responsibility</p>
  <ul className='ml-9 mb-10 list-disc'>
    <li>By uploading data, you authorize DeployByDesign to scan and verify its compliance with our Data Upload Policy and Terms of Service.</li>
    <li>DeployByDesign holds the right to take corrective actions, including data removal, for any non-compliance.</li>
  </ul>

  <p className='ml-9 mb-10'>
    For more details, please refer to the <Link to='/tos' className='text-blue-600 underline'>Terms of Service</Link>.
  </p>
</div>

s<Footer/>
   </div>
  )
}

export default DataUploadPolicy