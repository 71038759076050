import React from 'react';
import { Box, Center, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import ErrorSVG from '../images/500-error-page.svg';
import Navbar from './Navbar';
import Footer from './Footer';
import img1 from '../images/bg6.jpg';

const InternalServerErrorPage = ({ errorMessage, resetError }) => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        resetError();
        navigate('/', { replace: true });
        window.location.reload();
    };

    return (
        <>
            <div>
                <div className="bg-cover pt-4" style={{ backgroundImage: `url(${img1})` }}>

                    <Navbar />
                    <Center minH="80vh"   bg="white" alignItems='center' className='mt-16' mx={'auto'}>
                        <VStack spacing={6} textAlign="center">
                            <Box width="80%" maxWidth="500px">
                                <img src={ErrorSVG} alt="Internal Server Error Illustration" />
                            </Box>
                            <Heading as="h1" size="2xl" color="#3758F9">
                                500 - Internal Server Error
                            </Heading>
                            <Text fontSize="lg" color="gray.600">
                                {"Oops! Something went wrong on our end. We're working to fix it."}
                            </Text>
                            <Button bg="#3758F9" colorScheme="blue" mb={16} onClick={handleGoHome} _hover={{ bg: '#213594' }}>
                                Go back to Home
                            </Button>
                        </VStack>
                    </Center>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default InternalServerErrorPage;
