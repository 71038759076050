import { toast } from 'react-hot-toast';
import { apiConnector } from '../apiConnector'
import { authEndpoints } from '../apis'
import { login, logout, setLoading } from '../../redux/authSlice'
import { useNavigate } from 'react-router-dom';

const {
    LOGIN_API,
    SIGNUP_API
} = authEndpoints

export function loginAPI(username, password, navigate) {
    return async (dispatch) => {
        const toastID = toast.loading('Loading');
        dispatch(setLoading(true))
        if (!username || !password) {
            toast.error("Please fill all the fields")    
            dispatch(setLoading(false));
            return;
        }

        try{
            const res = await apiConnector("POST", LOGIN_API, {
                username, password,
            })

            console.log("LOGIN API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            dispatch(login(username));
            
            toast.success('Login Successful');

            if(res.status === 200){
                navigate('/')
            }
           
        }
        catch(error) {
            console.log("LOGIN API ERROR: ", error)
            toast.error('Invalid email or password');
        }
        dispatch(setLoading(false))
        toast.dismiss(toastID)
    }
}

export function signupAPI(first_name, last_name, email, password, confirm_password, phone_number, organization_name, navigate) {
    return async (dispatch) => {
        const toastID = toast.loading('Loading');
        if (!first_name || !last_name || !email || !password || !confirm_password || !phone_number || !organization_name) {
            toast.error("Please fill all the fields");
            toast.dismiss(toastID);
            return;
        }

        try {
            const res = await apiConnector("POST", SIGNUP_API, {
                first_name, last_name, email, password, confirm_password, phone_number, organization_name
            });

            console.log("Signup API Response: ", res);

            if (res.status !== 200) {
                throw new Error(res.message || "Signup failed");
            }
            
            toast.success("Sign Up Successful");

            // Navigate to the login page after successful signup
            if (res.status === 200) {
                navigate("/login");
            }
        } catch (error) {
            console.log("SIGNUP API ERROR: ", error);
            toast.error(error.message || "An error occurred during signup");
        } finally {
            toast.dismiss(toastID);
        }
    };
}