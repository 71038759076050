import React from "react";
import InternalServerErrorPage from "./InternalServerErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error) {
   let message = "An unexpected error occurred.";
    if (typeof error === "string") {
      message = error;
    } else if (error && error.message) {
      message = error.message;
    } else {
      try {
        message = JSON.stringify(error);
      } catch {
        message = "Unknown error occurred.";
      }
    }

    return { hasError: true, errorMessage: message };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  resetError = () => {
    this.setState({ hasError: false, errorMessage: "" });
  };

  render() {
    if (this.state.hasError) {
      return <InternalServerErrorPage errorMessage={this.state.errorMessage} resetError={this.resetError} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;