import { toast } from 'react-hot-toast';
import { apiConnector } from '../apiConnector';
import { miscellaneousEndpoints } from '../apis';

const {
    SUBMIT_CONTACT_FORM,
    SUBMIT_SUPPORT_FORM,
    NEWSLETTER,
} = miscellaneousEndpoints

export function supportForm(first_name, last_name, email, confirm_email, phone_number, country, organization, question) {
    return async () => {
        const toastID = toast.loading('Loading');
        if(!first_name || !last_name || !email || !confirm_email || !phone_number || !country || !organization || !question){           
            toast.error("All fields are mandatory")
            return;
        }

        if(email !== confirm_email){
            toast.error("Email and Confirm Email fields do not match");
            return;
        }

        try{
            const res = await apiConnector("POST", SUBMIT_SUPPORT_FORM, {
                first_name, last_name, email, confirm_email, phone_number, country, organization, question
            })

            console.log("SUPPORT FORM API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

        }
        catch(error) {
            console.log("SUPPORT FORM API ERROR: ", error)
            toast.error('Error in sending form');
        }
        finally{
            toast.dismiss(toastID)
        }
    }
}

export function contactForm(first_name, last_name, email, confirm_email, phone_number, country, organization, question) {
    return async () => {
        const toastID = toast.loading('Loading');
        if(!first_name || !last_name || !email || !confirm_email || !phone_number || !country || !organization || !question){           
            toast.error("All fields are mandatory")
            return;
        }

        if(email !== confirm_email){
            toast.error("Email and Confirm Email fields do not match");
            return;
        }

        try{
            const res = await apiConnector("POST", SUBMIT_CONTACT_FORM, {
                first_name, last_name, email, confirm_email, phone_number, country, organization, question
            })

            console.log("CONTACT FORM API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Question Sent Successfully');
        }
        catch(error) {
            console.log("CONTACT FORM API ERROR: ", error)
            toast.error('Error in sending form');
        }
        finally{
            toast.dismiss(toastID)
        }
    }
}



export function sendEmail( email_id , newsletter_type) {
    return async () => {
        const toastID = toast.loading('Loading');
        if( !email_id || !newsletter_type ){           
            toast.error("All fields are mandatory")
            return;
        }


        try{
            const res = await apiConnector("POST", NEWSLETTER, {
                email_id , newsletter_type
            })

            console.log("Newsletter Sign Up  API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Newsletter Sign Up Successful');
        }
        catch(error) {
            console.log("Newsletter Sign Up  API ERROR: ", error)
            toast.error('Error in Newsletter Sign Up ');
        }
        finally{
            toast.dismiss(toastID)
        }
    }
}
