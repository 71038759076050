import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { checkWorkSpaceStatusAPI, createStreamingURLAPI, shareWorkspace, startWorkSpaceAPI, stopWorkSpaceAPI } from '../services/operations/workSpaceAPI';
import { useDispatch, useSelector } from 'react-redux';
import toast from "react-hot-toast";
import {
  Box, Button, Checkbox, FormControl, FormErrorMessage, HStack, Input, IconButton, VStack,
} from "@chakra-ui/react";
import { updateWorkspaceStatus } from '../redux/workspaceSlice';
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";

const Compute = () => {
  const [files, setFiles] = useState([{
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }
  ]);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [startDialogOpen, setStartDialogOpen] = useState(false);

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const currentFiles = files.slice(firstItemIndex, lastItemIndex);

  const totalPages = Math.ceil(files.length / itemsPerPage);
  const { workspaceList, isRunning, isProcessing, workspaceId, streamingURL } = useSelector((state) => state.workspace);

  const { isLoggedIn, username } = useSelector((state) => state.auth);
  const [emails, setEmails] = useState([""]);
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@,]+@[^\s@,]+\.[^\s@,]+$/;
    return emailRegex.test(email);
  };

  const validateField = (email, index) => {
    let errorMessage = "";
    if (!email) {
      errorMessage = "Email is required";
    } else if (!validateEmail(email)) {
      errorMessage = "Invalid email format";
    }
    if (
      email &&
      emails.filter((e, i) => i !== index && e.toLowerCase() === email.toLowerCase()).length > 0
    ) {
      errorMessage = "Email is already added";
    }
    return errorMessage;
  };

  const handleChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    const errorMessage = validateField(value, index);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (errorMessage) {
        newErrors[index] = errorMessage;
      } else {
        delete newErrors[index];
      }
      return newErrors;
    });
  };

  const handleAddEmail = () => {
    const lastIndex = emails.length - 1;
    const lastEmail = emails[lastIndex];
    const errorMessage = validateField(lastEmail, lastIndex);

    if (errorMessage) {
      setErrors((prev) => ({ ...prev, [lastIndex]: errorMessage }));
      return;
    }

    if (emails.length < 5) {
      setEmails([...emails, ""]);
    }
  };

  const handleDelete = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
    setErrors(() => {
      const newErrors = {};
      newEmails.forEach((email, i) => {
        const errorMessage = validateField(email, i);
        if (errorMessage) {
          newErrors[i] = errorMessage;
        }
      });
      return newErrors;
    });
  };
  const isShareDisabled =
    emails.some((email) => !validateEmail(email)) || Object.keys(errors).length > 0;

  const dispatch = useDispatch();
  const intervalCall = useRef(null);
  const getWorkspaceStatus = async (launchPopUp = true) => {
    try {
      const res = await checkWorkSpaceStatusAPI(username);
      if (res.status === 200) {
        setStartDialogOpen(false);
        const ec2 = res?.data?.ec2_status.toString().toLowerCase();
        const appStream = res?.data?.appstream_status.toString().toLowerCase();

        if ((ec2 === 'running' && appStream !== 'running') || (ec2 !== 'running' && appStream === 'running') || (ec2 === 'starting' && appStream === 'starting')) {
          dispatch(updateWorkspaceStatus({ isRunning: false, isProcessing: true }));
        } else if (ec2 === 'running' && appStream === 'running') {
          dispatch(updateWorkspaceStatus({ isRunning: true, isProcessing: false }));
          clearInterval(intervalCall.current);
          if (launchPopUp) {
            document.getElementById('modalLaunchWorkspace').showModal();
          }
        } else if (ec2 === 'stopping' || appStream === 'stopping' || ec2 === 'stopped' || appStream === 'stopped' || ec2 === 'unknown' || appStream === 'unknown') {
          dispatch(updateWorkspaceStatus({ isRunning: false, isProcessing: false }));
          clearInterval(intervalCall.current);
        }
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
  }


  useEffect(() => {
    getWorkspaceStatus(false);
    intervalCall.current = setInterval(getWorkspaceStatus, 300 * 1000);
    return () => clearInterval(intervalCall.current); // Cleanup on unmount
  }, []);

  const handlePageChange = (pgNumber) => {
    setCurrentPage(pgNumber);
  };

  const handleShareWorkspace = async () => {
    let newErrors = {};
    emails.forEach((email, index) => {
      const errorMessage = validateField(email, index);
      if (errorMessage) {
        newErrors[index] = errorMessage;
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      if (emails.length > 0) {
        const response = await shareWorkspace(username, emails);
        document.getElementById('modalshare').close();

        if (response.status === 200) {
          toast.success(response?.data?.message || "Workspace shared successfully");
        }

      } else {
        toast.error('fill the email address')
      }
    } catch (error) {
      console.error('Error sharing workspace:', error);
    } finally {
      document.getElementById('modalshare').close();
    }
  };

  const [workspaceTyp, setWorkspaceTyp] = useState('cpu');

  const handleStartWorkspace = async (type) => {
    try {
      document.getElementById('modalstart').close();

      if (!isLoggedIn || !username) return alert("Please login to start workspace");
      setStartDialogOpen(true);
      const workspace = workspaceList[0];
      const email = username;
      const release = "v1.0";
      const response = await startWorkSpaceAPI(workspace, email, release, type, dispatch);
      intervalCall.current = setInterval(getWorkspaceStatus, 300 * 1000);
      toast.success(response.message || "Workspace start request has been sent successfully");
    } catch (error) {
      toast.error(error.message || 'Error starting workspace');
      console.error('Error starting workspace:', error);
    } finally {
      document.getElementById('modalstart').close();
    }
  }

  const handleStopWorkspace = async () => {
    try {
      document.getElementById('modalstop').close();
      if (!isLoggedIn || !username) return alert("Please login to stop workspace");
      const response = await stopWorkSpaceAPI(workspaceId,
        username, dispatch);
      toast.success(response.message || "Workspace stop request has been sent successfully");
    } catch (error) {
      console.error('Error stopping workspace:', error);
    } finally {
      document.getElementById('modalstop').close();
    }
  }

  const handleGenerateWorkspaceURL = async () => {
    // here we will get the streaming url

    try {
      document.getElementById('modalLaunchWorkspace').close();
      if (!isLoggedIn || !username) return alert("Please login to launch workspace");
      if (!streamingURL) {
        const response = await createStreamingURLAPI(username, dispatch);
        window.open(response?.data?.streaming_url, '_blank');
      } else {
        window.open(streamingURL, '_blank');
      }
    } catch (error) {
      console.error('Error generating workspace URL:', error);
      toast.error(error.message || 'Error generating workspace URL');

    } finally {
      document.getElementById('modalLaunchWorkspace').close();
    }

  }

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between mb-8'>
        <p className='text-2xl font-semibold'>Compute</p>
        <div className='flex gap-x-3'>
          <div className='my-auto'>
            {
              (isRunning && !isProcessing) ? (<button className={`btn cursor-pointer px-4 py-2 text-white font-bold rounded-md bg-blue-500 hover:bg-blue-600`}
                onClick={() => document.getElementById('modalLaunchWorkspace').showModal()}
              >
                Launch Workspace
              </button>) : isProcessing && !isRunning ? (
                <button className={`btn cursor-pointer px-4 py-2 font-bold rounded-md border-2`}>
                  Launching...
                </button>
              ) : ''
            }
          </div>
          {!isProcessing && !isRunning ? (
            <button className={`btn bg-green-500 hover:bg-green-600 text-white`} onClick={() => document.getElementById('modalstart').showModal()}>Start Workspace</button>
          ) : (
            <button className={`btn bg-red-500 hover:bg-red-600 text-white`} onClick={() => document.getElementById('modalstop').showModal()}>Stop Workspace</button>
          )}
          <button className='btn' onClick={() => document.getElementById('modalshare').showModal()}>Share Workspace</button>
        </div>

        <dialog id="modalstart" className="modal z-40">
          <div className="modal-box">
            <p className='text-base font-semibold mb-5'>Launch Workspace</p>
            <div className='bg-gray-200 h-0.5 w-full'></div>
            <form method="dialog">
              <button onClick={() => {
                setStartDialogOpen(false);
              }} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form>
            <div className='flex flex-col gap-y-2 mt-4 mb-4'>
              <p className='text-sm'>Resuming your workspace takes about 30 minutes and the status will be
                displayed as CREATING for this time period.
              </p>
              {/* <p>By clicking OK and accessing this system you accept the following:</p>
                <ul>
                  <li>1. Usage may be monitored, recorded, and subject to audit.</li>
                  <li>2. Usage is authorized only in adherence to the DeployByDesign's <a className='text-teal-500' href='/tos' target='_blank'>Terms of Service.</a></li>
                  <li>3. Use indicates consent to monitoring and recording.</li>
                </ul> */}
            </div>
            <div>
              <p className='mb-2 font-semibold'>Current Workspace Configuration</p>
              <table className='text-sm' style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr style={{ border: '1px solid black' }}>
                    <th style={{ border: '1px solid black', padding: '3px' }}>Type</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>vCPUs</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>Memory</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>GPUs</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>OS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black', padding: '3px' }}>CPU</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>4</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>32GB</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>0</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>Amazon Linux 2</td>
                  </tr>
                </tbody>
              </table>

              {/* <div className='mt-3 mb-3 text-blue-600 flex gap-x-2'>
                <p className='mt-4  text-sm'>Click here to change Workspace Configuration </p>
                {!isOpened ? (
                  <div className='cursor-pointer mt-4' onClick={(e) => setIsOpened(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                  </div>
                ) : (
                  <div className='cursor-pointer mt-4' onClick={(e) => setIsOpened(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                    </svg>
                  </div>
                )}
              </div> */}

              {/* {isOpened && <table className='text-sm' style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr style={{ border: '1px solid black' }}>
                    <th style={{ border: '1px solid black', padding: '3px' }}>Type</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>vCPUs</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>Memory</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>GPUs</th>
                    <th style={{ border: '1px solid black', padding: '3px' }}>OS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black', padding: '3px' }}>
                      <div className='flex gap-x-4'>
                        <input type='radio' name='workspaceType' value="CPU"
                          checked={workspaceTyp === 'CPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                        <p>CPU</p>
                      </div>
                    </td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>4</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>32GB</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>0</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>Amazon Linux 2</td>
                  </tr>
                  <tr style={{ border: '1px solid black' }}>
                    <td style={{ border: '1px solid black', padding: '3px' }}>
                      <div className='flex gap-x-4'>
                        <input type='radio' name='workspaceType' value="GPU"
                          checked={workspaceTyp === 'GPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                        <p>GPU</p>
                      </div>
                    </td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>4</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>32GB</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>0</td>
                    <td style={{ border: '1px solid black', padding: '3px' }}>Amazon Linux 2</td>
                  </tr>

                </tbody>
              </table>
              } */}
            </div>

            <p className='flex gap-x-2 my-2'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-7 mt-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
              </svg>

              <p className='mt-4 text-sm font-semibold'>
                For any additional compute requirements please email at
                <a className='text-blue-500' href='mailto:support@deploybydesign.com'> support@deploybydesign.com</a>
              </p>
            </p>

            <div className='mt-4 gap-4 flex flex-row-reverse '>
              <form method="dialog">
                <button className='px-5 py-2 rounded-md border-solid border-2 border-gray-400'
                  onClick={() => {
                    setStartDialogOpen(false);
                  }}
                >Cancel</button>
              </form>
              <button className='bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-2 rounded' onClick={() => {
                handleStartWorkspace(workspaceTyp)
              }}>
                Start
              </button>

            </div>
          </div>
        </dialog>

        {/* <dialog id="modal" className="modal z-10">
            <div className="modal-box">
              <p className='text-base font-semibold mb-5'>Start Workspace</p>
              <div className='bg-gray-200 h-0.5 w-full'></div>
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
              <div className='mt-4 flex flex-col gap-y-5'>
                <div className='flex gap-x-4'>
                  <p>CPU</p>
                  <input type='radio' name='workspaceType' value="CPU" 
          checked={workspaceTyp === 'CPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                </div>
                <div className='flex gap-x-4'>
                  <p>GPU</p>
                  <input type='radio' name='workspaceType' value="GPU" 
          checked={workspaceTyp === 'GPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                </div>
                <div className='flex justify-between'>
                  <button className='btn'  onClick={()=> {
                    document.getElementById('modalLaunch').showModal()
                    }}>
                      Launch
                  </button>
                  <form method="dialog">
                    <button className='btn'>No</button>
                  </form>
                </div>
              </div>
            </div>
          </dialog> */}
        <dialog id="modalshare" className="modal z-10">
          <div className="modal-box">
            <p className="text-base font-semibold mb-5">Share Workspace</p>
            <div className="bg-gray-200 h-0.5 w-full"></div>
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                ✕
              </button>
            </form>
            <div className="mt-4 flex flex-col gap-y-5">
              <VStack spacing={4} align="stretch">
                {emails.map((email, index) => (
                  <HStack key={index} spacing={2} w="full">
                    <FormControl isInvalid={errors[index]} flex="1">
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => handleChange(index, e.target.value)}
                        placeholder="Enter email"
                      />
                      <FormErrorMessage>{errors[index]}</FormErrorMessage>
                    </FormControl>
                    {emails.length > 1 && (
                      <IconButton
                        aria-label="Delete email"
                        icon={<DeleteIcon />}
                        size="sm"
                        onClick={() => handleDelete(index)}
                        textColor="red"
                      />
                    )}
                  </HStack>
                ))}
                {emails.length < 5 && (
                  <Button
                    size="md"
                    w={32}
                    mx="auto"
                    onClick={handleAddEmail}
                    bg="#3558f9"
                    textColor="white"
                    _hover={{ bg: "#1d4ed8" }}
                  >
                    + Add Email
                  </Button>
                )}
              </VStack>
              <p className="flex gap-x-2 my-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-12 -mt-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
                <p className="text-xs font-semibold">
                  Sharing your workspace also shares access to all of your data, and your scratch area.
                  Please ensure the team member you are sharing with should have access to this data.
                </p>
              </p>
              <div className="mt-4 gap-4 flex flex-row-reverse">
                <form method="dialog">
                  <button className="px-5 py-2 rounded-md border-solid border-2 border-gray-400">
                    Cancel
                  </button>
                </form>
                <button
                  className={`bg-[#3558f9] hover:bg-blue-700 text-white font-semibold px-6 py-2 rounded ${isShareDisabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  onClick={handleShareWorkspace}
                  disabled={isShareDisabled}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </dialog>

        <dialog id="modalstop" className="modal">
          <div className="modal-box">
            <p className='text-base font-semibold mb-5'>Stop Workspace</p>
            <div className='bg-gray-200 h-0.5 w-full'></div>
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form>
            <div className='mt-4 flex flex-col gap-y-5'>
              <p>Are you sure you want to stop your workspace? Stopping your workspace will
                remove any custom configuration changes you have made. All of your uploaded
                data is backed up and restored upon resuming your workspace.
              </p>
              <p>To restore changes you've made to your workspace, save all of your configuration
                code, including package loading commands, to a Jupyter Notebook. Then, run that
                notebook after resuming your workspace.
              </p>
              <p><span className='font-bold'>Note: </span>The above applies only to Auto Scaling Clusters. GPU instance configuration are
                fully restored after resumingwithout any action needed on your part.
              </p>
              <div className='flex flex-row-reverse gap-4'>
                <form method="dialog">
                  <button className='px-6 py-2 rounded-md border-solid border-2 border-gray-400'>No</button>
                </form>
                <button className='px-6 py-2 rounded-md bg-red-400 text-white font-bold hover:bg-red-600 ' onClick={handleStopWorkspace}>Yes</button>

              </div>
            </div>
          </div>
        </dialog>
        <dialog id="modalLaunchWorkspace" className="modal">
          <div className="modal-box">
            <p className='text-base font-semibold mb-5'>Launch Workspace</p>
            <div className='bg-gray-200 h-0.5 w-full'></div>
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form>
            <div className='mt-4 flex flex-col gap-y-5'>
              <p>Are you sure you want to Launch your workspace...?
              </p>
              <p> This will start your workspace in the new tab and will not affect your current workspace.
              </p>

              <div className='flex flex-row-reverse gap-4'>
                <form method="dialog">
                  <button className='px-6 py-2 rounded-md border-solid border-2 border-gray-400'>No</button>
                </form>
                <button className='px-6 py-2 rounded-md bg-blue-500 text-white font-bold hover:bg-blue-600 ' onClick={handleGenerateWorkspaceURL}>Yes</button>

              </div>
            </div>
          </div>
        </dialog>
      </div>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Dataset Name</th>
              <th>Data Documentation Link</th>
              <th>Data Dictionary</th>
              <th>Coding Instructions</th>
              <th>Analysis Guideslines</th>
            </tr>
          </thead>
          <tbody>
            {currentFiles.length > 0 ? (
              currentFiles.map((file, index) => (
                <tr key={index}>
                  <td>{file.dataset_name}</td>
                  <td>{file.data_documentation_link}</td>
                  <td>{file.data_dictionary}</td>
                  <td>{file.coding_instructions}</td>
                  <td>{file.analysis_guidelines}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">No datasets found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="join mt-8 justify-end">
        {Array.from({ length: totalPages }, (_, i) => (
          <button key={i + 1} className={`join-item btn ${currentPage === i + 1 ? 'btn-active' : ''}`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>

        ))}
      </div>
    </div >
  )
}


export default Compute