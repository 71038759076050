import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { workspaceEndpoints } from "../apis";
import { updateWorkspaceStatus, updateWorkspaceIdAndURL, removeWorkspaceIdAndURL } from "../../redux/workspaceSlice";

const { CREATE_WORKSPACE, GET_WORKSPACE, CHECK_WORKSPACE_STATUS, START_WORKSPACE, STOP_WORKSPACE, CREATE_STREAMING_URL, SHARE_WORKSPACE } = workspaceEndpoints

export function workSpaceAPI(data) {
    // {  "email_id": "string",  "create_new_workspace": true,  "selected_configuration": "string",  "data_amount": 0,  "data_unit": "string",  "purpose": "string",  "terms_of_service_agreed": true }
    const { email_id, create_new_workspace, selected_configuration, data_amount, data_unit, purpose, terms_of_service_agreed } = data;
    return new Promise(async (resolve, reject) => {
        if (!email_id || !create_new_workspace || !selected_configuration || !data_amount || !data_unit || !purpose) {
            toast.error("Please fill all the fields")
            return;
        }
        if (!terms_of_service_agreed) {
            toast.error('Please agree to the terms of service');
            return;
        }

        if (data_amount < 0 || data_amount === 0) {
            toast.error("Data amount should be greater than 0")
            return;
        }

        if (data_unit === 'GB' && data_amount > 150) {
            toast.error("Data amount should be less than 150GB")
            return;
        }

        if (data_unit === 'TB' && data_amount > 10) {
            toast.error("Data amount should be less than 10TB")
            return;
        }

        try {
            const res = await apiConnector("POST", CREATE_WORKSPACE, data)


            if (res.status !== 200) {
                throw new Error(res.message)
            }

            resolve(res);

        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
        finally {

        }
    }
    )
}

export function getWorkSpaceAPI(email) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", GET_WORKSPACE, null, null, { email })

            if (res.status !== 200) {
                throw new Error(res.message)
            }

            resolve(res);

        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
    }
    )
}

export function checkWorkSpaceStatusAPI(email_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", CHECK_WORKSPACE_STATUS, null, null, { email_id })

            if (res.status !== 200) {

                reject("error not 200")
            }

            resolve(res);
        }
        catch (error) {
            reject(error)
        }
    }
    )
}

export function startWorkSpaceAPI(instanceName, email, release, type, dispatch) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", START_WORKSPACE, { instance_name: instanceName, email_id: email, workspace_release: release, workspace_type: type }, null, null)
            if (res.status === 500) {
                throw new Error("Something went wrong while starting workspace");
            }
            if (res.status !== 200) {
                throw new Error(res.message)
            }
            dispatch(updateWorkspaceStatus({ isRunning: false, isProcessing: true }));
            dispatch(updateWorkspaceIdAndURL({ workspaceId: res?.data?.ec2_instance_id || '', streamingURL: '' }));
            resolve(res);
        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
    }
    )
}

export function stopWorkSpaceAPI(workspace_id, email_id, dispatch) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", STOP_WORKSPACE, null, null, { workspace_id, email_id })

            if (res.status !== 200) {
                throw new Error(res.message)
            }

            dispatch(updateWorkspaceStatus({ isRunning: false, isProcessing: false }));
            dispatch(removeWorkspaceIdAndURL());

            resolve(res);
        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
    }
    )
}

export function createStreamingURLAPI(email, dispatch) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", CREATE_STREAMING_URL, null, null, { user_id: email })

            if (res.status !== 200) {
                throw new Error(res.message)
            }

            dispatch(updateWorkspaceStatus({ isRunning: true, isProcessing: false }));
            dispatch(updateWorkspaceIdAndURL({ workspaceId: sessionStorage.getItem('workspaceId'), streamingURL: res?.data?.streaming_url || '' }));
            resolve(res);
        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
    }
    )
}

export function shareWorkspace(userEmail, email_ids) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", SHARE_WORKSPACE, { email: userEmail, email_ids }, null, null)
            console.log("Response : ==========================> ", res)
            if (res.status !== 200) {
                throw new Error(res.message)
            }
            resolve(res);
        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
    }
    )
}