import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: !!sessionStorage.getItem('isLoggedIn'),
    loading: false,
    username: sessionStorage.getItem('username') || "",
    accessToken: sessionStorage.getItem('accessToken') || "",
    refreshToken: sessionStorage.getItem('refreshToken') || "",
    expiresIn: sessionStorage.getItem('expiresIn') || "",
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLoggedIn = true;
            state.username = action.payload.username;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.expiresIn = new Date().getTime() + action.payload.expiresIn * 1000;
            sessionStorage.setItem('isLoggedIn', 'true');
            sessionStorage.setItem('username', action.payload.username);
            sessionStorage.setItem('accessToken', action.payload.accessToken);
            sessionStorage.setItem('refreshToken', action.payload.refreshToken);
            sessionStorage.setItem('expiresIn', new Date().getTime() + action.payload.expiresIn * 1000);
            sessionStorage.setItem("isGeneratingToken", 'false');
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setter: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.expiresIn = new Date().getTime() + action.payload.expiresIn * 1000;
            sessionStorage.setItem('accessToken', action.payload.accessToken);
            sessionStorage.setItem('expiresIn', new Date().getTime() + action.payload.expiresIn * 1000);
            sessionStorage.setItem("isGeneratingToken", 'false');
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.accessToken = "";
            state.username = "";
            state.refreshToken = "";
            state.expiresIn = "";
            sessionStorage.removeItem('isLoggedIn');
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('expiresIn');
            sessionStorage.removeItem("isGeneratingToken");
        },
    },
});

export const { login, logout, setLoading, setter } = authSlice.actions;
export default authSlice.reducer;