import { toast } from 'react-hot-toast';
import { apiConnector } from '../apiConnector';
import { blueprintEndpoints } from '../apis';

const {
    CREATE_BLUEPRINT,
    UPDATE_BLUEPRINT,
    DELETE_BLUEPRINT,
    DOWNLOAD_BLUEPRINT,
    RETRIEVE_BLUEPRINT,
    SEARCH_BLUEPRINT,
    LIST_BLUEPRINTS
} = blueprintEndpoints;

export function createBlueprint(email, filename, content) {
    console.log({email, filename, content});
    return new Promise(async (resolve, reject) => {
        const toastID = toast.loading('Loading');
        if (!filename) {
            toast.error("Please provide a filename")
            reject('Filename is missing');
            return;
        }

        try{
            const res = await apiConnector("POST", CREATE_BLUEPRINT, {
                email, filename, content
            })

            console.log("CREATE BLUEPRINT API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Blueprint Created Successfully');
            resolve(res);
        }
        catch(error) {
            console.log("BLUEPRINT API ERROR: ", error)
            toast.error('Error in creating blueprint');
            reject(error);
        }
        finally{
            toast.dismiss(toastID)
        }
    }
)}

export function updateBlueprint(email, filename, content) {
    console.log({email, filename, content});
    return new Promise(async (resolve, reject) => {
        const toastID = toast.loading('Loading');
        if (!filename) {
            toast.error("Filename is missing")
            reject('Filename is missing');
            return;
        }

        try{
            const res = await apiConnector("PUT", UPDATE_BLUEPRINT, {
                email, filename, content
            })

            console.log("UPDATE BLUEPRINT API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Blueprint Updated Successfully');
            resolve(res);
        }
        catch(error) {
            console.log("BLUEPRINT API ERROR: ", error)
            toast.error('Error in Updating blueprint');
            reject(error);
        }
        finally{
            toast.dismiss(toastID)
        }
    }
)}

export function deleteBlueprint(email, filename) {
    console.log({email, filename});
    return async () => {
        const toastID = toast.loading('Loading');
        if (!filename) {
            toast.error("File not present")
            return;
        }

        try{
            const res = await apiConnector("DELETE", DELETE_BLUEPRINT, {
                email, filename
            })

            console.log("DELETE BLUEPRINT API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Blueprint Deleted Successfully');
        }
        catch(error) {
            console.log("DELETE BLUEPRINT API ERROR: ", error)
            toast.error('Error in deleting blueprint');
        }
        finally{
            toast.dismiss(toastID)
        }
    }
}

export async function downloadBlueprint(email, filename) {
    console.log({email, filename});
        const toastID = toast.loading('Loading');
        if (!filename) {
            toast.error("File not present")
            toast.dismiss(toastID)
            throw new Error("Filename is missing");
        }

        try{
            const res = await apiConnector("POST", DOWNLOAD_BLUEPRINT, {
                email, filename
            })

            console.log("DOWNLOAD BLUEPRINT API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            console.log(res.data.url);
            toast.success('Blueprint Link Retreived Successfully');
            window.open(res.data.url, '_blank');
        }
        catch(error) {
            console.log("DOWNLOAD BLUEPRINT API ERROR: ", error)
            toast.error('Error in downloading blueprint');
            throw error;
        }
        finally{
            toast.dismiss(toastID)
        }
    }

    export async function retrieveBlueprintAPI(email, filename) {
            console.log({email, filename});
            const toastID = toast.loading('Loading');
    
            try{
                const res = await apiConnector("POST", RETRIEVE_BLUEPRINT, {
                    email, filename
                })
    
                console.log("RETRIEVE BLUEPRINT API Response: ", res)
    
                if(res.status !== 200){
                    throw new Error(res.message)
                }
                const resMessage = res.data.message || 'Blueprint Fetched Successfully';
                toast.success(resMessage);

                return res.data.blueprint;
            }
            catch(error) {
                const errorMessage = error.message || 'Error in fetching blueprint';
                toast.error(errorMessage);
            }
            finally{
                toast.dismiss(toastID)
            }
    }   

export async function listBlueprintsAPI(email) {
    console.log({email});

    try{
        const res = await apiConnector("POST", LIST_BLUEPRINTS, {
            email
        })

        console.log("LIST BLUEPRINT API Response: ", res)

        if(res.status !== 200){
            throw new Error(res.message)
        }
        const resMessage = res.data.message || 'Blueprint Listed Successfully';

        return res.data.blueprints;
    }
    catch(error) {
        const errorMessage = error.message || 'Error in listing blueprint';
        toast.error(errorMessage);
        return [];
    }
    finally{
    }
    
}