import { useSelector } from "react-redux";

export function encodePassphrase(passphrase) {
    return encodeURIComponent(passphrase);
}

export function decodePassphrase(base64String) {
    return decodeURIComponent(base64String);
}

export function generateRoomId(length = 4) {
    return `${randomString(length)}-${randomString(length)}`;
}

export function randomString(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getAccessToken() {
    const token = sessionStorage.getItem('accessToken');
    return token;
}


export const generateToken = (roomId) => {
    // const API_KEY = process.env.REACT_APP_LIVEKIT_API_KEY;
    // const API_SECRET = process.env.REACT_APP_LIVEKIT_API_SECRET;
    // const identity = `user-${Math.random().toString(36).substr(2, 9)}`; // Random user identity

    // // Create a JWT token
    // const payload = {
    //     iss: API_KEY,
    //     sub: identity,
    //     room: roomId,
    //     exp: Math.floor(Date.now() / 1000) + 3600, // Token expires in 1 hour
    // };

    // const header = { alg: 'HS256', typ: 'JWT' };
    // const encodedHeader = btoa(JSON.stringify(header));
    // const encodedPayload = btoa(JSON.stringify(payload));
    // const signature = btoa(
    //     new TextEncoder().encode(
    //         `${encodedHeader}.${encodedPayload}`,
    //         API_SECRET,
    //     )
    // );

    // return `${encodedHeader}.${encodedPayload}.${signature}`;
        // replace it with Dynamic when Backend is ready
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NDIyMjM0NTAsImlzcyI6IkFQSUt5emR1UEI1eW5aaiIsIm5iZiI6MTc0MjIxNjI1MCwic3ViIjoicXVpY2tzdGFydCB1c2VyIHYxYXZ0ayIsInZpZGVvIjp7ImNhblB1Ymxpc2giOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsInJvb20iOiJxdWlja3N0YXJ0IHJvb20iLCJyb29tSm9pbiI6dHJ1ZX19.TGjtNN-EfFdOr8n-Jm1uVDs-SlcjrkmJeE_Sok2SM98';
};