import {
    decodePassphrase,
} from "../utils/client-utils";
import {
    formatChatMessageLinks,
    LiveKitRoom,
    PreJoin,
    VideoConference,
} from "@livekit/components-react";
import {
    ExternalE2EEKeyProvider,
    VideoPresets,
    Room,
    DeviceUnsupportedError,
} from "livekit-client";
import { useNavigate } from "react-router-dom";
import React from 'react';
import { getConnectionDetails } from "../utils/client-connection-details"; // Import the new function
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import VideoConferenceSidebar from "./VideoConferenceSidebar";
import { Box, HStack } from "@chakra-ui/react";

// const SHOW_SETTINGS_MENU = process.env.REACT_APP_SHOW_SETTINGS_MENU === "true";
const SHOW_SETTINGS_MENU = false;

export default function VideoConferenceComponent({ meetCode, codec }) {

    const [preJoinChoices, setPreJoinChoices] = React.useState(null);
    const [connectionDetails, setConnectionDetails] = React.useState(null);
    const preJoinDefaults = React.useMemo(() => ({
        username: "",
        videoEnabled: true,
        audioEnabled: true,
    }), []);

    const handlePreJoinSubmit = React.useCallback(async (values) => {
        setPreJoinChoices(values);
        try {
            const {
                serverUrl: livekitServerUrl,
                roomName,
                participantToken,
                participantName,
            } = await getConnectionDetails(meetCode, values.username, '');
            if (participantToken) {
                setConnectionDetails({
                    serverUrl: livekitServerUrl,
                    roomName,
                    participantToken,
                    participantName,
                });
            } else {
                console.error("Participant token not found in connection details.");
            }
        } catch (error) {
            console.error("Error fetching connection details:", error);
        }
    }, [meetCode]);

    return (
        <main data-lk-theme="default" className={``}>
            <HStack className={`flex justify-between h-[70vh] pr-3 pb-3 w-full ${!connectionDetails || !preJoinChoices ? 'h-1/4 items-center' : 'flex justify-between items-center'}`} spacing={4} flexDirection={{ base: 'column', md: 'row' }}>

                {!connectionDetails || !preJoinChoices ? (
                    <div className="flex items-center mx-auto ">
                        <PreJoin defaults={preJoinDefaults} onSubmit={handlePreJoinSubmit} />
                    </div>
                ) : (
                    <div data-lk-theme="default" className="flex md:justify-start items-start h-full w-full md:h-[90%] md:w-[80%]">
                        <InnerVideoConferenceComponent
                            connectionDetails={connectionDetails}
                            userChoices={preJoinChoices}
                            options={{ codec }}
                        />
                    </div>
                )}
                {
                    (connectionDetails || preJoinChoices) &&

                    <VideoConferenceSidebar />

                }
            </HStack>
        </main>
    );
}

function InnerVideoConferenceComponent({ userChoices, connectionDetails, options }) {
    const e2eePassphrase = decodePassphrase(window.location.hash.substring(1));
    const worker = e2eePassphrase ? new Worker(new URL("livekit-client/e2ee-worker", import.meta.url)) : null;
    const e2eeEnabled = !!(e2eePassphrase && worker);
    const keyProvider = new ExternalE2EEKeyProvider();
    const [e2eeSetupComplete, setE2eeSetupComplete] = React.useState(false);
    const navigate = useNavigate();

    const roomOptions = React.useMemo(() => {
        let videoCodec = options.codec || "vp9";
        if (e2eeEnabled && ["av1", "vp9"].includes(videoCodec)) videoCodec = undefined;
        return {
            videoCaptureDefaults: {
                deviceId: userChoices.videoDeviceId,
                resolution: options.hq ? VideoPresets.h2160 : VideoPresets.h720,
            },
            publishDefaults: {
                videoSimulcastLayers: options.hq ? [VideoPresets.h1080, VideoPresets.h720] : [VideoPresets.h540, VideoPresets.h216],
                red: !e2eeEnabled,
                videoCodec,
            },
            audioCaptureDefaults: {
                deviceId: userChoices.audioDeviceId,
            },
            e2ee: e2eeEnabled ? { keyProvider, worker } : undefined,
        };
    }, [userChoices, options, e2eeEnabled]);

    const room = React.useMemo(() => new Room(roomOptions), []);

    React.useEffect(() => {
        if (e2eeEnabled) {
            keyProvider.setKey(decodePassphrase(e2eePassphrase)).then(() => {
                room.setE2EEEnabled(true).catch((e) => {
                    if (e instanceof DeviceUnsupportedError) {
                        alert("Your browser does not support encrypted meetings. Update to the latest version.");
                        console.error(e);
                    } else {
                        throw e;
                    }
                });
            }).then(() => setE2eeSetupComplete(true));
        } else {
            setE2eeSetupComplete(true);
        }
    }, [e2eeEnabled, room, e2eePassphrase]);

    const handleOnLeave = () => navigate("/");
    const handleError = (error) => {
        alert(`Error encountered: ${error.message}`);
    };
    const handleEncryptionError = (error) => {
        console.error("Encryption error:", error);
        alert(`Encryption error: ${error.message}`);
    };

    return (
        <div className="h-full w-full">
            <LiveKitRoom
                connect={e2eeSetupComplete}
                room={room}
                token={connectionDetails.participantToken}
                serverUrl={connectionDetails.serverUrl}
                video={userChoices.videoEnabled}
                audio={userChoices.audioEnabled}
                onDisconnected={handleOnLeave}
                onEncryptionError={handleEncryptionError}
                onError={handleError}
                className="max-w-full md:max-w-full h-full "
            >
                <VideoConference
                    chatMessageFormatter={formatChatMessageLinks}
                    // SettingsComponent={SHOW_SETTINGS_MENU ? SettingsMenu : undefined}
                    SettingsComponent={false}
                />
                {/* <DebugMode /> */}
                {/* <RecordingIndicator /> */}
            </LiveKitRoom>
        </div>
    );
}