import { toast } from 'react-hot-toast';
import { apiConnector } from '../apiConnector';
import { billingEndpoints } from '../apis';

const {
    FETCH_BILLING
} = billingEndpoints;

export async function fetchBilling(year, month) {
    console.log({year, month});
    const toastID = toast.loading('Loading');

    try{
        const res = await apiConnector("POST", FETCH_BILLING, {
            year, month
        })

        console.log("Billing API Response: ", res)

        if(res.status !== 200){
            throw new Error(res.message)
        }
        const resMessage = res.data.message || 'Billing Info Fetched Successfully';
        toast.success(resMessage);

        return res;
    }
    catch(error) {
        const errorMessage = error.message || 'Error in fetching billing';
        toast.error(errorMessage);
    }
    finally{
        toast.dismiss(toastID)
    }
}