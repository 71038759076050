import { toast } from 'react-hot-toast';
import { apiConnector } from '../apiConnector'
import { authEndpoints } from '../apis'
import { login, logout, setLoading, setter } from '../../redux/authSlice'
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { removeWorkspace } from '../../redux/workspaceSlice';

const {
    LOGIN_API,
    SIGNUP_API,
    REGENERATE_ACCESS_TOKEN,
    FORGOT_PASSWORD,
    VERIFY_OTP
} = authEndpoints

export function loginAPI(username, password, navigate) {
    return async (dispatch) => {
        const toastID = toast.loading('Loading');
        dispatch(setLoading(true))
        if (!username || !password) {
            toast.error("Please fill all the fields")
            dispatch(setLoading(false));
            return;
        }

        try {
            const res = await apiConnector("POST", LOGIN_API, {
                username, password,
            })


            if (res.status !== 200) {
                throw new Error(res.message)
            }

            dispatch(login({ username: username, accessToken: res?.data?.authentication_result?.AccessToken, refreshToken: res?.data?.authentication_result?.RefreshToken, expiresIn: res?.data?.authentication_result?.ExpiresIn }));

            toast.success('Login Successful');

            if (res.status === 200) {
                navigate('/workspace');
            }

        }
        catch (error) {
            toast.error('Invalid email or password');
        }
        dispatch(setLoading(false))
        toast.dismiss(toastID)
    }
}

export function signupAPI(first_name, last_name, email, password, confirm_password, phone_number, organization_name, navigate) {
    return async (dispatch) => {
        const toastID = toast.loading('Loading');
        if (!first_name || !last_name || !email || !password || !confirm_password || !phone_number || !organization_name) {
            toast.error("Please fill all the fields");
            toast.dismiss(toastID);
            return;
        }

        try {
            const res = await apiConnector("POST", SIGNUP_API, {
                first_name, last_name, email, password, confirm_password, phone_number, organization_name
            });
            if (res.status !== 200) {
                throw new Error(res.message || "Signup failed");
            }

            toast.success("Sign Up Successful");

            // Navigate to the login page after successful signup
            if (res.status === 200) {
                navigate("/login");
            }
        } catch (error) {
            toast.error(error.message || "An error occurred during signup");
        } finally {
            toast.dismiss(toastID);
        }
    };
}

export function regenerateAccessToken(refreshToken) {
    return async (dispatch) => {
        if (!refreshToken) {
            toast.error("Login first then try again");
            return;
        }
        try {
            const res = await apiConnector("POST", REGENERATE_ACCESS_TOKEN, {
                access_token: refreshToken
            });

            if (res.status === 200) {
                dispatch(setter({ accessToken: res?.data?.AccessToken, expiresIn: res?.data?.ExpiresIn }));
            } else {
                dispatch(logout());
                dispatch(removeWorkspace());
                throw new Error(res.message || "Something went wrong");
            }

            return res?.data;
        } catch (error) {
            toast.error(error.message || "An error occurred during Regenerate Access Token");
        }
    };
}

export function forgotPasswordAPI(email) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", FORGOT_PASSWORD, { email }, null, null);
            if (res.status !== 200) {
                throw new Error(res.message)
            }
            resolve(res);
        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
    }
    )
}

export function verifyOTP(email, otp, newPassword) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiConnector("POST", VERIFY_OTP, { email, code: otp, new_password: newPassword }, null, null);
            resolve(res);
        }
        catch (error) {
            toast.error(error);
            reject(error)
        }
    }
    )
}
