import axios from 'axios';
import { getAccessToken } from '../utils/client-utils.js';
import store from '../redux/store.js'
import { regenerateAccessToken } from './operations/authAPI.js';
import { logout } from '../redux/authSlice.js';
import toast from 'react-hot-toast';
import { removeWorkspace } from '../redux/workspaceSlice.js';

export const axiosInstance = axios.create({});

export const apiConnector = (method, url, bodyData, headers, params) => {
    try {
        const token = getAccessToken();
        const newHeader = {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            ...(headers ? headers : {})
        };

        return axiosInstance({
            method: `${method}`,
            url: `${url}`,
            data: bodyData || null,
            headers: newHeader,
            params: params || null,
        });
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
};

axiosInstance.interceptors.response.use(
    async (response) => {
        const isGenerating = sessionStorage.getItem("isGeneratingToken");
        if (response.status === 200) {
            const expireTime = sessionStorage.getItem("expiresIn");
            if (!expireTime) return response;

            const expiryTimestamp = parseInt(expireTime, 10);

            const currentTime = Date.now();
            const remainingTime = expiryTimestamp - currentTime;

            if (remainingTime <= 10 * 60 * 1000 && isGenerating === 'false') {
                sessionStorage.setItem("isGeneratingToken", 'true');

                const refreshToken = sessionStorage.getItem("refreshToken");

                if (refreshToken) {
                    await store.dispatch(regenerateAccessToken(refreshToken));
                }
            }
        } else if (response.status === 401) {
            store.dispatch(logout());
            store.dispatch(removeWorkspace());
        } else if (response.status === 500) {
            toast.error("Internal Server Error");
        }
        return response;
    },
    (error) => {
        if (error.response.status === 404 || error.response.status === 500 || error.response.status === 400) {
            toast.error(error.response.data.detail || 'Something went wrong');
            return Promise.resolve(error.response); 
        }
        return Promise.reject(error);
    }
);
