import { Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Workflow from './Workflow';
import Billing from './Billing';
import Data from './Data';
import Datasets from './Datasets';
import Compute from './Compute';
import WorkspaceDetails from './WorkspaceDetails';
import { getWorkSpaceAPI } from '../services/operations/workSpaceAPI';
import toast from 'react-hot-toast';
import { setWorkspace } from '../redux/workspaceSlice';
import img1 from '../images/bg6.jpg';


const Workspace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, username } = useSelector((state) => state.auth);
  const [showForm, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const tabNames = ['Data Storage', 'Billing', 'Compute'];

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/workspace-prev');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const res = await getWorkSpaceAPI(username);
        if (res.status === 200) {
          setShowTable(res?.data?.workspace_assigned || false);
          dispatch(setWorkspace(res?.data?.workspaces || []));
          setIsLoading(false);
        } else {
          toast.success('No workspace assigned');
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message || 'Error fetching workspace');
      }
    };

    if (isLoggedIn) {
      fetchWorkspace();
    }
  }, [isLoggedIn, username, dispatch]);

  if (!isLoggedIn) return null;

  return (
    <>
      <div>
        <div className="bg-cover pt-4" style={{ backgroundImage: `url(${img1})` }}>
          {isLoading ? (
            <div className="flex items-center justify-center mt-16 min-h-[600px] bg-white">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          ) :
            showTable ? (
              <div className='p-6 mt-16 min-h-[800px] bg-white' >
                <div className='p-6 pt-24'>
                  <Tabs className='bg-white' align='start' variant='enclosed' index={activeTab} onChange={(index) => setActiveTab(index)}>
                    <TabList
                      mb='1em'
                      display={{ base: 'none', md: 'flex' }}
                      className="w-[900px]"
                    >
                      {tabNames.map((tab, index) => (
                        <Tab
                          key={index}
                          width="full"
                          height="full"
                          className={`font-bold text-sm ${index === activeTab ? 'text-blue-600' : ''}`}
                        >
                          {tab}
                        </Tab>
                      ))}
                    </TabList>
                    <div className='xs:block md:hidden mb-4 px-4'>
                      <select
                        value={activeTab}
                        onChange={(e) => setActiveTab(Number(e.target.value))}
                        className='p-2 border rounded-md w-full'
                      >
                        {tabNames.map((name, index) => (
                          <option key={index} value={index}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <TabPanels>
                      <TabPanel>
                        <Data />
                      </TabPanel>
                      {/* <TabPanel>
                    <Workflow />
                  </TabPanel> */}
                      <TabPanel>
                        <Billing />
                      </TabPanel>
                      {/* <TabPanel>
                    <Datasets />
                  </TabPanel> */}
                      <TabPanel>
                        <Compute />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </div>
              </div>

            ) : showForm ? (
              <div className='p-6 mt-8 min-h-[800px] flex items-center justify-center  '>

                <WorkspaceDetails />
              </div>

            ) : (
              <div className=' mt-16 min-h-[700px] flex items-center justify-center bg-white'>
                <VStack spacing={4} justifyContent='center' className='text-center'>
                  <Text fontSize="3xl" fontWeight="medium" mb={4}>No Active Workspace Found</Text>
                  <Text fontSize="lg" fontWeight="medium" mb={4}>
                    If you would like to access a Workspace,{' '}
                    <span className='text-red-500 cursor-pointer hover:underline' onClick={() => setShowForm(true)}>
                      please complete a data request
                    </span>
                  </Text>
                </VStack>
              </div>
            )
          }
        </div>
      </div >
    </>

  );
};

export default Workspace;