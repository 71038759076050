import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import img1 from '../images/bg6.jpg';

const PrivacyPolicy = () => {

const handleBack = () => {
  window.history.back();
}

  return (
    <div>
       <div className="bg-cover pt-20" style={{ backgroundImage: `url(${img1})` }}>
        <Navbar/>
        <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
      </svg>
      </div> 
      <div className='p-6 mx-auto md:w-[60%] bg-white'>
        
              <p className='font-extrabold text-3xl p-5'>Privacy Policy for DeployByDesign</p>
                  <p className='text-xl p-5 mb-4'>Privacy Policy</p>    

                  <p className='text-lg ml-5 mb-3 font-semibold'>Effective Date: Nov 1, 2024</p>
                  
                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>1. Introduction</p>
                  
                  
                  <p className='ml-9 mb-10'>Welcome to Deploybydesign. This Privacy Policy explains how we collect, use, and protect your personal information when you use our SaaS platform.</p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>2. Information We Collect</p>

                  <p className='ml-9 mb-10'>Personal Data: We collect your name, email address, and payment information when you place an order.
                  Non-Personal Data: We use web cookies to collect non-personal data to improve our services and user experience.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>3. Purpose of Data Collection</p>

                  <p className='ml-9 mb-10'>The personal data we collect is used solely for order processing and service provision.
                  </p>
                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>4. Data Sharing</p>

                  <p className='ml-9 mb-10'>
                  <p>We do not share your personal data with any third parties, ensuring your information remains confidential and secure.
                  </p>
                  </p>
                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>5. Children's Privacy</p>

                  <p className='ml-9 mb-10'>
                  We do not knowingly collect any personal data from children under the age of 13. Our services are directed to individuals who are at least 18 years old or older.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>6. Updates to the Privacy Policy</p>

                  <p className='ml-9 mb-10'>
                  We may update this Privacy Policy from time to time. Users will be notified of any changes via email.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>7. Contact Us</p>

                  <p className='ml-9 mb-10'>
                  If you have any questions about this Privacy Policy or the security of your personal data, please contact us at support@astradigit.com.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>8. Conclusion</p>
                  <p className='ml-9 mb-10'>
                  Your privacy is important to us, and we are committed to protecting your personal information. Thank you for trusting Deploybydesign with your data.
                  </p>
                </div>
                <Footer/>
      </div>
    </div>
  )
}

export default PrivacyPolicy