import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { deleteFile, downloadFiles, listAllFiles, uploadFiles } from '../services/operations/uploadDownloadAPI';
import { useNavigate } from 'react-router-dom';

const Data = () => {
    
    const [os, setOs] = useState('Mac');
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);
    const [search, setSearch] = useState("");
    const [filteredFiles, setFilteredFiles] = useState([]);
    const nav = useNavigate();

    useEffect(() => {
        const filtered = files.filter((file) =>
          file.file_name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredFiles(filtered);
        setCurrentPage(1); // Reset to first page on new search
      }, [files, search]);

    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
        fetchFiles();
    }, [username])

    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const lastItemIndex = currentPage * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;

    const currentFiles = filteredFiles.slice(firstItemIndex, lastItemIndex);

    const totalPages = Math.ceil(filteredFiles.length / itemsPerPage);

    const handlePageChange = (pgNumber) => {
        setCurrentPage(pgNumber);
    };


    function copyText() {
        const text = document.getElementById('textTocopy').innerText;
        navigator.clipboard.writeText(text)
        .then(() => {
            alert("Text copied to clipboard!");
        })
        .catch((err) => {
            console.error("Failed to copy text: ", err);
            alert("Failed to copy text");
        });
    }

    const fetchFiles = async () => {

        if (!username) {
            console.log("No username");
            return;
        }

        try {
            const res = await listAllFiles(username);
            console.log(res.data.files);
            if (res && res.data.files) { 
                setFiles(res.data.files);
            } else {
                console.log("No files found in response.");
            }
        } catch (error) {
            console.error("Error fetching files:", error);
        }
    };

    const handleDownload = async (file) => {
        try {
            const url = await downloadFiles(file.file_name, username);

            if (url && url.data.url) {
                window.location.href = url.data.url;
                console.log("Download started for filename: " + file.file_name);
            } else{
                console.error("Failed to retrive download url")
            }

        } catch (error) {
            console.error("Error during file download:", error);
        }
    };

    const handleDelete = async(file) => {
        try {
            const res = await deleteFile(username, file.file_name);
            fetchFiles();
            if(res){
                console.log('File deleted');
                
            } else {
                console.log('Failed to delete file')
            }
        } catch (error) {
            console.error("Error while deleting:", error);

        }
    }

    const manageUpload = async (e) => {
        e.preventDefault();
        console.log(username);
        console.log(file);

        if (!username || !file) {
            console.log("No username or file");
            return;
        }
    
        try { 
            await uploadFiles(username, file);
            document.getElementById('my_modal_3').close()
            fetchFiles();
            setFile(null);
            document.getElementById('filename').innerHTML = '';

        } catch (error) {
            console.error("Error during file upload:", error);
        }
    };

    const [checked, setChecked] = useState(false);

    const handleCheckboxChange = (event) => {
      setChecked(event.target.checked);
      if (event.target.checked) {
        window.open('/data-upload-policy', '_blank');      
      }
    };


  return (
    <div className=''>
        <div>
                <div className='md:grid md:grid-cols-5 gap-x-8 gap-y-8 sm:grid sm:grid-cols-2 mb-6'>
                <div className='border border-slate-300 bg-slate-500 text-white  border-b-cyan-800 border-b-4  shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>Datasets</p>
                    <p className='text-2xl mb-4'>40
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800  border-b-4 text-white  bg-slate-700 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>Uploaded Files</p>
                    <p className='text-2xl mb-4'>10
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800 border-b-4 text-white  bg-sky-950 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>Monthly Bills</p>
                    <p className='text-2xl mb-4'>$150
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800  border-b-4 text-white  bg-slate-500 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>Memory</p>
                    <p className='text-2xl mb-4'>v4 CPU
                    </p>
                </div>
                <div className='border border-slate-300 border-b-cyan-800  border-b-4 text-white  bg-sky-950 shadow-2xl p-8 rounded-lg hover:scale-105 transition-all duration-200'>
                    <p className='mb-4 text-xl'>vCPUs</p>
                    <p className='text-2xl mb-4'>0
                    </p>
                </div>
            </div>
                </div>
        <div className='w-full p-3 border shadow-xl'>
            <div className='flex items-center justify-between'>
                <div className='flex gap-x-2'>
                    <p className='text-lg font-semibold'>Uploaded Files
                    </p>
                    <div  className='cursor-pointer'>
                    <svg onClick={fetchFiles} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    </div>
                </div>
                <div className='xs:flex xs:flex-col md:flex md:flex-row xs:gap-y-2 md:gap-x-2'>
                    <p>
                        <input type='text' value={search} 
                        onChange={(e) => setSearch(e.target.value)} placeholder='Search File' className='p-2 border items-center rounded-md'/>
                    </p>
                    <button onClick={()=>document.getElementById('my_modal_4').showModal()} className='btn xs:mx-10 md:mx-0'>Request SFTP</button>
                    <dialog id="my_modal_4" className="modal">
                        <div className="modal-box xs:max-w-[500px]">
                            <form method="dialog">
                                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                            </form>
                            <h3 className="font-bold text-md mb-4">Request SFTP Service</h3>
                            <div className='w-full border'></div>
                                <div className='flex mt-6 mb-4 flex-col gap-y-1'>
                                    <p className='text-sm'>Email Id:</p>
                                    <p>{username}</p>
                                </div>
                                <div className="overflow-x-auto mt-4 mb-6 ">
                                    <p className='text-md mb-3 font-semibold'>SFTP Pricing</p>
                                    <table className='text-sm' style={{ borderCollapse: 'collapse', width: '100%' }}>
                                    <thead>
                                        <tr style={{ border: '1px solid black' }}>
                                            <th style={{ border: '1px solid black', padding: '3px' }}>Service</th>
                                            <th style={{ border: '1px solid black', padding: '3px' }}>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ border: '1px solid black' }}>
                                            <td style={{ border: '1px solid black', padding: '3px' }}>Base Service</td>
                                            <td style={{ border: '1px solid black', padding: '3px' }}>$0.30 per hour/$7.20 per day usage</td>
                                        </tr>
                                        <tr style={{ border: '1px solid black' }}>
                                            <td style={{ border: '1px solid black', padding: '3px' }}>Data Upload</td>
                                            <td style={{ border: '1px solid black', padding: '3px' }}>$0.05 per gigabyte (GB)</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>

                                <div className='flex mb-4 flex-col gap-x-2'>
                                    <p>Comment: </p>
                                    <textarea rows={3} type='text' className='border mt-2 rounded-md p-2 w-[70%] text-sm' placeholder='Enter details about url, purpose etc... (Optional)' />
                                </div>

                                <div className="form-control mb-5">
                                    <label className="cursor-pointer flex gap-x-3">
                                        <input type="checkbox" onChange={handleCheckboxChange} defaultChecked={false} className="checkbox checkbox-warning" />
                                        <span className="label-text">I agree to the Payment Terms and Terms of Service.</span>

                                    </label>
                                </div>

                                <button className='btn'>Submit</button>
                        </div>
                        </dialog>
                    <button onClick={()=>document.getElementById('my_modal_3').showModal()} className='btn xs:mx-10 md:mx-0'>Upload</button>
                    <dialog id="my_modal_3" className="modal">
                        <div className="modal-box">
                            <form method="dialog">
                                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                            </form>
                            <h3 className="font-bold text-md">Upload</h3>
                            <div role="tablist" className="mt-7 tabs tabs-lifted">
                                <input type="radio" name="my_tabs_2" role="tab" className="min-w-[140px] px-2 tab text-base" aria-label="Single File" defaultChecked/>
                                <div role="tabpanel" className="tab-content flex flex-col bg-base-100 border-base-300 rounded-box p-6">
                                        
                                        <input type='file' className='mt-8 mb-10' onChange={(e) => setFile(e.target.files[0])}/>
                                        <div className='flex gap-x-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                        </svg>
                                        <p className='text-sm font-semibold mb-2'>
                                            Do not upload any data that contains PII or PHI information.
                                        </p>
                                        </div>

                                        <div className='flex gap-x-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                        </svg>
                                        <p className='text-sm font-semibold mb-2'>
                                            For uploading files greater than 20GB, please request <span className='text-emerald-700'>SFTP - Large File Upload Service</span> from the My Requests page.
                                        </p>
                                        </div>

                                        <div className='flex justify-between mt-7'>
                                            <button onClick={manageUpload} className='btn'>Upload</button>
                                        </div>

                                </div>

                                <input
                                    type="radio"
                                    name="my_tabs_2"
                                    role="tab"
                                    className="tab text-base min-w-[140px] px-2"
                                    aria-label="Multiple Files"
                                 />
                                <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                                <div className="flex gap-x-2 mt-5">
                                    <button 
                                        onClick={() => setOs('Mac')} 
                                        className={`p-2 border rounded ${os === 'Mac' ? 'bg-blue-500 text-white' : ''}`}
                                    >
                                        Mac
                                    </button>
                                    <button 
                                        onClick={() => setOs('Windows')} 
                                        className={`p-2 border rounded ${os === 'Windows' ? 'bg-blue-500 text-white' : ''}`}
                                    >
                                        Windows
                                    </button>
                                </div>
                                {os === 'Mac' ? (
                                    <div>
                                    <p className='mt-5 mb-2 font-semibold'>In order to upload multiple files, please execute the following steps from your Mac machine</p>
                                    <p className='mb-2'>1. Click <span className='text-emerald-600'>here</span> to download the script.</p>
                                    <p className='mb-2'>2. In Command Prompt, go to the directory where you have downloaded fileupload.sh and use the following command to upload multiple files.</p>
                                    <div id='textTocopy' className='bg-slate-100 text-sm p-1 h-48 w-64 overflow-auto mb-3'>
                                        bash fileupload.sh -filepath path_to_directory_containing_files -bucketname aha-pmp-researcher-scratchbucket-655188113753/researcher_data/folder_name -aws_access_key ASIAZRDCBHFM5ADFPYTF -aws_secret_access_key Rt14uXxi/3OyUOhE79IsH8mCBs0+QKZ1X+znWVsp -aws_session_token FwoGZXIvYXdzEJP//////////wEaDGM6IGnVOCO/fE1X7CKvAX/ZxOjgGGP7B+oNCEBRzW9gx5QetVNRvQAu8EGqwHaRJLgl6SNKfEqxLMZQvpapcKMa1QDlHeBTfHpmAS9Uq24z0M59EE95thVNvbC4Rq64z+wNUiBTx/7kA6JtoR4SxlIPI/z5xiEoMppva7fpEyB2VbiMzzEJcYUnTmk+oW9xzQ8lWNJxTSHje42iqx/mBKcs7N+R06GJpavaGuxaxtp18jYUZ99rMz29v3nDfucojtftuAYyLQMN80f5LTbjWN9jsqzd3UaxK5SHoY9pAAN8bAUIE/3wW2cjXobAkzaBOP3Ovw== -portal_username aha.pmp.development@hitachivantara.com                                    </div>
                                    <button onClick={copyText} className='btn mb-4'>Copy</button>

                                    <p className='mb-2 text-gray-600'>The command will upload all files in the directory to S3 under folder given by name 'folder_name'.</p>
                                    <p className='mb-2'>3. Refresh the page to see the updated notebook link.</p>
                                    <p className='font-bold'>4. The credentials are valid for 1 hour only.</p>
                                    </div>
                                ) : (
                                    <div>
                                    <p className='mt-5 mb-2 font-semibold'>In order to upload multiple files, please execute the following steps from your Windows machine</p>
                                    <p className='mb-2'>1. Click <span className='text-emerald-600'>here</span> to download the script.</p>
                                    <p className='mb-2'>2. In Command Prompt, go to the directory where you have downloaded fileupload.ps1 and use the following command to upload multiple files.</p>
                                    
                                    <div id='textTocopy' className='bg-slate-100 text-sm p-1 h-48 w-64 overflow-auto mb-3'>
                                    ./fileupload.ps1 -filepath path_to_directory_containing_files -bucketname aha-pmp-researcher-scratchbucket-655188113753/researcher_data/folder_name -aws_access_key ASIAZRDCBHFM5ADFPYTF -aws_secret_access_key Rt14uXxi/3OyUOhE79IsH8mCBs0+QKZ1X+znWVsp -aws_session_token FwoGZXIvYXdzEJP//////////wEaDGM6IGnVOCO/fE1X7CKvAX/ZxOjgGGP7B+oNCEBRzW9gx5QetVNRvQAu8EGqwHaRJLgl6SNKfEqxLMZQvpapcKMa1QDlHeBTfHpmAS9Uq24z0M59EE95thVNvbC4Rq64z+wNUiBTx/7kA6JtoR4SxlIPI/z5xiEoMppva7fpEyB2VbiMzzEJcYUnTmk+oW9xzQ8lWNJxTSHje42iqx/mBKcs7N+R06GJpavaGuxaxtp18jYUZ99rMz29v3nDfucojtftuAYyLQMN80f5LTbjWN9jsqzd3UaxK5SHoY9pAAN8bAUIE/3wW2cjXobAkzaBOP3Ovw== -portal_username aha.pmp.development@hitachivantara.com                                   
                                    </div>
                                    <button onClick={copyText} className='btn mb-4'>Copy</button>
                                    <p className='mb-2 text-gray-600'>The command will upload all files in the directory to S3 under folder given by name 'folder_name'.</p>
                                    <p className='mb-2'>3. Refresh the page to see the updated notebook link.</p>
                                    <p className='font-bold'>4. The credentials are valid for 1 hour only.</p>         
                                </div>
                                )}
                            </div>
                        </div>
                        </div>
                    </dialog>
                </div>

                </div>
                {/* table */}
                <div className="overflow-x-auto">
                    <table className="table">
                        {/* head */}
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Last Modified</th>
                            <th>Actions</th>

                        </tr>
                        </thead>

                        <tbody>
          {currentFiles.length > 0 ? (
            currentFiles.map((file, index) => (
              <tr key={index}>
                <td>{file.file_name}</td>
                <td>{file.file_size}</td>
                <td>{file.last_modified}</td>
                <td className="flex gap-x-2">
                  <button onClick={() => handleDownload(file)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  </button>
                  <button onClick={() => handleDelete(file)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No files found</td>
            </tr>
          )}
        </tbody>

        </table>

        
        <div className="join float-right">
            {Array.from({length: totalPages}, (_, i) => (
                <button key={i+1} className={`join-item btn ${currentPage === i + 1 ? 'btn-active' : '' }`}
                    onClick={() => handlePageChange(i+1)}
                >
                    {i+1}
                </button>

            ))}
        </div>

    </div>
    </div>
    </div>
  )
}

export default Data