import React from 'react'

const Testimonial = () => {
  return (
    <div className='flex flex-col'>
         <div className="stats stats-vertical mx-auto mt-32 bg-slate-50 w-[80%] lg:stats-horizontal shadow p-9">
            <div className="stat flex flex-col items-center">
                <div className="stat-value">2024</div>
                <div className="stat-desc">Founded</div>
            </div>

            <div className="stat  flex flex-col items-center">
                <div className="stat-value">50K+</div>
                <div className="stat-desc">Active Users</div>
            </div>

            <div className="stat flex flex-col items-center">
                <div className="stat-value">1K+</div>
                <div className="stat-desc">Company Partners</div>
            </div>
        </div>
        <section className="bg-gray-1 py-20 dark:bg-dark-2 md:py-[50px] -mb-36">
      <section className="text-gray-600 body-font">
        <div className=" px-5 py-24 mx-auto">
            <p className="text-center text-xl font-semibold text-blue-700">Testimonials</p>
              <div className='text-center font-semibold mt-6 mb-20 sm:text-4xl'>
                Trusted by professionals across the industry
              </div>
          <div className="flex flex-wrap -m-4 w-full">
            <div className="bblock p-4 xs:w-[100%] md:w-1/3">
              <div className="h-full bg-gray-100 p-8 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-10">
                Astradigit has been an invaluable partner for our business. Their team of experts helped us streamline our processes and implement cutting-edge
                  technology solutions that significantly improved our efficiency and productivity. Their dedication to customer service and attention to detail 
                  is second to none.
                </p>
                <a className="inline-flex items-center">
                  {/* <div style={{backgroundImage: `url(${img2})`, backgroundPosition: 'center', backgroundSize: 'cover'}} className="w-12 h-12 rounded-full"></div> */}
                  <span className="flex-grow flex flex-col pl-4">
                    <span className="title-font font-medium text-gray-900">Deval Gantara</span>
                    <span className="text-gray-500 text-sm">PixieBytez</span>
                  </span>
                </a>
              </div>
            </div>
            <div className="bblock p-4 xs:w-[100%] md:w-1/3">
              <div className="h-full bg-gray-100 p-8 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-10">
                Astradigit has been an invaluable partner for our business. Their team of experts helped us streamline our processes and implement cutting-edge
                  technology solutions that significantly improved our efficiency and productivity. Their dedication to customer service and attention to detail 
                  is second to none.
                </p>
                <a className="inline-flex items-center">
                  {/* <div style={{backgroundImage: `url(${img3})`, backgroundPosition: 'center', backgroundSize: 'cover'}} className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"></div> */}
                  <span className="flex-grow flex flex-col pl-4">
                    <span className="title-font font-medium text-gray-900">Suraj Chandalia</span>
                    <span className="text-gray-500 text-sm">Vitafoam</span>
                  </span>
                </a>
              </div>
            </div>
            <div className="bblock p-4 md:w-1/3 xs:w-full">
              <div className="h-full bg-gray-100 p-8 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-10">
                Astradigit has been an invaluable partner for our business. Their team of experts helped us streamline our processes and implement cutting-edge
                  technology solutions that significantly improved our efficiency and productivity. Their dedication to customer service and attention to detail 
                  is second to none.
                </p>
                <a className="inline-flex items-center">
                  {/* <div style={{backgroundImage: `url(${img4})`, backgroundPosition: 'center', backgroundSize: 'cover'}} className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"></div> */}
                  <span className="flex-grow flex flex-col pl-4">
                    <span className="title-font font-medium text-gray-900">Sanjay Baid</span>
                    <span className="text-gray-500 text-sm">NewgenPlastics</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    </div>
  )
}

export default Testimonial