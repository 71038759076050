import { Chart, registerables } from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { fetchBilling } from '../services/operations/billingAPI';

Chart.register(...registerables);

const Billing = () => {
    const chartRef = useRef(null);
    let chartInstance = useRef(null);
    const currentDate = new Date();
    const [year, setYear] = useState(currentDate.getFullYear());
    const [month, setMonth] = useState(currentDate.getMonth() + 1);
    const [billingData, setBillingData] = useState([]);
    const [last12Months, setLast12Months] = useState([]);

    // Generate an array of the last 12 months considering the 14-month limit
    const getValidMonths = () => {
        const months = [];
        const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 14); // 14 months back
        for (let i = 0; i < 12; i++) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
            if (date >= maxDate) {
                months.push({
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    display: date.toLocaleString('default', { month: 'long', year: 'numeric' })
                });
            }
        }
        return months.reverse(); 
    };

    useEffect(() => {
        setLast12Months(getValidMonths());
    }, []);

    const handleFetchBillingData = async () => {
        try {
            const res = await fetchBilling(year, month);
            console.log("Fetched Data:", res);
            if (res && res?.data?.billing) {
                setBillingData(res.data.billing);
            } else {
                console.error("Billing data is undefined or not in expected format");
            }
        } catch (error) {
            console.log("Error fetching billing data:", error);
        }
    };

    useEffect(() => {
        handleFetchBillingData();
    }, [year, month]);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        if (billingData.length === 0) return;

        const ctx = chartRef.current.getContext('2d');
        const labels = billingData.map(item => item.date);
        const amounts = billingData.map(item => parseFloat(item.amount));

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Billing Amount (USD)',
                    data: amounts,
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [billingData]);

    return (
        <div className='mt-4 px-8'>
            <div className='flex justify-between'>
                <p className='text-xl font-semibold'>Billing</p>
                <div className='mb-4'>
                    <label htmlFor="year-select" className='mr-2'>Year:</label>
                    <select
                        id="year-select"
                        value={year}
                        onChange={(e) => setYear(Number(e.target.value))}
                    >
                        {[...new Set(last12Months.map(m => m.year))].map(y => (
                            <option key={y} value={y}>{y}</option>
                        ))}
                    </select>

                    <label htmlFor="month-select" className='ml-4 mr-2'>Month:</label>
                    <select
                        id="month-select"
                        value={month}
                        onChange={(e) => setMonth(Number(e.target.value))}
                    >
                        {last12Months.map(({ year: y, month: m, display }) => (
                            <option key={`${y}-${m}`} value={m} disabled={y !== year}>
                                {display}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='border' style={{ height: '400px', width: '100%' }}>
                <canvas id='myChart' ref={chartRef} width="600" height="400" />
            </div>         
        </div>
    );
};

export default Billing;
