import { Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store'
import { ReactFlow, ReactFlowProvider } from '@xyflow/react';
import { Toaster } from 'react-hot-toast';
import '@xyflow/react/dist/style.css';
import Login from './pages/Login';
import Homepage from './pages/Homepage';
import Email from './components/Email';
import TermsOfService from './components/TermsOfService';
import TechnicalSupport from './components/TechnicalSupport';
import PrivacyPolicy from './components/PrivacyPolicy';
import Faq from './components/Faq';
import Contact from './components/Contact';
import Workspace from './components/Workspace';
import Chatbot from './components/Chatbot';
import TextToSql from './components/TextToSql';
import MultiModality from './components/MultiModality';
import CallCenter from './components/CallCenter';
import WorkspacePrev from './components/WorkspacePrev';
import Profile from './components/Profile';
import DataUploadPolicy from './components/DataUploadPolicy';
import Signup from './pages/Signup';
import UserInput from './components/UserInput';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  return (
    <ReactFlowProvider>
      <Toaster />
      <Provider store={store}>
    <div className='h-[100vh] w-[100vw]'>
      <Routes>
      
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path='/contact-us' element={<Contact/>} />
        <Route path="/technical-support" element={<TechnicalSupport/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/faq" element={<Faq/>} />
        <Route path="/tos" element={<TermsOfService/>}/>
        <Route path="/data-upload-policy" element={<DataUploadPolicy/>}/>
        <Route path="/workspace-prev" element={<WorkspacePrev />}/>
        <Route path="/forgot-password" element={<ForgotPassword />}/>

        <Route path="/" element={<Homepage />}>
         
          <Route path="email" element={<Email />} />
          <Route path="chatbot" element={<Chatbot />} />
          <Route path="texttosql" element={<TextToSql />} />
          <Route path="multimodality" element={<MultiModality />} />
          <Route path="call-center" element={<CallCenter />} />
          <Route path="workspace" element={<Workspace />} />
          <Route path="profile" element={<Profile />} />
          <Route path="user-input" element={<UserInput />}/>
          
          
        </Route>        
      </Routes>
      </div>
      </Provider>
      </ReactFlowProvider>
  );
}

export default App;