import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { HStack, VStack, Input, PinInput, PinInputField, Button, IconButton, FormControl, FormLabel, InputGroup, InputRightElement, Text, Flex } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon, CheckCircleIcon } from '@chakra-ui/icons';
import toast from 'react-hot-toast';
import img from '../images/bg-supp.jpg';
import { forgotPasswordAPI, verifyOTP } from '../services/operations/authAPI';

const VerificationPage = () => {
    const navigate = useNavigate();
    const username = sessionStorage.getItem("email");

    const [otp, setOTP] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [resetTimer, setResetTimer] = useState(60);

    useEffect(() => {
        if (resetTimer > 0) {
            const interval = setInterval(() => setResetTimer(prev => prev - 1), 1000);
            return () => clearInterval(interval);
        }
    }, [resetTimer]);

    const cleanup = () => {
        setOTP('');
        setNewPassword('');
        setConfirmPassword('');
    }

    const handleVerifyOTP = async () => {
        if (otp.length !== 6) {
            toast.error("Please enter a valid 6-digit OTP");
            return;
        }
        if (!newPassword || !confirmPassword) {
            toast.error("Both passwords are required");
            return;
        }
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match!");
            return;
        }
        if (!isPasswordValid(newPassword)) {
            toast.error("Password does not meet requirements!");
            return;
        }

        try {
            const res = await verifyOTP(username, otp, newPassword);
            if (res.status === 200) {
                toast.success("OTP Verified! Password Updated Successfully");
                cleanup();
                navigate("/login");
            }
        } catch (error) {
            toast.error("Failed to verify OTP");
        } finally {
            cleanup();
        }
    };

    const resendOTPHandler = async () => {
        try {
            const res = await forgotPasswordAPI(username);
            if (res.status === 200) {
                setResetTimer(60);
                setOTP('');
                toast.success("OTP sent successfully!");
            }
        } catch (error) {
            toast.error("Failed to send OTP");
        }
    };

    const isPasswordValid = (password) => {
        const minLength = password.length >= 8;
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const matchNewPassword = newPassword === confirmPassword && newPassword !== '' && confirmPassword !== '';
        const otpLength = otp.length === 6;
        return { minLength, hasUpper, hasLower, hasNumber, hasSpecial, matchNewPassword, otpLength };
    };

    const passwordChecks = isPasswordValid(newPassword);

    return (
        <div className="flex">
            <div className="md:w-[70%] hidden md:block" style={{
                backgroundImage: `url(${img})`,
                height: '100vh',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}></div>

            <div className="flex flex-col xs:w-[80%] p-6 mt-10 md:w-[25%] bg-white mx-auto shadow-lg rounded-lg">
                <Text fontSize="lg" fontWeight="bold" textAlign="center" color="gray.700" mb={4}>
                    Enter OTP Received on Email
                </Text>

                <HStack justify="center" mb={4}>
                    <PinInput type="number" value={otp} onChange={setOTP}>
                        {Array.from({ length: 6 }).map((_, i) => (
                            <PinInputField key={i} className="border border-gray-300 rounded-md" />
                        ))}
                    </PinInput>
                </HStack>

                <Text fontSize="xs" textAlign="center" color="gray.500" mb={4}>
                    Didn't receive the OTP?{" "}
                    <Button
                        colorScheme="blue"
                        variant="link"
                        isDisabled={resetTimer > 0}
                        onClick={resendOTPHandler}
                    >
                        {resetTimer > 0 ? `Resend OTP in ${resetTimer}s` : "Resend OTP"}
                    </Button>
                </Text>

                {/* New Password & Confirm Password */}
                <VStack spacing={4} width="full">
                    <FormControl>
                        <FormLabel fontSize="sm" color="gray.600">New Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="border border-gray-300 rounded-md p-2"
                            />
                            <InputRightElement>
                                <IconButton
                                    size="sm"
                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    <FormControl>
                        <FormLabel fontSize="sm" color="gray.600">Confirm Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm New Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="border border-gray-300 rounded-md p-2"
                            />
                            <InputRightElement>
                                <IconButton
                                    size="sm"
                                    icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                </VStack>
                <VStack align="start" spacing={1} fontSize="xs" color="gray.600" mt={3} ml={2}>
                    <Flex align="center">
                        <CheckCircleIcon color={passwordChecks.minLength ? "green.500" : "gray.400"} mr={1} />
                        Minimum 8 characters
                    </Flex>
                    <Flex align="center">
                        <CheckCircleIcon color={passwordChecks.hasUpper ? "green.500" : "gray.400"} mr={1} />
                        At least 1 uppercase letter
                    </Flex>
                    <Flex align="center">
                        <CheckCircleIcon color={passwordChecks.hasLower ? "green.500" : "gray.400"} mr={1} />
                        At least 1 lowercase letter
                    </Flex>
                    <Flex align="center">
                        <CheckCircleIcon color={passwordChecks.hasNumber ? "green.500" : "gray.400"} mr={1} />
                        At least 1 number
                    </Flex>
                    <Flex align="center">
                        <CheckCircleIcon color={passwordChecks.hasSpecial ? "green.500" : "gray.400"} mr={1} />
                        At least 1 special character (!@#$%^&*/:,\;+-={'>'})
                    </Flex>
                    <Flex align="center">
                        <CheckCircleIcon color={passwordChecks.otpLength ? "green.500" : "gray.400"} mr={1} />
                        OTP should be 6 digits
                    </Flex>
                    <Flex align="center">
                        <CheckCircleIcon color={passwordChecks.matchNewPassword ? "green.500" : "gray.400"} mr={1} />
                        Match the new password
                    </Flex>
                </VStack>

                <Button
                    colorScheme="blue"
                    width="full"
                    mt={6}
                    onClick={handleVerifyOTP}
                    isDisabled={!Object.values(passwordChecks).every(Boolean)}
                >
                    Verify OTP & Reset Password
                </Button>
            </div>
        </div>
    );
};

export default VerificationPage;
