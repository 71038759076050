import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { chatbotEndpoints } from "../apis";

const {
    CHATBOT
} = chatbotEndpoints

export function chatbotAPI(email, message) {
    console.log({email, message});
    return new Promise(async (resolve, reject) => {
        if (!email) {
            toast.error("No email!")    
            return;
        }

        try{
            const res = await apiConnector("POST", CHATBOT, {
                email, message
            })

            console.log("Chatbot API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }
            
            resolve(res);

        }
        catch(error) {
            console.log("Chatbot API error: ", error)
            toast.error(error);
            reject(error)
        }
        finally{
            
        }
    }
)}