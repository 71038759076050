import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { metadataEndpoints } from "../apis";

const {
    CREATE_METADATA,
    UPDATE_METADATA,
    RETRIEVE_METADATA,
    DELETE_METADATA,
    DELETE_METADATA_PARAM
} = metadataEndpoints;

export function createMetadataAPI(email, filename, content) {
    return async () => {         
        console.log({email, filename, content});
        const toastID = toast.loading('Loading');
        if (!filename) {
            toast.error("Please provide a filename")
            return;
        }

        try{
            const res = await apiConnector("POST", CREATE_METADATA, {
                email, filename, content
            })

            console.log("CREATE METADATA API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Metadata Created Successfully');
        }
        catch(error) {
            console.log("METADATA API ERROR: ", error)
            toast.error('Error in creating metadata');
        }
        finally{
            toast.dismiss(toastID)
        }
    }
}

export async function updateMetadataAPI(email, filename, content) {       
        console.log({email, filename, content});
        const toastID = toast.loading('Loading');

        if (!filename) {
            toast.error("Please provide a filename")
            return;
        }

        try{
            const res = await apiConnector("PUT", UPDATE_METADATA, {
                email, filename, content
            })

            console.log("UPDATE METADATA API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Metadata Updated Successfully');
            return true;
        }
        catch(error) {
            console.log("METADATA API ERROR: ", error)
            toast.error('Error in updating metadata');
            return false;
        }
        finally{
            toast.dismiss(toastID)
        }
    }

export async function deleteMetadataParamAPI(email, filename, metadata_key) {
        console.log({email, filename, metadata_key});
        const toastID = toast.loading('Loading');

        if (!filename) {
            toast.error("Please provide a filename")
            return;
        }

        try{
            const res = await apiConnector("POST", DELETE_METADATA_PARAM, {
                email, filename, metadata_key
            })

            console.log("DELETE METADATA API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Metadata Parameter Deleted Successfully');
            return true;
        }
        catch(error) {
            console.log("METADATA Parameter API ERROR: ", error)
            toast.error('Error in deleting metadata');
            return false;
        }
        finally{
            toast.dismiss(toastID)
        }
    }

export function deleteMetadataAPI(email, filename, content) {
    return async () => {         
        console.log({email, filename, content});
        const toastID = toast.loading('Loading');
        if (!filename) {
            toast.error("Please provide a filename")
            return;
        }

        try{
            const res = await apiConnector("DELETE", DELETE_METADATA, {
                email, filename, content
            })

            console.log("DELETE METADATA API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Metadata Deleted Successfully');
        }
        catch(error) {
            console.log("METADATA API ERROR: ", error)
            toast.error('Error in deleting metadata');
        }
        finally{
            toast.dismiss(toastID)
        }
    }
}

export function retrieveMetadataAPI(email, filename) {
        console.log({email, filename});
        const toastID = toast.loading('Loading');
        return new Promise(async (resolve, reject) => {
        if (!filename) {
            toast.error("Please provide a filename")
            return;
        }

        try{
            const res = await apiConnector("POST", RETRIEVE_METADATA, {
                email, filename
            })

            console.log("RETRIEVE METADATA API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }
            toast.success('Metadata Retrieved Successfully');
            resolve(res);
        }
        catch(error) {
            console.log("METADATA API ERROR: ", error)
            toast.error('Error in retrieving metadata');
            reject(error);
        }
        finally{
        
            toast.dismiss(toastID)
            }
        }
        )
        }