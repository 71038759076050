import React from 'react'
import { Box, VStack, Checkbox, Icon, Text, HStack, Button } from '@chakra-ui/react';
import { Md3dRotation, MdCheckCircleOutline, MdCheckCircle } from "react-icons/md"
import { LeaveIcon } from '@livekit/components-react';

const tasks = [
    { id: 1, label: 'Name, Age, Address, Date Of Birth', completed: true },
    { id: 2, label: 'Lifestyle', completed: false },
    { id: 3, label: 'Smoking', completed: false },
    { id: 4, label: 'Drinking', completed: false },
    { id: 5, label: 'Reading', completed: false },
    { id: 6, label: 'Reading', completed: false },
    { id: 7, label: 'Reading', completed: false },
    { id: 8, label: 'Reading', completed: false },
    { id: 9, label: 'Reading', completed: false },
];

const markdown = `
**Smoking**
Do You Smoke?
**yes**
**Since how long you have been smoking?**
**-**
how many cigarettes a day you smoke?
**-**
**Drinking**
Do You Drink?
**yes**
**Since how long you have been drinking?**
**-**
how many Drink a day you take ?
**-**
`;

export default function VideoConferenceSidebar() {
    const [isUpcoming, setIsUpcoming] = React.useState(true);
    const markdownToHtml = (markdown) => {
        const html = markdown
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            .replace(/\n/g, '<br/>');
        return { __html: html };
    };
    return (
        <div className="block h-full w-full md:mx-0 md:w-1/3 rounded-lg bg-gray-100 ">
            <Box className='mx-2 md:mt-4'>
                <TaskList />
                <HStack className='p-4 justify-around' spacing={4}>
                    <Button size="lg" className={`text-bold ${isUpcoming ? '!bg-blue-700 !hover:bg-blue-800 !text-white' : 'text-black bg-gray-300'}`} borderRadius={25} px={{ base: '8', sm: '4' }} onClick={() => setIsUpcoming(true)}>Upcoming</Button>
                    <Button size="lg" className={`text-bold ${!isUpcoming ? '!bg-blue-700 !hover:bg-blue-800 !text-white' : 'text-black bg-gray-300'}`} borderRadius={25} px={{ base: '8', sm: '4' }} onClick={() => setIsUpcoming(false)}>
                        Unclear
                        <span className={`ml-2 rounded-full  w-6 ${!isUpcoming ? 'bg-white text-black' : 'bg-blue-700 text-white'} `} > 4</span>
                    </Button>
                </HStack>
            </Box>
            <Box className='relative mx-2 overflow-y-auto max-h-80 bg-white border-2 border-slate-200 rounded-lg text-black p-4  shadow-lg'>
                <VStack align="stretch" spacing={1}>
                    <VStack align="stretch" spacing={1}></VStack>
                    <Text dangerouslySetInnerHTML={markdownToHtml(markdown)} />
                </VStack >

            </Box >
        </div>
    )
}


function TaskList() {

    return (
        <VStack>
            <Box className='relative p-4 mt-3 mx-2 overflow-y-scroll max-h-44 w-full border-2 border-slate-200 cursor-pointer rounded-lg bg-white  shadow-lg'>
                <VStack align="stretch" spacing={1}>
                    <Text ml={2} className='text-black font-bold my-1 '>Completed ({tasks.filter((task) => task.completed).length}/{tasks.length})</Text>

                    {tasks.map((task) => (
                        <Box key={task.id} display="flex" alignItems="center">
                            <Icon
                                as={task.completed ? MdCheckCircle : MdCheckCircleOutline}
                                color={task.completed ? 'black' : 'gray.500'}
                                size={'lg'}
                            />
                            <Text ml={2} className='text-black'>{task.label}</Text>

                        </Box>
                    ))}

                </VStack>
            </Box>

        </VStack >
    );
};
