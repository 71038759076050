import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MultiModality = () => {
    const nav = useNavigate();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); //getting login state from redux
    useEffect(() => {
      if(!isLoggedIn) {
          nav('/login');
      }
  }, [isLoggedIn, nav]);

  const [text, setText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Search query:", text);
  };

  const [selectedMedia, setSelectedMedia] = useState('Image');

  const handleMediaSelect = (mediaType) => {
    setSelectedMedia(mediaType);
  };

  // Image Filters
    const [selectedDimensions, setSelectedDimensions] = useState([]);
    const [copyrightFilter, setCopyrightFilter] = useState({
      withCopyright: false,
      withoutCopyright: false,
    })
  

  const handleDimensionChange = (e) => {
    const { value, checked } = e.target;
    if(checked) {
      setSelectedDimensions([...selectedDimensions, value]) //adding to the list of selected dimensions
    }
    else {
      setSelectedDimensions(selectedDimensions.filter((dim => dim !== value)));
    }
  };

  const handleCopyrightChange = (e) => {
    const { name, checked } = e.target;
    setCopyrightFilter({
      ...copyrightFilter,
      [name]: checked,
    });
  };

  // Video Filters
  const [selectedLength, setSelectedLength] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleLengthChange = (e) => {
    const { value, checked } = e.target;
    if(checked) {
      setSelectedLength([...selectedLength, value]);
    } else {
      setSelectedLength(selectedLength.filter((len => len !== value)))
    }
  };

  const handleLanguageChange = (e) => {
    const { value, checked } = e.target;
    if(checked) {
      setSelectedLanguage([...selectedLanguage, value]);
    } else {
      setSelectedLanguage(selectedLanguage.filter((lang => lang !== value)))
    }
  };



  const renderFilters = () => {
    switch (selectedMedia) {
      case 'Image':
        return (
          <>
            <div className='sm:flex md:flex md:flex-col'>
              <p className='font-semibold mb-2 mr-3'>Dimensions</p>
              <div className='sm:flex  md:flex md:flex-col gap-x-3 sm:mb-5'>
                <label>
                  <input 
                    type='checkbox'
                    value='1920x1080'
                    onChange={handleDimensionChange}
                    /> {' '}
                    1920x1080
                </label>
                <label>
                  <input 
                    type='checkbox'
                    value='1280x720'
                    onChange={handleDimensionChange}
                    /> {' '}
                    1280x720
                </label>
                <label>
                  <input 
                    type='checkbox'
                    value='640x480'
                    onChange={handleDimensionChange}
                    /> {' '}
                    640x480
                </label>
              </div>

            </div>
            <div className='sm:flex md:flex md:flex-col'>
            <p className='font-semibold mb-2 mr-3'>Copyright</p>
            <div className='sm:flex md:flex md:flex-col sm:gap-x-3 md:mb-4'>
              <label>
                <input 
                  type='checkbox'
                  name='withCopyright'
                  checked={copyrightFilter.withCopyright}
                  onChange={handleCopyrightChange}
                />{' '}With Copyright
              </label>
              <label>
                <input 
                  type='checkbox'
                  name='withoutCopyright'
                  checked={copyrightFilter.withoutCopyright}
                  onChange={handleCopyrightChange}
                />{' '}Without Copyright
              </label>
              </div>
            </div>
          </>
        );
      case 'Video':
        return (
          <>
            <div className='sm:flex md:flex md:flex-col'>
              <p className='font-semibold mb-2 mr-3'>Length</p>
              <div className='sm:flex  md:flex md:flex-col sm:gap-x-3 sm:mb-5'>
                <label>
                  <input 
                    type='checkbox'
                    value='5'
                    onChange={handleLengthChange}
                    /> {' '}
                    5 mins
                </label>
                <label>
                  <input 
                    type='checkbox'
                    value='10'
                    onChange={handleLengthChange}
                    /> {' '}
                    10 mins
                </label>
                <label>
                  <input 
                    type='checkbox'
                    value='15'
                    onChange={handleLengthChange}
                    /> {' '}
                    15 mins
                </label>
              </div>
            </div>
            <div className='sm:flex md:flex md:flex-col'>
              <p className='font-semibold mb-2 mr-3'>Language</p>
              <div className='sm:flex  md:flex md:flex-col sm:gap-x-3 md:gap-y-2 sm:mb-5'>
                <label>
                  <input 
                    type='checkbox'
                    value='English'
                    onChange={handleLanguageChange}
                    /> {' '}
                    English
                </label>
                <label>
                  <input 
                    type='checkbox'
                    value='Hindi'
                    onChange={handleLanguageChange}
                    /> {' '}
                    Hindi
                </label>
                <label>
                  <input 
                    type='checkbox'
                    value='Japanese'
                    onChange={handleLanguageChange}
                    /> {' '}
                    Japanese
                </label>
              </div>

            </div>
          </>
        );
      case 'Audio':
        return (
          <>
            <p>Bit Rate</p>
            <p>Genre</p>
          </>
        );
      default:
        return null;
    }
  };

  const handleBack = () => {
    window.history.back();
  }
  return (
    <div className='pt-20'>
        <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
      </svg>
      </div> 
        <div className='flex flex-col gap-y-3 text-center'>
          <p className='text-center font-bold text-3xl'>
            Unlocking Human Potential <br/>With Generative AI
          </p>
          <p className='text-center mt-3 text-gray-500 text-sm'>Developing open-source AI models for creative<br/> problem-solving and industrial use.</p>
         
        
        </div>
        <div className='flex flex-col mx-auto'>
              <div class="mt-6 mb-3 w-[80%] mx-auto flex items-center ps-3 gap-x-2">
                <input
                  required={true}
                  type='text'
                  name='text'
                  value={text} 
                  onChange={(e) => setText(e.target.value)}
                  className='p-2 m-3 text-sm border rounded-md w-[100%]'
                  placeholder='Describe the idea you would like to create'
                />
                <Button
                  colorScheme="blue"
                  width="20%"
                  onClick={handleSubmit}
                  size="sm"
              >
                  Generate
              </Button>              
              </div>   
         
            <ul className='flex mx-auto gap-x-3'>
              <li className={`border border-gray-400 hover:bg-white p-2 cursor-pointer rounded-md text-sm
                ${selectedMedia === 'Image' ? 'bg-white shadow-md border-gray-200' : 'hover:bg-white'}` }
                onClick={() => handleMediaSelect('Image')}
                >Image</li>
              <li className={`border border-gray-400 hover:bg-white p-2 cursor-pointer  rounded-md text-sm
                ${selectedMedia === 'Video' ? 'bg-white shadow-md' : 'hover:bg-white'}` }
                onClick={() => handleMediaSelect('Video')}>Video</li>
              <li className={`border border-gray-400 hover:bg-white p-2  cursor-pointer rounded-md text-sm
                ${selectedMedia === 'Audio' ? 'bg-white shadow-md' : 'hover:bg-white'}` } 
                onClick={() => handleMediaSelect('Audio')}>Audio</li>
            </ul>
          </div>
          <div className='mx-auto md:flex md:gap-x-3 mt-6 md:w-[80%] sm:w-[95%]'>
            <div className='mx-auto border mb-2 border-gray-200 rounded-md bg-white md:w-[20%] md:h-[500px] sm:w-[80%] sm:h-[120px] sm:max-h-fit'>
              {/* <p className='p-2'>Filters</p> */}
              <p className='p-2'>
                {renderFilters()}
              </p>
            </div>
            <div className='mx-auto border border-gray-200 rounded-md bg-white w-[80%] h-[500px]'>
              <p className='p-2'>Output</p>
            </div>
          
          </div>
    </div>
  )
}

export default MultiModality