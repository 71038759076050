'use client';

import { useState } from 'react';
import {
  Box, Button, Checkbox, FormControl, FormLabel, Radio, RadioGroup, Stack, Text, Textarea, HStack, VStack, Divider, Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FaAmazon } from 'react-icons/fa';
import { FaCircleInfo } from "react-icons/fa6";
import { SiAwsorganizations } from "react-icons/si";
import { workSpaceAPI } from '../services/operations/workSpaceAPI';
import { useSelector } from 'react-redux';
import toast from "react-hot-toast";
import img1 from '../images/bg6.jpg';


export default function WorkspaceDetails() {
  const [dataSize, setDataSize] = useState(0);
  const [unit, setUnit] = useState('GB');
  const [agreed, setAgreed] = useState(false);
  const [workspace, setWorkspace] = useState(1);
  const [purpose, setPurpose] = useState('');
  const { username } = useSelector((state) => state.auth);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const workspaceConfigs = [
    { id: 1, title: 'Single Node CPU Instance', cpu: '4', memory: '32GB', iconText: "Amazon Linux", icon: <FaAmazon size={60} /> },
    { id: 2, title: 'Single Node GPU Instance', cpu: '8', memory: '61GB', iconText: "Amazon Linux", icon: <FaAmazon size={60} /> },
    {
      id: 3, title: 'Auto Scaling Cluster', cpu: '8 - 32', memory: '32GB - 128GB', iconText: "Cluster", icon: <SiAwsorganizations size={60} />
    },
  ];
  const maxSliderValue = unit === 'GB' ? 150 : 10;
  const data = {
    email_id: username,
    create_new_workspace: true,
    selected_configuration: workspaceConfigs.find((config) => config.id === workspace).title,
    data_amount: dataSize,
    data_unit: unit,
    purpose: purpose,
    terms_of_service_agreed: agreed,
  }
  const resetForm = () => {
    setDataSize(0);
    setUnit('GB');
    setAgreed(false);
    setWorkspace(1);
    setPurpose('');
  }

  const handelSubmit = async () => {
    try {
      const res = await workSpaceAPI(data);
      if (res.status === 200) {
        toast.success('Workspace Request has been created Successfully');
      } else {
        toast.error('Failed to create Workspace');
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    } finally {
      resetForm();
    }
  }

  return (
    <div>
      <div className="bg-cover pt-4" style={{ backgroundImage: `url(${img1})` }}>

        <Box p={6} maxW="900px" mx="auto" bg="white" boxShadow="lg" rounded="lg" className="text-black">
          <HStack className='flex items-center ' flexDirection={{ base: 'column', sm: 'row' }}>
            <FaCircleInfo color='blue-500' className='mb-4 text-blue-700' />
            <Text fontSize="xl" fontWeight="bold" mb={4}>Workspace Details</Text>
          </HStack>

          <Text fontWeight="medium">Select the Workspace configuration you would need</Text>
          <HStack spacing={4} my={4} wrap="wrap">
            {workspaceConfigs.map((config, idx) => (
              <Box key={idx} borderWidth={2} p={4} rounded="md" cursor="pointer" className={`hover:shadow-lg min-w-[200px] sm:min-w-[250px] border-1 ${workspace === config.id ? 'border-blue-500' : 'border-gray-400'}`} onClick={() => setWorkspace(config.id)}  >
                <Box key={idx} borderWidth={2} p={4} cursor="pointer" className=" min-w-[200px] min-h-36 bg-[#eaeaea] sm:min-w-[200px] "   >
                  <VStack>
                    <Text fontWeight="medium">{config.title}</Text>
                    {config.icon}
                    <Text className='text-sm'>{config.iconText}</Text>

                  </VStack>
                </Box>
                <HStack className='flex items-center  p-2 gap-8' justify='space-between' flexDirection={{ base: 'column', sm: 'row' }}>

                  <Text className='text-center'>CPU <br /><p className=''>{config.cpu} </p></Text>
                  <Text className='text-center'>Memory <br /><p className=''>{config.memory} </p></Text>
                </HStack>
              </Box>
            ))}
          </HStack>
          <HStack className='flex items-center pl-12 my-4'  >

            <FaCircleInfo />
            <Text fontSize="sm" fontWeight="medium">This selection can be changed later as per your requirements</Text>
          </HStack>
          <Divider w={0} my={4} />
          <Text color="gray.600">How much data will you bring to the platform</Text>
          <HStack className='flex items-center mt-8' flexDirection={{ base: 'column', sm: 'row' }}>
            <Slider
              aria-label='slider-ex-6'
              max={maxSliderValue}
              value={dataSize}
              onChange={(val) => setDataSize(val)}
              w="70%"
            >
              <SliderMark
                value={dataSize}
                textAlign='center'
                bg='blue.500'
                color='white'
                mt='-10'
                ml='-5'
                w='12'
              >
                {dataSize}
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack bg="blue.500" />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <RadioGroup onChange={setUnit} value={unit}>
              <Stack direction="row">
                <Radio value="GB" colorScheme={unit === 'GB' ? 'blue' : 'gray'}>GB</Radio>
                <Radio value="TB" colorScheme={unit === 'TB' ? 'blue' : 'gray'}>TB</Radio>
              </Stack>
            </RadioGroup>
          </HStack>
          <FormControl my={4}>
            <FormLabel>Purpose*</FormLabel>
            <Textarea placeholder="Please elaborate on your specific requirements." focusBorderColor="blue.500" value={purpose} onChange={(e) => setPurpose(e.target.value)} />
          </FormControl>
          <Checkbox isChecked={agreed} onChange={() => setAgreed(!agreed)} colorScheme="blue">
            I agree to the <Text as="span" color="blue.500" onClick={onOpen}>Terms of Service</Text>

          </Checkbox>
          <HStack mt={4} justify="flex-start">
            <Button variant="outline" p="4" onClick={() => resetForm()}>Cancel</Button>
            <Button colorScheme="blue" rounded="3xl" p="4" onClick={() => handelSubmit()} >Submit</Button>
          </HStack>
          <Modal isOpen={isOpen} onClose={onClose} className="text-black mx-4 sm:mx-0">
            <ModalOverlay />
            <ModalContent minW={{ base: '90vw', md: '50vw' }} p={0} m={0}>
              <ModalHeader fontWeight="bold" fontSize="2xl" >Terms and Conditions for DeployByDesign</ModalHeader>
              <ModalCloseButton mt={2} />
              <ModalBody  >
                <div className='md:w-full bg-white'>
                  <p className='text-xl p-5 mb-4'>Welcome to DeployByDesign</p>

                  <p className='text-sm sm:text-lg ml-5 mb-3 font-semibold'>These Terms of Service ("Terms") govern your use of the DeployByDesign website at deploybydesign.com ("Website") and the services provided by SURANA DIGITAL (OPC) PRIVATE LIMITED, operating as AstraDigit.
                    By using our Website and services, you agree to these Terms.</p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Description of DeployByDesign</p>


                  <p className='ml-5 mb-10'>DeployByDesign is a SaaS platform that offers finance-related products to assist customers in their financial journey.</p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Ownership and Usage Rights</p>

                  <p className='ml-5 mb-10'>
                    When you purchase a package from our platform, you obtain the right to use it according to the package terms.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>User Data and Privacy</p>

                  <p className='ml-5 mb-10'>
                    We collect and store user data, including name, email, and payment information, necessary for providing our services.
                    For more details on how we handle your data, please refer to our Privacy Policy.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Non-Personal Data Collection</p>


                  <p className='ml-5 mb-10'>
                    We use web cookies to collect non-personal data for the purpose of improving our services and user experience.
                  </p>
                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>
                    Governing Law
                  </p>

                  <p className='ml-5 mb-10'>
                    These Terms are governed by the laws of the relevant jurisdiction.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Refund and Cancellation Policy</p>

                  <p className='ml-5 mb-10'>
                    All purchases made on our platform are final and non-refundable.
                    Please ensure that you have selected the correct product before making a payment.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Updates to the Terms</p>

                  <p className='ml-5 mb-10'>
                    We may update these Terms from time to time. Users will be notified of any changes via email.
                  </p>

                  <p className='font-semi-bold ml-5 mb-3 font-semibold text-md'>Contact Information</p>
                  <p className='ml-5 mb-10'>
                    For any questions or concerns regarding these Terms of Service, please contact us at:
                  </p>

                  <p className='ml-9 mb-6'>
                    Email: support@deploybydesign.com<br />
                    Address: C-26, Vidhyut Nagar, Chitrakoot Marg, Near Ajmer Road, Jaipur, India<br /><br />

                    Thank you for using DeployByDesign!<br />
                  </p>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' rounded="3xl" mr={4} mb={6} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </div>
    </div>
  );
}
