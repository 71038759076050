import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import img from '../images/bg-supp.jpg';
import { loginAPI, signupAPI } from '../services/operations/authAPI'

const Signup = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [countryCode, setCountryCode] = useState("+1");
    const [phone, setPhone] = useState("");
    const [organization, setOrganization] = useState("");

    
  
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
  
    const [loading, setLoading] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const fullPhone = `${countryCode}${phone}`;
            await dispatch(signupAPI(firstname, lastname, email, password, confirmPwd, fullPhone, organization, nav));
        } catch (error) {
            console.error("Signup error:", error);
        } finally {
            setLoading(false);
        }
    };

  return (
    <div className=''>
        <div className='flex'>
            <div className="md:w-[70%]">
                <div className='sm:hidden md:block' style={{ 
                    backgroundImage: `url(${img})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}></div>
            </div>
            <div className='flex flex-col xs:w-[80%] p-1 mt-10 md:w-[25%] bg-white mx-auto'>
                <p className="text-md mb-6 ml-4">
                    <span className='text-gray-700 text-md'>Sign Up to</span><br></br>
                    <span className='text-green-900 font-semibold text-2xl'>DeployByDesign</span>
                </p>
                <div className="flex flex-col gap-y-2 bg-white md:w-[90%] xs:w-[90%] rounded-md mx-auto text-sm p-2">
                    <div className='xs:flex xs:flex-row md:flex md:flex-col gap-y-2 xs:w-full xs:gap-x-2'>
                        <div id="firstname" isRequired>
                            <input
                            value={firstname}
                            type="text"
                            placeholder="First Name"
                            onChange={(e) => setFirstname(e.target.value)}
                            className='p-2 border w-full'
                            />
                        </div>
                        <div id="lastname" isRequired>
                            <input
                            value={lastname}
                            type="text"
                            placeholder="Last Name"
                            onChange={(e) => setLastname(e.target.value)}
                            className='flex-grow p-2 border w-full'
                            />
                        </div>
                    </div>
                        <div id="email" isRequired>
                            <input
                            value={email}
                            type="Email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            className='p-2 border w-full'
                            />
                        </div>
                        <div id="password" className='text-sm' isRequired>
                            <input
                                value={password}
                                type={show ? "text" : "password"}
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                className='p-2 border w-full'
                            />
                        </div>
                        <div id="confirmPwd" className='text-sm' isRequired>
                            <input
                                value={confirmPwd}
                                type={show ? "text" : "password"}
                                placeholder="Confirm Password"
                                onChange={(e) => setConfirmPwd(e.target.value)}
                                className='p-2 border w-full'
                            />
                        </div>
                        <div id="phone" className='text-sm flex' isRequired>
                        <select
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                                className='p-2 border mr-2 w-[20%]'
                            >
                                <option value="+1">+1 (US)</option>
                                <option value="+91">+91 (India)</option>
                                <option value="+44">+44 (UK)</option>
                                <option value="+61">+61 (Australia)</option>
                                <option value="+49">+49 (Germany)</option>
                                <option value="+33">+33 (France)</option>
                                <option value="+86">+86 (China)</option>
                                <option value="+55">+55 (Brazil)</option>
                                <option value="+39">+39 (Italy)</option>
                                <option value="+7">+7 (Russia)</option>
                                <option value="+52">+52 (Mexico)</option>
                                <option value="+34">+34 (Spain)</option>
                                <option value="+27">+27 (South Africa)</option>
                                <option value="+971">+971 (UAE)</option>
                                <option value="+82">+82 (South Korea)</option>
                                <option value="+62">+62 (Indonesia)</option>
                                <option value="+964">+964 (Iraq)</option>
                                <option value="+98">+98 (Iran)</option>
                                <option value="+60">+60 (Malaysia)</option>
                                <option value="+63">+63 (Philippines)</option>
                                <option value="+92">+92 (Pakistan)</option>
                                <option value="+66">+66 (Thailand)</option>
                                <option value="+20">+20 (Egypt)</option>
                                <option value="+234">+234 (Nigeria)</option>
                                <option value="+213">+213 (Algeria)</option>
                                <option value="+90">+90 (Turkey)</option>
                            </select>
                            <input
                                value={phone}
                                type="text"
                                placeholder="Phone"
                                onChange={(e) => setPhone(e.target.value)}
                                className='p-2 border w-full'
                            />
                        </div>
                        <div id="organization" isRequired>
                            <input
                            value={organization}
                            type="text"
                            placeholder="Organization"
                            onChange={(e) => setOrganization(e.target.value)}
                            className='p-2 border w-full'
                            />
                        </div>
                        <button
                            style={{ marginTop: 15 }}
                            onClick={submitHandler}
                            isLoading={loading}
                            className='btn btn-primary mb-6 text-sm'
                        >
                            Signup
                        </button>
                        <p className='-mt-4 text-center'>Already have an account? <Link to='/login' className='text-blue-700'>Login</Link> here</p>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Signup