import React from 'react';
import { Box, Center, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import NotFoundSVG from '../images/404-error-page.svg';
import Footer from './Footer';
import Navbar from './Navbar';
import img1 from '../images/bg6.jpg';

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <div>
                <div className="bg-cover pt-4" style={{ backgroundImage: `url(${img1})` }}>

                    <Navbar />
                    <Center minH="80vh" bg="white" alignItems='center' className='mt-16' mx={'auto'}>
                        <VStack spacing={6} textAlign="center">
                            <Box width="80%" maxWidth="500px">
                                <img src={NotFoundSVG} alt="Page Not Found Illustration" />
                            </Box>
                            <Heading as="h1" size="2xl" color="#3758F9">
                                404 - Page Not Found
                            </Heading>
                            <Text fontSize="lg" color="gray.600">
                                Oops! The page you're looking for doesn't exist. Please check the URL and try again.
                            </Text>
                            <Button bg="#3758F9" colorScheme="blue" mb={16} onClick={() => navigate('/')}>
                                Go back to Home
                            </Button>
                        </VStack>
                    </Center>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default PageNotFound;
