import React, { useState } from 'react'
import Navbar from './Navbar';
import img1 from '../images/card-bg2.jpg';
import img2 from '../images/bg6.jpg';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const WorkspacePrev = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [organization, setOrganization] = useState("");
  const [question, setQuestion] = useState("");
  return (
    <div>
      <div className="bg-cover pt-20" style={{ backgroundImage: `url(${img2})` }}>
        <Navbar/>
        <div className='flex flex-col mt-20 mx-auto w-[90%]'>
          <div className='md:mb-20 w-full flex flex-col text-center'>
            <p className='font-bold text-5xl mb-5'>Workspace</p>
            <p className='text-lg text-gray-700 mt-3 mb-5'>
              Work collaboratively. Process data faster. Discover more.
            </p>
            <a href="#support" className="text-white mx-auto my-6 text-lg bg-blue-700 w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" id='#contact'>Request a Demo</a>
          </div>
          <div className='md:mb-8  md:flex md:flex-row md:gap-x-10 sm:flex sm:flex-col mt-16 sm:gap-y-10 mb-20'>
            <div className="md:w-[70%] xs:w-full xs:h-48 md:mb-0 md:h-64 bg-cover bg-center border shadow-xl" style={{ backgroundImage: `url(${img1})`, height: '390px', backgroundRepeat: 'no-repeat'}}></div>
            <div className='md:w-1/3 xs:mt-20 md:mt-0 md:py-5 flex flex-col md:justify-between md:items-start'>
              <p className='text-2xl font-bold mb-3'>What is it?</p>
              <p className='text-md font-semibold mb-5'>DeployByDesign Workspace </p>
              <p className='mb-6 text-sm'> By offering intuitive tools and a drag-and-drop interface, it enables users to design, automate, and deploy workflows without coding expertise. This approach streamlines complex tasks, making workflow automation accessible to all, improving efficiency, and enhancing productivity across various industries.</p>
              <Link to='/login'  className="text-white xs:mt-7 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-fit sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your portal</Link>
            </div>
          </div>
          <div  id='support' className='md:mt-20 md:mb-8 md:flex md:flex-row md:gap-x-10 sm:flex sm:flex-col'>
            <div className='flex flex-col justify-center sm:items-center md:items-start w-[1/2]'>
              <p className='font-semibold text-4xl'>Technical & Analytical Support</p>
              <p className='mt-5'>Once registered, DeployByDesign users have access <br/>to the technical and analytical support.</p>
                 <Link to='/signup' className="text-white my-6 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-fit sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Sign Up</Link>
            </div>
            <div className='flex-grow'>
                <div className="md:w-[100%] bg-white mx-auto border shadow-lg rounded-md p-6">
                  <form class="max-w-xl mx-auto"> 
                  <p className='text-xl font-semibold mt-5 mb-8 text-center'>Contact Us</p>
      
                  <div class="flex gap-x-8">
                      <div class="relative z-0 w-full mb-8 group">
                          <input type="text" name="first_name" value={firstname} onChange={(e) => setFirstname(e.target.value)} id="first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                          <label for="first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
                      </div>
                      <div class="relative z-0 w-full mb-8 group">
                          <input type="text" name="last_name" value={lastname} onChange={(e) => setLastname(e.target.value)} id="last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                          <label for="last_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name</label>
                      </div>
                    </div>
                    <div className='flex gap-x-8'>
                    <div class="relative z-0 w-full mb-8 group">
                        <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                    </div>
                    <div class="relative z-0 w-full mb-8 group">
                        <input type="email" name="confirm-email" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} id="confirm-email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="confirm-email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirm Email</label>
                    </div>
                    </div>
                  
                    <div class="flex gap-x-8">
                      <div class="relative z-0 w-full mb-8 group">
                          <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                          <label for="phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone Number</label>
                      </div>
                      <div class="relative z-0 w-full mb-8 group">
                          <input type="text" name="country" value={country} onChange={(e) => setCountry(e.target.value)} id="country" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                          <label for="country" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Country</label>
                      </div>
                    </div>
                    <div class="relative z-0 w-full mb-8 group">
                        <input type="organization" name="organization" value={organization} onChange={(e) => setOrganization(e.target.value)} id="organization" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="organization" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Organization</label>
                    </div>
                    <div class="relative z-0 w-full mb-8 group">
                        <textarea value={question} type="question" name="question" onChange={(e) => setQuestion(e.target.value)} id="question" rows={4} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="question" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Question</label>
                    </div>
                    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                  </form>
                </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default WorkspacePrev