import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import img from '../images/bg-supp.jpg';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const [username, setUsername] = useState("");

    const submitHandler = (e) => {
        // const res = resetPasswordAPI();
    }

  return (
    <div className=''>
        <div className='flex'>
            <div className="md:w-[70%]">
                <div className='sm:hidden md:block' style={{ 
                    backgroundImage: `url(${img})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}></div>
            </div>
            <div className='flex flex-col xs:w-[80%] p-1 mt-10 md:w-[25%] bg-white mx-auto'>
                <p className="text-md mb-6 mx-auto justify-center">
                    <span className='text-gray-700 text-lg text-center justify-center font-semibold'>Reset your Password</span><br></br>
                </p>
                <div className="flex flex-col gap-y-2 bg-white md:w-[90%] xs:w-[85%] rounded-md mx-auto text-sm p-2">
                        <div id="username" isRequired>
                            <input
                            value={username}
                            type="username"
                            placeholder="Enter email address"
                            onChange={(e) => setUsername(e.target.value)}
                            className='p-2 border w-full'
                            />
                        </div>
                        
                        <button
                            style={{ marginTop: 15 }}
                            onClick={submitHandler}
                            className='btn btn-primary mb-6 text-sm'
                        >
                            Reset Password
                        </button>

                    </div>
                </div>
            </div>
        </div>
  )
}

export default ForgotPassword