import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice';
import flowReducer from "./flowSlice";
import workspaceReducer from "./workspaceSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        flow: flowReducer,
        workspace: workspaceReducer
    },
});

export default store;