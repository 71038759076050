import { Menu, MenuButton } from '@chakra-ui/react';

import Navbar from './Navbar';

import { generateRoomId } from '../utils/client-utils';
import { useNavigate } from "react-router";

export default function VideoConferencePage() {
    const navigate = useNavigate();
    const handleCreateMeeting = () => {
        const meet = generateRoomId(5);
        navigate(`/meeting?id=${meet}&region=in&codec=VP8`);
    }
    return (
        <div>
            <div className="bg-cover pt-20">
                <Navbar />
                <div className='  flex flex-col h-screen     p-6'>

                    <div className='flex justify-between'>
                        <p className='pt-6 pl-6 text-xl font-bold'>Video Conferencing</p>
                        <Menu>
                            <MenuButton as="button" type="button" onClick={() => handleCreateMeeting()} className="flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                Create Meeting
                            </MenuButton>
                        </Menu>
                    </div>

                </div>
            </div>
        </div>
    );
}