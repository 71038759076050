import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workspaceList: sessionStorage.getItem('workspace') || [],
    isRunning: sessionStorage.getItem('isRunning') || false,
    isProcessing: sessionStorage.getItem('isProcessing') || false,
    workspaceId: sessionStorage.getItem('workspaceId') || "",
    streamingURL: sessionStorage.getItem('streamingURL') || "",
};

const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload
        },
        setWorkspace: (state, action) => {
            state.workspaceList = action.payload;
            sessionStorage.setItem('workspace', action.payload);
        },
        removeWorkspace: (state, action) => {
            state.workspaceList = [];
            state.isRunning = false;
            state.isProcessing = false;
            state.workspaceId = "";
            sessionStorage.removeItem('workspaceId');
            sessionStorage.removeItem('workspace');
            sessionStorage.removeItem('streamingURL');
            sessionStorage.removeItem('isRunning');
            sessionStorage.removeItem('isProcessing');
        },
        updateWorkspaceStatus: (state, action) => {
            state.isRunning = action.payload.isRunning;
            state.isProcessing = action.payload.isProcessing;
            sessionStorage.setItem('isRunning', action.payload.isRunning);
            sessionStorage.setItem('isProcessing', action.payload.isProcessing);
        },
        updateWorkspaceIdAndURL: (state, action) => {
            state.workspaceId = action.payload.workspaceId;
            state.streamingURL = action.payload.streamingURL;
            sessionStorage.setItem('workspaceId', action.payload.workspaceId);
            sessionStorage.setItem('streamingURL', action.payload.streamingURL);
        },
        removeWorkspaceIdAndURL: (state, action) => {
            state.workspaceId = "";
            state.streamingURL = "";
            
            sessionStorage.removeItem('workspaceId');
            sessionStorage.removeItem('streamingURL');
        },
    },
});

export const { setWorkspace, removeWorkspace, setLoading, updateWorkspaceStatus, updateWorkspaceIdAndURL, removeWorkspaceIdAndURL } = workspaceSlice.actions;
export default workspaceSlice.reducer;