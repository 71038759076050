import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Workflow from './Workflow';
import Billing from './Billing';
import Data from './Data';
import Datasets from './Datasets';
import Compute from './Compute';

const Workspace = () => {
    const nav = useNavigate();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    useEffect(() => {
        if(!isLoggedIn) {
            nav('/workspace-prev');
        }
    }, [isLoggedIn, nav]);

    const [activeTab, setActiveTab] = useState(0);
    const tabNames = ['Data Storage', 'Design & Build', 'Billing', 'Datasets', 'Compute'];

  return isLoggedIn? (
    <div className='p-6 pt-24'>
        <Tabs className='bg-white'  align='start' variant='enclosed' index={activeTab} onChange={(index) => setActiveTab(index)}>
        <TabList
            mb='1em'
            display={{ base: 'none', md: 'flex' }} // Shows only on md and larger screens
            className="w-[900px]"
        >
            {tabNames.map((tab, index) => (
                <Tab
                    key={index}
                    width="full"
                    height="full"
                    className={`font-bold text-sm ${index === activeTab ? 'text-blue-600' : ''}`}
                >
                    {tab}
                </Tab>
            ))}
        </TabList>
        <div className='xs:block md:hidden mb-4 px-4'>
          <select
            value={activeTab}
            onChange={(e) => setActiveTab(Number(e.target.value))}
            className='p-2 border rounded-md w-full'
          >
            {tabNames.map((name, index) => (
              <option key={index} value={index}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <TabPanels>
            <TabPanel>
                <Data/>
            </TabPanel>
            <TabPanel>
                <Workflow />
            </TabPanel>
            <TabPanel>
                <Billing/>
            </TabPanel>
            <TabPanel>
                <Datasets />
            </TabPanel>
            <TabPanel>
                <Compute />
             </TabPanel>
        </TabPanels>
        </Tabs>
        </div>
  ) : null;
}

export default Workspace;