const BASE_URL = 'https://apis.deploybydesign.com'

// Auth Endpoints
export const authEndpoints = {
    LOGIN_API: BASE_URL + "/login/",
    SIGNUP_API: BASE_URL + "/sign_up/"
}

// Blueprint Endpoints
export const blueprintEndpoints = {
    CREATE_BLUEPRINT: BASE_URL + "/blueprint/create_blueprint/",
    UPDATE_BLUEPRINT: BASE_URL + "/blueprint/update_blueprint/",
    DELETE_BLUEPRINT: BASE_URL + "/blueprint/delete_blueprint/",
    DOWNLOAD_BLUEPRINT: BASE_URL + "/blueprint/download_blueprint/",
    SEARCH_BLUEPRINT: BASE_URL + "/blueprint/search_blueprints/",
    LIST_BLUEPRINTS: BASE_URL + "/blueprint/list_blueprints/",
    RETRIEVE_BLUEPRINT: BASE_URL + "/blueprint/retrieve_blueprint/",
}

// Metadata Endpoints
export const metadataEndpoints = {
    CREATE_METADATA: BASE_URL + "/metadata/create_metadata/",
    UPDATE_METADATA: BASE_URL + "/metadata/update_metadata/",
    RETRIEVE_METADATA: BASE_URL + "/metadata/retrieve_metadata/",
    DELETE_METADATA: BASE_URL + "/metadata/delete_metadata_file/",
    DELETE_METADATA_PARAM: BASE_URL + "/metadata/delete_metadata_parameter/",
}

// Miscellaneous Endpoints
export const miscellaneousEndpoints = {
    SUBMIT_SUPPORT_FORM: BASE_URL + "/miscellaneous/submit_support_form/",
    SUBMIT_CONTACT_FORM: BASE_URL + "/miscellaneous/submit_contact_form/",
    NEWSLETTER: BASE_URL + "/miscellaneous/subscribe"
}

// Profile Endpoints
export const profileEndpoints = {
    SAVE_PERSONAL_INFO: BASE_URL + "/profile/save_personal_info/",
    ADD_CONNECTION: BASE_URL + "/profile/add_connection/",
    LIST_ALL_CONNECTIONS: BASE_URL + "/profile/list_all_connections/",
    FETCH_PERSONAL_INFO: BASE_URL + "/profile/fetch_personal_info/"
}

// Upload & Download Endpoints
export const uploadDownloadEndpoints = {
    LIST_ALL_FILES: BASE_URL + "/file/list_all_files/",
    UPLOAD_FILE: BASE_URL + "/file/upload_file/",
    DOWNLOAD_FILE: BASE_URL + "/file/download_file/",
    DELETE_FILE: BASE_URL + "/file/delete_file/"
}

// Fetch Billing Data Endpoint
export const billingEndpoints = {
    FETCH_BILLING: BASE_URL + "/billing/get_monthly_billing"
}

// Chatbot Endpoints
export const chatbotEndpoints = {
    CHATBOT: BASE_URL + "/dashboard/chatbot"
}