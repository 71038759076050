import React, { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useSelector } from 'react-redux';
import img from "../images/bg6.jpg";
import img1 from "../images/card-bg1.jpg";
import img2 from "../images/card-bg2.jpg";
import img3 from "../images/card-bg3.jpg";
import img4 from "../images/card-bg4.jpg";
import img5 from "../images/card-bg9.jpg";
import img6 from "../images/card-bg6.jpg";
import img7 from '../images/grad-bg1.jpg'
import img8 from '../images/grad-bg2.jpg'
import img9 from '../images/grad-bg3.jpg'
import img10 from '../images/grad-bg4.jpg'
import img11 from '../images/grad-bg5.jpg'
import img12 from '../images/grad-bg6.jpg'

import client1 from "../images/vitafoam.png";
import client2 from "../images/client2.png";
import client3 from "../images/client3.png";
import client4 from "../images/client4.png";
import Pricing from '../components/Pricing';
import Testimonial from '../components/Testimonial';
import CookieConsent from 'react-cookie-consent';
import Features from '../components/Features';
import Newsletter from '../components/Newsletter';

const Homepage = () => {
    const location = useLocation();
   
    const { isLoggedIn, username } = useSelector((state) => state.auth);

    useEffect(() => {
        const handleNextClick = () => {
          let lists = document.querySelectorAll(".item");
          document.getElementById("slide").appendChild(lists[0]);
        };
    
        const handlePrevClick = () => {
          let lists = document.querySelectorAll(".item");
          document.getElementById("slide").prepend(lists[lists.length - 1]);
        };
    
        const nextButton = document.getElementById("next");
        const prevButton = document.getElementById("prev");
    
        if (nextButton && prevButton) {
          nextButton.addEventListener("click", handleNextClick);
          prevButton.addEventListener("click", handlePrevClick);
        }
    
        return () => {
          if (nextButton && prevButton) {
            nextButton.removeEventListener("click", handleNextClick);
            prevButton.removeEventListener("click", handlePrevClick);
          }
        };
      }, []);    

  return (
    <div className='w-screen' style={
        isLoggedIn
          ? { backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center' }
          : {}
      }>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#f3f3f3", color: "black", fontSize: '15px' }}
          buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
          expires={150}
          enableDeclineButton
        >DeployByDesign uses information collected through cookies to improve your experience
        on our website, analyze how it is used, and show personalized content, including advertisements. By clicking on
        "I understand", you agree to the storing of cookies on your device to enhance site navigation, analyze site 
        usage, and assist in our marketing efforts.</CookieConsent>
      <Navbar />
      <Outlet />
      {location.pathname === '/' && (
        <>
          {!isLoggedIn ? (
             
            <>
            <Newsletter />
              <div className="flex my-auto max-w-screen items-center justify-evenly transform translate-x-[0.002%] translate-y-[-40%] transition-all h-[90vh] mt-72 -mb-72 lg:-mb-52">
             <div id="slide">
                 <div className="item" style={{backgroundImage: `url(${img1})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                 <div className="content cursor-pointer">
                     <div className="name">Email Automation</div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
         
                     <div className="des text-[#000000] text-sm">
                     AI agents autonomously draft, schedule, and send personalized emails based on customer interactions and preferences, ensuring timely communication for inquiries and marketing campaigns with minimal human intervention.                     </div>
                 </div>
                 </div>
                 <div className="item" style={{backgroundImage: `url(${img2})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                 <div className="content cursor-pointer">
                     <div className="name">Chatbot Automation</div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our AI-driven chatbots engage with customers in real-time, resolving queries, offering product recommendations, and improving customer support, reducing the need for manual intervention.                     </div>
                 </div>
                 </div>
                 <div className="item" style={{backgroundImage: `url(${img3})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                 <div className="content cursor-pointer">
                     <div className="name">
                         Text to SQL Automation
                     </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our AI-powered workflows convert natural language queries into SQL, enabling non-technical users to easily interact with databases and access complex data insights without SQL knowledge.
                     </div>
                 </div>
                 </div>
                 <div className="item" style={{backgroundImage: `url(${img4})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                 <div className="content cursor-pointer">
                     <div className="name">
                         Call Center Automation
                     </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     AI agents in call centers analyze and respond to customer inquiries via voice or text, delivering instant resolutions or escalating complex cases, reducing wait times, enhancing satisfaction, and streamlining operations.                     </div>
                 </div>
                 </div>
                 <div className="item" style={{backgroundImage: `url(${img5})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                 <div className="content cursor-pointer">
                     <div className="name">
                        Image Processing and Automation                          
                      </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our AI workflows process images for tasks like object detection, facial recognition, and automatic tagging, making them ideal for e-commerce, media, and security industries where fast image analysis is crucial.                     </div>
                 </div>
                 </div>
                 <div className="item" style={{backgroundImage: `url(${img2})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                 <div className="content cursor-pointer">
                     <div className="name">
                     Text-to-Video Automation
                      </div>
                     <div className="w-16 h-1 mt-2 bg-sky-800"></div>
                     <div className="des text-[#000000] text-sm">
                     Our AI agents automate video creation by converting text into engaging videos, perfect for marketing, training, or customer onboarding, saving time and effort with visually rich, intelligent outputs.                     </div>
                 </div>
                 </div>
             </div>
         
             <div className="buttons w-[87%]  bottom-[6%] -mt-7 absolute z-10 flex flex-row space-x-2  ">
                 <button id="prev">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                     <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                 </svg>
                 </button>
                 <button id="next">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                   <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                 </svg>
                 </button>
             </div>
              </div>

              {/* features 1 */}
              <section class="mt-6 text-gray-600 body-font">
                <div class="px-20 py-24 mx-auto">
                  <div className='flex mx-auto mt-6 mb-20 md:text-5xl xs:text-3xl text-center'>
                  Accelerate Innovation with Seamless Access to Powerful Computing
                  </div>
                  <div class=" flex flex-wrap -m-4 xs:-mb-36">
                    <div class="bblock p-4 md:w-1/3 xs:w-full sm:w-1/2">
                      <div class="flex rounded-lg h-full w-full bg-gray-100 p-8 flex-col">
                        <div class="flex items-center mb-3">
                          <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                            </svg>
                          </div>
                          <h2 class="text-gray-900 text-lg title-font font-medium">Cloud Computation</h2>
                        </div>
                        <div class="flex-grow">
                          <p class="leading-relaxed text-base">
                          Access powerful cloud processing from any computer
                          </p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="bblock p-4 md:w-1/3 xs:w-full sm:w-1/2">
                      <div class="flex rounded-lg h-full w-full p-8 flex-col bg-gray-100" >
                        <div class="flex items-center mb-3">
                          <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </div>
                          <h2 class="text-gray-900 text-lg title-font font-medium">Data Access</h2>
                        </div>
                        <div class="flex-grow">
                          <p class="leading-relaxed text-base">
                          Get integrated access to diverse datasets
                          </p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="bblock p-4 md:w-1/3 xs:w-full sm:w-1/2">
                      <div class="flex rounded-lg h-full w-full p-8 flex-col bg-gray-100" >
                        <div class="flex items-center mb-3">
                          <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                              <circle cx="6" cy="6" r="3"></circle>
                              <circle cx="6" cy="18" r="3"></circle>
                              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                            </svg>
                          </div>
                          <h2 class="text-gray-900 text-lg title-font font-medium">Secure Collaboration</h2>
                        </div>
                        <div class="flex-grow">
                          <p class="leading-relaxed text-base">Safely share workspaces with collaborators across the globe</p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="bblock p-4 md:w-1/3 xs:w-full sm:w-1/2">
                      <div class="flex rounded-lg h-full w-full p-8 flex-col bg-gray-100">
                        <div class="flex items-center mb-3">
                          <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                              <circle cx="6" cy="6" r="3"></circle>
                              <circle cx="6" cy="18" r="3"></circle>
                              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                            </svg>
                          </div>
                          <h2 class="text-gray-900 text-lg title-font font-medium">Machine Learning</h2>
                        </div>
                        <div class="flex-grow">
                          <p class="leading-relaxed text-base">Improve your research with systems that learn and improve automatically</p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="bblock p-4 md:w-1/3 xs:w-full sm:w-1/2">
                      <div class="flex rounded-lg h-full w-full p-8 flex-col bg-gray-100">
                        <div class="flex items-center mb-3">
                          <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                              <circle cx="6" cy="6" r="3"></circle>
                              <circle cx="6" cy="18" r="3"></circle>
                              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                            </svg>
                          </div>
                          <h2 class="text-gray-900 text-lg title-font font-medium">Integrated Applications</h2>
                        </div>
                        <div class="flex-grow">
                          <p class="leading-relaxed text-base">
                          Customize and code based on the needs of your project
                          </p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="bblock p-4 md:w-1/3 xs:w-full sm:w-1/2">
                      <div class="flex rounded-lg h-full w-full p-8 flex-col bg-gray-100">
                        <div class="flex items-center mb-3">
                          <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                              <circle cx="6" cy="6" r="3"></circle>
                              <circle cx="6" cy="18" r="3"></circle>
                              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                            </svg>
                          </div>
                          <h2 class="text-gray-900 text-lg title-font font-medium">Data Storage & Sharing</h2>
                        </div>
                        <div class="flex-grow">
                          <p class="leading-relaxed text-base">
                          Securely store and analyze data
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* features 2 */}
              <div className='flex flex-col'>
                <div class="mt-16 w-[80%] text-gray-600 body-font px-5 py-24 mx-auto text-center">
                  <h2 class="mx-auto sm:text-3xl xs:text-2xl md:text-5xl text-gray-900 font-medium title-font mb-2 xs:-mb-14">Focusing on quality, <span className='text-gray-500'>we maintain customer trust </span></h2>
                </div>
                <ul className='bblock flex mx-auto md:gap-x-5 xs:gap-y-3 md:grid md:grid-cols-3 xs:grid xs:grid-cols-1'>
                  <li style={{backgroundImage: `url(${client1})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} className='bblock rounded-3xl h-56 w-56 border border-gray-200 cursor-pointer hover:bg-gray-50 shadow-xl hover:shadow-none flex items-center justify-center font-semibold transition-all duration-200'></li>
                  <li style={{backgroundImage: `url(${client2})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} className='bblock h-56 w-56 rounded-3xl border border-gray-200 cursor-pointer hover:bg-gray-50 shadow-xl hover:shadow-none flex items-center justify-center font-semibold transition-all duration-200'></li>
                  <li style={{backgroundImage: `url(${client4})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} className='bblock h-56 w-56 rounded-3xl border border-gray-200 cursor-pointer hover:bg-gray-50 shadow-xl hover:shadow-none flex items-center justify-center font-semibold transition-all duration-200'></li>
                </ul>
              </div>
              <Features/>
              <Testimonial/>
              <Pricing />
            </>
          ) : (
            <div className='pl-10 pt-12 mb-12 ml-6 mr-6'>
              <p className='font-semibold text-2xl pt-20 pb-5 mb-6'>Welcome {username}</p>
              <div className='md:grid md:grid-cols-3 gap-x-8 gap-y-8 sm:grid sm:grid-cols-2 xs:grid xs:grid-cols-1'>
                <Link to='/email' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Email Automation</p>
                  <p className='text-sm mb-4'>
                  AI agents autonomously draft, schedule, and send personalized emails based on customer interactions and preferences, ensuring timely communication for inquiries and marketing campaigns with minimal human intervention.    
                  </p>
                </Link>
                <Link to='/chatbot' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Chatbot Automation</p>
                  <p className='text-sm mb-4'>
                  Our AI-driven chatbots engage with customers in real-time, resolving queries, offering product recommendations, and improving customer support, reducing the need for manual intervention.                    
                  </p>
                </Link>
                <Link to='/texttosql' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Text to SQL Automation</p>
                  <p className='text-sm mb-4'>
                  Our AI-powered workflows convert natural language queries into SQL, enabling non-technical users to easily interact with databases and access complex data insights without SQL knowledge.
                  </p>
                </Link>
                <Link to='/multimodality' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Text-to-Video Automation</p>
                  <p className='text-sm mb-4'>
                  Our AI agents automate video creation by converting text into engaging videos, perfect for marketing, training, or customer onboarding, saving time and effort with visually rich, intelligent outputs.       
                  </p>
                </Link>
                <Link to='/call-center' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Call Center Automation</p>
                  <p className='text-sm mb-4'>
                  AI agents in call centers analyze and respond to customer inquiries via voice or text, delivering instant resolutions or escalating complex cases, reducing wait times, enhancing satisfaction, and streamlining operations.                  
                  </p>
                </Link>
                <Link to='/video-conferencing' className='border border-slate-300 cursor-pointer border-b-green-900 border-b-4 bg-white shadow-2xl p-8 rounded-sm hover:scale-105 transition-all duration-200'>
                  <p className='mb-4 text-xl'>Video Conferencing Solution</p>
                  <p className='text-sm mb-4'>
                  Our newgen Video Conferencing AI solution enhances virtual meetings with features like real-time transcription, automated note-taking, and intelligent participant tracking, making it ideal for businesses, educational institutions, and remote teams seeking to improve communication and productivity.
                  </p>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
      <Footer />
    </div>
  );
};
    

export default Homepage