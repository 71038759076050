import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice';
import flowReducer from "./flowSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        flow: flowReducer,
    },
});

export default store;