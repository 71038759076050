import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CallCenter = () => {
    const nav = useNavigate();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); //getting login state from redux
    useEffect(() => {
      if(!isLoggedIn) {
          nav('/login');
      }
  }, [isLoggedIn, nav]);

  const [note, setNote] = useState('');

  const handleBack = () => {
    window.history.back();
  }
  return (
    <div className='pt-20'>
        <div onClick={handleBack} type="button" class="cursor-pointer ml-8 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
      </svg>
      </div> 
      <div className='font-semibold flex justify-between mx-auto w-[90%]'>
        <div className='flex gap-x-2 bg-slate-100 justify-center items-center'>
          <p className='font-semibold p-2 '>Live Calls</p>
          <i class="ri-arrow-down-s-line mr-2"></i>
        </div>
        <div className='flex gap-x-2 bg-slate-50'>
          <p className='cursor-pointer border border-gray-400 p-2'>All live calls (1)</p>
          <p className='cursor-pointer border border-gray-400 p-2'>Live calls with Interest</p>
        </div>
      </div>
      <div className='bg-slate-50 w-[90%] h-[400px] mx-auto'>
        

<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Agent Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Status
                </th>
                <th scope="col" class="px-6 py-3">
                    Duration
                </th>
                <th scope="col" class="px-6 py-3">
                    Moments of Interest
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Ramit Surana
                </th>
                <td class="px-6 py-4">
                    On-Call
                </td>
                <td class="px-6 py-4">
                    10:10
                </td>
                <td class="px-6 py-4 flex gap-x-2">
                    <div className='text-blue-500 w-fit bg-blue-100 border border-blue-400 p-1 rounded-md'>
                      Supervisor Escalation (09:12)
                    </div>
                    <div className='text-blue-500 w-fit bg-blue-100 border border-blue-400 p-1 rounded-md'>
                      Negative Customer Sentiment (09:12)
                    </div>
                </td>
                <td className='px-6 py-4'>
                <div className="drawer drawer-end relative">
                  <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
                  <div className="drawer-content  transition-transform duration-300 ease-in-out" id="drawer-content">
                    {/* Page content here */}
                    <label htmlFor="my-drawer-4" className="text-indigo-700 font-semibold cursor-pointer">View</label>
                  </div>
                  <div className="drawer-side z-20">
                    <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
                    <div className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
                      {/* Sidebar content here */}
                      <div className='flex flex-col gap-y-3'>
                        <p>On-Call - Ramit Surana</p>
                        <div className='w-[100%] mx-auto bg-gray-500 h-[1px]'></div>
                        <p>MOMENTS OF INTEREST</p>
                        <div className='flex flex-col gap-y-1'>
                          <div className='text-blue-500 w-fit bg-blue-100 border border-blue-400 p-1 rounded-md'>
                            Supervisor Escalation (09:12)
                          </div>
                          <div className='text-blue-500 w-fit bg-blue-100 border border-blue-400 p-1 rounded-md'>
                            Negative Customer Sentiment (09:12)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </td>
            </tr>
        </tbody>
    </table>
      </div>

      </div>
          {/* Open the modal using document.getElementById('ID').showModal() method */}
<button className="fixed p-3 bottom-8 right-8 z-50 rounded-xl bg-blue-800 hover:bg-blue-950 text-white drop-shadow-2xl" onClick={()=>document.getElementById('my_modal_1').showModal()}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>

</button>
<dialog id="my_modal_1" className="modal">
  <div className="modal-box xs:w-md h-[80%] max-w-lg">
    <div>
    <div className="flex mx-auto bg-gray-200 w-28 h-28 rounded-full"></div>
    <p className="py-2 px-4 w-fit mt-5 mx-auto text-center rounded-md bg-gray-100">Sulagna Banerjee</p>
    </div>
    <div className='mt-4 flex flex-col gap-y-2'>
      <p>Call Notes</p>
      <div className='bg-gray-200 w-[100%] h-[1px]'></div>
      <div className='bg-gray-50 h-44'>Notes</div>
      <input type='text' value={note} onChange={() => setNote(note)} name='note' id='note' placeholder='Add Note' className='bottom-2 py-2 w-full'/>
    </div>
    <div className="modal-action">
      <form method="dialog">
        {/* if there is a button in form, it will close the modal */}
        <button className="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>
    </div>
  )
}

export default CallCenter