import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: !!sessionStorage.getItem('isLoggedIn'),
    loading: false,
    username: sessionStorage.getItem('username') || "",
};

const authSlice = createSlice ({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLoggedIn = true;
            sessionStorage.setItem('isLoggedIn', 'true');
            state.username = action.payload;
            sessionStorage.setItem('username', action.payload);
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        logout: (state) => {
            state.isLoggedIn = false;
            sessionStorage.removeItem('isLoggedIn');
            sessionStorage.removeItem('username');
            state.username = "";
        },
    },
});

export const { login, logout, setLoading } = authSlice.actions;
export default authSlice.reducer;