import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import img from '../images/bg-supp.jpg';
import { loginAPI } from '../services/operations/authAPI'

const Login = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
  
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
  
    const [loading, setLoading] = useState(false);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(loginAPI(username, password, nav))
    }

  return (
    <div className=''>
        <div className='flex'>
            <div className="md:w-[70%]">
                <div className='sm:hidden md:block' style={{ 
                    backgroundImage: `url(${img})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}></div>
            </div>
            <div className='flex flex-col xs:w-[80%] p-1 mt-10 md:w-[25%] bg-white mx-auto'>
                <p className="text-md mb-6 ml-4">
                    <span className='text-gray-700 text-md'>Login to</span><br></br>
                    <span className='text-green-900 font-semibold text-2xl'>DeployByDesign</span>
                </p>
                <div className="flex flex-col gap-y-2 bg-white md:w-[90%] xs:w-[85%] rounded-md mx-auto text-sm p-2">
                        <div id="username" isRequired>
                            <input
                            value={username}
                            type="username"
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                            className='p-2 border w-full'
                            />
                        </div>
                        <div id="password" className='text-sm' isRequired>
                            <input
                                value={password}
                                type={show ? "text" : "password"}
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                className='p-2 border w-full'
                            />
                        </div>
                        <Link to='/forgot-password' className='text-blue float-right'>Forgot Password?</Link>
                        <button
                            style={{ marginTop: 15 }}
                            onClick={submitHandler}
                            isLoading={loading}
                            className='btn btn-primary mb-6 text-sm'
                        >
                            Login
                        </button>
                        <p className='-mt-4 text-center'>Don't have an account? <Link to='/signup' className='text-blue-700'>Signup</Link> here</p>

                    </div>
                </div>
            </div>
        </div>
  )
}

export default Login