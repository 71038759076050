import { toast } from 'react-hot-toast';
import { apiConnector } from '../apiConnector';
import { profileEndpoints } from '../apis';

const {
    SAVE_PERSONAL_INFO,
    ADD_CONNECTION,
    LIST_ALL_CONNECTIONS,
    FETCH_PERSONAL_INFO
} = profileEndpoints;

export function saveInfo(first_name, last_name, email_id, phone, address) {
    console.log({first_name, last_name, email_id, phone, address});
    return new Promise(async (resolve, reject) => {
        const toastID = toast.loading('Loading');

        try{
            const res = await apiConnector("POST", SAVE_PERSONAL_INFO, {
                first_name, last_name, email_id, phone, address
            })

            console.log("Save Personal API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Saved Personal Info Successfully');
            resolve(res);
        }
        catch(error) {
            console.log("PERSONAL INFO API ERROR: ", error)
            toast.error('Error in saving info');
            reject(error);
        }
        finally{
            toast.dismiss(toastID)
        }
    }
)}

export function addConnection(email_id, connection_name, type, aws_username, aws_access_key) {
    console.log({email_id, connection_name, type, aws_username, aws_access_key});
    return new Promise(async (resolve, reject) => {
        const toastID = toast.loading('Loading');

        try{
            const res = await apiConnector("POST", ADD_CONNECTION, {
                email_id, connection_name, type, aws_username, aws_access_key
            })

            console.log("ADD CONNECTION API Response: ", res)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            toast.success('Connection Added Successfully');
            resolve(res);
        }
        catch(error) {
            console.log("ADD CONNECTION API ERROR: ", error)
            toast.error('Error in adding connection');
            reject(error);
        }
        finally{
            toast.dismiss(toastID)
        }
    }
)}

export async function listConnection(email_id) {
    console.log({email_id});
    return new Promise(async (resolve, reject) => {

        try{
            const res = await apiConnector("POST", LIST_ALL_CONNECTIONS, {
                email_id
            })

            console.log("LIST ALL CONNECTION API Response: ", res.data.connections)

            if(res.status !== 200){
                throw new Error(res.message)
            }

            if(res.data.connections){
                toast.success("Connections fetched successfully")
            }
            resolve(res);
        }
        catch(error) {
            console.log("LIST ALL CONNECTION API ERROR: ", error)
            toast.error('Error in fetching connections');
            reject(error);

        }
        finally{
        }
    }
)}

export async function fetchInfo(email_id) {
    console.log({email_id});
    return new Promise(async (resolve, reject) => {

        try{
            const res = await apiConnector("POST", FETCH_PERSONAL_INFO, {
                email_id
            })
            console.log("FETCH PERSONAL INFO API Response: ", res.data.personal_info)

            if(res.status !== 200){
                throw new Error(res.message)
            }
            
            if(res.data.personal_info){
                toast.success("Personal Info Fetched Successfully")
            }
            resolve(res);

        }
        catch(error) {
            console.log("FETCH PERSONAL INFO API ERROR: ", error)
            // toast.error('Error in fetching info');
            reject(error);
        }
        finally{
        }
    }
)}
