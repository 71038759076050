import Navbar from './Navbar';
import { Box, Button, Checkbox, Text, VStack, HStack, Badge, Divider } from "@chakra-ui/react";
import VideoConferenceComponent from './VideoConferenceComponent'
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';

import { useSearchParams } from 'react-router-dom';
import { generateToken } from '../utils/client-utils';

export default function MeetingPage() {
    const [searchParams] = useSearchParams();

    const meetCode = searchParams.get('id');
    // /meeting?id=${meet}&region=in&codec=VP8
    const region = searchParams.get('region');
    const codec = searchParams.get('codec');
    const token = generateToken(meetCode);
    const handleBack = () => {
        window.history.back();
    }
    return (
        <div className='w-screen'>
            <div className="bg-cover pt-20">
                <Navbar />
                <Box className="p-6 min-h-screen">
                    <Box>
                        <div onClick={handleBack} type="button" className="cursor-pointer sm:ml-3 font-medium w-6 bg-white border rounded-lg shadow-md text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none hover:bg-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-3 size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                            </svg>
                        </div>
                    </Box>
                    <Box className="max-w-[90rem] mx-auto bg-white shadow-sm rounded-lg border border-gray-200">
                        <Box className="border-b border-gray-200">
                            <HStack className='flex justify-between' spacing={4} flexDirection={{ base: 'column', sm: 'row' }}>
                                <HStack className='w-full sm:w-auto justify-between px-4' spacing={4}>
                                    <Box>
                                        <Text className="font-bold text-3xl text-gray-800">Video Conferencing</Text>
                                    </Box>
                                </HStack>

                                {/* <HStack className='border-2 border-black p-4 justify-between sm:border-t-0' spacing={4}>
                                    <Text className="text-lg font-semibold text-gray-800">When you are done, you can submit this for review</Text>
                                    <Button size="lg" bg="black" color="white" borderRadius={0} px={8} _hover={{ bg: 'gray.700' }}>Review</Button>
                                </HStack> */}
                            </HStack>
                            <VideoConferenceComponent meetCode={meetCode} token={token} />
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

