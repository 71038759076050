import axios from 'axios';
import React, { useState } from 'react'

const Compute = () => {
  const [files, setFiles] = useState([{
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }, {
    dataset_name: "Cardiogenic Shock",
    data_documentation_link: "...",
    data_dictionary: "v2_2024_cs_research_data",
    coding_instructions: "CS coding",
    analysis_guidelines: "...",
  }
  ]);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [workspaceRunning, setWorkspaceRunning] = useState(false);

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const currentFiles = files.slice(firstItemIndex, lastItemIndex);

  const totalPages = Math.ceil(files.length / itemsPerPage);

  const handlePageChange = (pgNumber) => {
      setCurrentPage(pgNumber);
  };

  const [isOpened, setIsOpened] = useState(true);

  const [emailInput, setEmailInput] = useState('');

  const handleShareWorkspace = async () => {
    const emailList = emailInput.split(',').map(email => email.trim());

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmails = emailList.filter(email => emailRegex.test(email));
  
    if (validEmails.length !== emailList.length) {
      alert('Some email addresses are invalid. Please check and try again.');
      return;
    }
    console.log("validEmails", validEmails)

    try {
      // const response = await shareWorkspaceAPI(validEmails);
      // console.log('Workspace shared successfully:', response.data);
    } catch (error) {
      // console.error('Error sharing workspace:', error);
    }
  }

  const [workspaceTyp, setWorkspaceTyp] = useState('');

  const handleStartWorkspace = async () => {
    try {
      console.log(workspaceTyp);
      setWorkspaceRunning(true);
      // const response = await startWorkspaceAPI(workspaceTyp);
      // console.log('Workspace started successfully:', response.data);
    } catch (error) {
      // console.error('Error starting workspace:', error);
    }
  }

  const handleStopWorkspace = async () => {
    try {
      // const response = await stopWorkspaceAPI();
      // console.log('Workspace stopped successfully:', response.data);
    } catch (error) {
      // console.error('Error stopping workspace:', error);
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between mb-8'>
        <p className='text-2xl font-semibold'>Compute</p>
        <div className='flex gap-x-3'>
          {!workspaceRunning ? (
            <button className='btn' onClick={() => document.getElementById('modalstart').showModal()}>Start Workspace</button>
          ) : (
            <button className='btn' onClick={() => document.getElementById('modalstop').showModal()}>Stop Workspace</button>
          )}
          <button className='btn' onClick={() => document.getElementById('modalshare').showModal()}>Share Workspace</button>
        </div>

        <dialog id="modalstart" className="modal z-40">
            <div className="modal-box">
              <p className='text-base font-semibold mb-5'>Launch Workspace</p>
              <div className='bg-gray-200 h-0.5 w-full'></div>
              <form method="dialog">
                <button onClick={() => {
                        setWorkspaceRunning(false);
                      }} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
              <div className='flex flex-col gap-y-2 mt-4 mb-4'>
                <p className='text-sm'>Resuming your workspace takes about 30 minutes and the status will be
                  displayed as CREATING for this time period.
                </p>
                {/* <p>By clicking OK and accessing this system you accept the following:</p>
                <ul>
                  <li>1. Usage may be monitored, recorded, and subject to audit.</li>
                  <li>2. Usage is authorized only in adherence to the DeployByDesign's <a className='text-teal-500' href='/tos' target='_blank'>Terms of Service.</a></li>
                  <li>3. Use indicates consent to monitoring and recording.</li>
                </ul> */}
              </div>
              <div>
                <p className='mb-2 font-semibold'>Current Workspace Configuration</p>
                <table className='text-sm' style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                      <tr style={{ border: '1px solid black' }}>
                          <th style={{ border: '1px solid black', padding: '3px' }}>Type</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>vCPUs</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>Memory</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>GPUs</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>OS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ border: '1px solid black' }}>
                          <td style={{ border: '1px solid black', padding: '3px' }}>CPU</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>4</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>32GB</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>0</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>Amazon Linux 2</td>
                      </tr>
                    </tbody>
                </table>

                <div className='mt-3 mb-3 text-blue-600 flex gap-x-2'>
                  <p className='mt-4  text-sm'>Click here to change Workspace Configuration </p>
                  {!isOpened? (
                    <div className='cursor-pointer mt-4' onClick={(e) => setIsOpened(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    </div>
                    ) : (
                      <div className='cursor-pointer mt-4' onClick={(e) => setIsOpened(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                      </svg>
                    </div>
                    )}
                  </div>

                { isOpened && <table className='text-sm' style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                      <tr style={{ border: '1px solid black' }}>
                          <th style={{ border: '1px solid black', padding: '3px' }}>Type</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>vCPUs</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>Memory</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>GPUs</th>
                          <th style={{ border: '1px solid black', padding: '3px' }}>OS</th>
                      </tr>
                    </thead>
                  <tbody>
                      <tr style={{ border: '1px solid black' }}>
                          <td style={{ border: '1px solid black', padding: '3px' }}>
                          <div className='flex gap-x-4'>
                            <input type='radio' name='workspaceType' value="CPU" 
                              checked={workspaceTyp === 'CPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                            <p>CPU</p>
                          </div>
                          </td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>4</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>32GB</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>0</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>Amazon Linux 2</td>
                      </tr>
                      <tr style={{ border: '1px solid black' }}>
                          <td style={{ border: '1px solid black', padding: '3px' }}>
                          <div className='flex gap-x-4'>
                            <input type='radio' name='workspaceType' value="GPU" 
                              checked={workspaceTyp === 'GPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                            <p>GPU</p>
                          </div>
                          </td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>4</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>32GB</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>0</td>
                          <td style={{ border: '1px solid black', padding: '3px' }}>Amazon Linux 2</td>
                      </tr>
                      
                  </tbody>
              </table>
              }
              </div>

              <p className='flex gap-x-2 my-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-7 mt-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>

                    <p className='mt-4 text-sm font-semibold'>
                      For any additional compute requirements please email at
                        <a className='text-blue-500' href='mailto:support@deploybydesign.com'> support@deploybydesign.com</a>
                    </p>
                </p>

              <div className='mt-4 flex justify-between'>
                <form method="dialog">
                    <button className='btn' 
                      onClick={() => {
                        setWorkspaceRunning(false);
                      }}
                    >Cancel</button>
                  </form>
                <button className='btn'  onClick={() => {
                  handleStartWorkspace(workspaceTyp)
                }}>
                  Start
                </button>
                
              </div>
            </div>
          </dialog>

        {/* <dialog id="modal" className="modal z-10">
            <div className="modal-box">
              <p className='text-base font-semibold mb-5'>Start Workspace</p>
              <div className='bg-gray-200 h-0.5 w-full'></div>
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
              <div className='mt-4 flex flex-col gap-y-5'>
                <div className='flex gap-x-4'>
                  <p>CPU</p>
                  <input type='radio' name='workspaceType' value="CPU" 
          checked={workspaceTyp === 'CPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                </div>
                <div className='flex gap-x-4'>
                  <p>GPU</p>
                  <input type='radio' name='workspaceType' value="GPU" 
          checked={workspaceTyp === 'GPU'} onChange={(e) => setWorkspaceTyp(e.target.value)} />
                </div>
                <div className='flex justify-between'>
                  <button className='btn'  onClick={()=> {
                    document.getElementById('modalLaunch').showModal()
                    }}>
                      Launch
                  </button>
                  <form method="dialog">
                    <button className='btn'>No</button>
                  </form>
                </div>
              </div>
            </div>
          </dialog> */}
          <dialog id="modalshare" className="modal z-10">
            <div className="modal-box">
              <p className='text-base font-semibold mb-5'>Share Workspace</p>
              <div className='bg-gray-200 h-0.5 w-full'></div>
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
              <div className='mt-4 flex flex-col gap-y-5'>
                <div className='flex flex-col gap-y-2'>
                  <p>Email Address:</p>
                  <input type='email' className='text-sm border p-1 rounded-md' placeholder='Enter email address of the user you want to share the workspace with'
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  />
                </div>
                <div className='flex flex-col gap-y-4'>
                  <div className='flex gap-x-2'>
                    <input type="checkbox" className="toggle toggle-primary toggle-md" />
                    <p>Start Workspace</p>
                  </div>
                  <div className='flex gap-x-2'>
                    <input type="checkbox" className="toggle toggle-primary toggle-md"/>
                    <p>Stop Workspace</p>
                  </div>
                  <div className='flex gap-x-2'>
                    <input type="checkbox" className="toggle toggle-primary toggle-md"/>
                    <p>Publish Notebooks/Analysis</p>
                  </div>
                  <div className='flex gap-x-2'>
                    <input type="checkbox" className="toggle toggle-primary toggle-md" />
                    <p>Allow SFTP</p>
                  </div>
                  <div className='flex gap-x-2'>
                    <input type="checkbox" className="toggle toggle-primary toggle-md" />
                    <p>Copy Notebooks</p>
                  </div>
                </div>
                <p className='flex gap-x-2 my-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 -mt-3">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>

                    <p className=' text-xs font-semibold'>Sharing your workspace also shares access to all of your data, and your scratch area. 
                    Please ensure the team member you are sharing with should have access to this data.</p>
                </p>
                <div className='flex justify-between'>
                  <button className='btn' onClick={handleShareWorkspace}>Share</button>
                  <form method="dialog">
                    <button className='btn'>Cancel</button>
                  </form>
                </div>
              </div>
            </div>
          </dialog>
        
          <dialog id="modalstop" className="modal">
            <div className="modal-box">
              <p className='text-base font-semibold mb-5'>Stop Workspace</p>
              <div className='bg-gray-200 h-0.5 w-full'></div>
              <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
              <div className='mt-4 flex flex-col gap-y-5'>
                <p>Are you sure you want to stop your workspace? Stopping your workspace will
                  remove any custom configuration changes you have made. All of your uploaded 
                  data is backed up and restored upon resuming your workspace.
                </p>
                <p>To restore changes you've made to your workspace, save all of your configuration
                   code, including package loading commands, to a Jupyter Notebook. Then, run that
                    notebook after resuming your workspace.
                </p>
                <p><span className='font-bold'>Note: </span>The above applies only to Auto Scaling Clusters. GPU instance configuration are
                   fully restored after resumingwithout any action needed on your part.
                </p>
                <div className='flex justify-between'>
                  <button className='btn' onClick={handleStopWorkspace}>Yes</button>
                  <form method="dialog">
                    <button className='btn'>No</button>
                  </form>
                </div>
              </div>
            </div>
          </dialog>
      </div>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Dataset Name</th>
              <th>Data Documentation Link</th>
              <th>Data Dictionary</th>
              <th>Coding Instructions</th>
              <th>Analysis Guideslines</th>
            </tr>
          </thead>
          <tbody>
            {currentFiles.length > 0 ? (
            currentFiles.map((file, index) => (
              <tr key={index}>
                <td>{file.dataset_name}</td>
                <td>{file.data_documentation_link}</td>
                <td>{file.data_dictionary}</td>
                <td>{file.coding_instructions}</td>
                <td>{file.analysis_guidelines}</td>
            </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No datasets found</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      <div className="join mt-8 justify-end">
            {Array.from({length: totalPages}, (_, i) => (
                <button key={i+1} className={`join-item btn ${currentPage === i + 1 ? 'btn-active' : '' }`}
                    onClick={() => handlePageChange(i+1)}
                >
                    {i+1}
                </button>

            ))}
        </div>
    </div>
  )
}


export default Compute