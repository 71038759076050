import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import FlowCanvas from './FlowCanvas';
import { useReactFlow } from '@xyflow/react';
import { createBlueprint, deleteBlueprint, downloadBlueprint, listBlueprintsAPI, retrieveBlueprintAPI, updateBlueprint } from '../services/operations/blueprintAPI';
import { createMetadataAPI, deleteMetadataAPI, retrieveMetadataAPI } from '../services/operations/metadataAPI';
import { clearFlow } from '../redux/flowSlice';

const initialVersion = '0.0.1';

const Workflow = () => {
    const [version, setVersion] = useState(initialVersion);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const nav = useNavigate();
    const { isLoggedIn, username } = useSelector((state) => state.auth);
    console.log(username);

    useEffect(() => {
      if(!isLoggedIn) {
          nav('/login');
      }
  }, [isLoggedIn, nav]);

  const [isOpen, setIsOpen] = useState(false); // State to control modal visibility
   const [search, setSearch] = useState("");
   const [blueprints, setBlueprints] = useState([]);
   const [ metadataResponse, setMetadataResponse ] = useState(null);
   const [isDropdownVisible, setIsDropdownVisible] = useState(false);
   const [loading, setLoading] = useState(true);
   const dropdownRef = useRef(null);

   const [step, setStep] = useState(0);

    const [envName, setEnvName] = useState("");
    const [envDesc, setEnvDesc] = useState("");
    const [connection, setConnection] = useState("");
    const [provider, setProvider] = useState("");
    const [awsRegion, setAwsRegion] = useState("");
    const [deployName, setDeployName] = useState("");
    const [deployDesc, setDeployDesc] = useState("");
    const [email, setEmail] = useState("");

    const [filename, setFilename] = useState("");

    const { setNodes, setEdges, getNodes, getEdges } = useReactFlow();

    const handleOpenModal1 = () => {
      document.getElementById('file_name_modal').showModal();
    };
  
    const handleOpenModal2 = () => {
      document.getElementById('delete_file_modal').showModal();
    };
  
    const handleSaveFilename = () => {
      setFilename(filename);
      document.getElementById('file_name_modal').close();
      console.log("Filename saved:", filename);
      handleCreate(filename);
    };

    const incrementVersion = (currentVersion) => {
      if (!currentVersion) {
        console.error("Version is undefined or invalid");
        return "0.0.1"; // Default starting version if undefined
      }
      const versionParts = currentVersion.toString().split('.').map(Number);
  
      versionParts[2] += 1;
  
      return versionParts.join('.');
    };

    // create blueprint and metadata
    const handleCreate = useCallback(async(filename) => {
      const nodes = getNodes();
      const edges = getEdges();
      
      const content = { 
        name: filename,
        version: initialVersion,
        nodes, 
        edges
      };
      console.log(content);

      if (!filename || !username) {
        console.log(filename);
        console.log(username);
        console.error("Filename or username is missing.");
        return;
      }

      try {
        const response = await createBlueprint(username, filename, content)
            if (response && response.url) {
                console.log('Blueprint URL:', response.url);
                window.open(response.url, '_blank');
            }
        }
        catch(error)  {
            console.error("Error creating blueprint:", error);
        };
        
        const metadataContent = {
          name: filename,
          version: initialVersion
        }

        // metadata getting created everytime a new blueprint gets created
        try {
          const metadataResponse = await createMetadataAPI(username, filename, metadataContent);
          metadataResponse();
        } catch (error) {
            console.log("Error creating metadata:", error);
        }

      }, [getNodes, getEdges, filename, username]);

      // update blueprint
      const handleUpdate = useCallback(() => {
        const nodes = getNodes()
        const edges = getEdges()
        
      const newVersion = incrementVersion(version);
        
        const content = { 
          name: filename,
          version: newVersion,
          nodes, 
          edges 
        };
        console.log(content);
  
        if (!filename || !username) {
          console.log(filename);
          console.log(username);
          console.error("Filename or username is missing.");
          return;
        }
  
        updateBlueprint(username, filename, content)
          .then((response) => {
              console.log('Blueprint Updated Successfully');
              setFilename(filename)
              setVersion(version)

              retrieveBlueprintAPI(username, filename)
              retrieveMetadataAPI(username, filename)
              // setFilename(response.name)
              // setVersion(response.version)
          })
          .catch((error) => {
              console.error("Error updating blueprint: ", error);
          });
        }, [getNodes, getEdges, filename, username]);


    // download
    const handleDownload = useCallback(async () => {
      if(!filename){
        alert("Please provide a filename");
        return;
      }

      if(!username){
        console.log("username is missing");
        return;
      }
      downloadBlueprint(username, filename);
    }, [username, filename]);

    // delete blueprint
    const handleDelete = useCallback(() => {
      if(!filename){
        alert("No file selected")
      }

      if(!filename || !username){
        console.error("Filename or username is missing");
        return;
      }

      const deleteFunc = deleteBlueprint(username, filename);
      deleteFunc().then(() => {
        document.getElementById('delete_file_modal').close()
        console.log('Deleted Successfully');
        setNodes([]);
        setEdges([]);
        setFilename('');
        listBlueprintsAPI(username)
        }
      ).catch(error => {
        console.log(error)
      })
    }, [filename, username, setNodes, setEdges,])

    

    useEffect(() => {
      const fetchBlueprints = async () => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Simulating loading time
        // Here, set the blueprints with your actual fetching logic
        setBlueprints([]); // Update this to your fetched data
        setLoading(false);
      };
      fetchBlueprints();
    }, []);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownVisible(false); // Hide dropdown if clicked outside
        }
      };
  
      setBlueprints([]);
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    // list blueprints
    const handleList = useCallback(async () => {
      if (!username) {
        console.error("username is missing");
        return;
      }
    
      try {
        setLoading(true);
        const result = await listBlueprintsAPI(username);
        if (result && result.length > 0) {
          setBlueprints(result);
          console.log(result);
        } else {
          setBlueprints([]);
          console.log("No Blueprints found");
        }
      } catch (error) {
        console.error("Error fetching blueprints:", error);
      } finally {
        setLoading(false);
        setIsDropdownVisible(true);
      }
    }, [username]);
    
    const handleSelectBlueprint = (blueprint) => {
      setSearch(blueprint);

      retrieveBlueprintAPI(username, blueprint)
      .then((result) => {
        console.log(result);

        if(result && result.nodes && result.edges) {
          setNodes(result.nodes);
          setEdges(result.edges);
          setFilename(result.name)
          setVersion(result.version)
          
          retrieveMetadataAPI(username, result.name)
                    .then((metadata) => {
                        setMetadataResponse(metadata);
                        console.log("Retrieve Metadata API response: ", metadata); // Now logging the resolved metadata
                    })
                    .catch((error) => {
                        console.error("Error retrieving metadata:", error);
                    });

          console.log("Flow data imported successfully.");
        } else {
            console.error("Invalid JSON structure.");
            console.log("Blueprint and metadata retrieved and displayed successfully.");
        } 
      })
        .catch((error) => {
          console.error("Error retrieving blueprint: ", error);
        });

      setIsDropdownVisible(false);
    };

    // search
    const handleSearch = useCallback(() => {
      if(!search || !username){
        console.error("Keyword or username is missing");
        return;
      }

      const searchFunc = retrieveBlueprintAPI(search, username);
      searchFunc().then(result => {
        setBlueprints(result);
      });

    }, [search, username])

    // upload
    const handleImport = (e) => {
      const file = e.target.files[0];
      if(!file) {
        console.error("No file selected");
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);

          if(json.nodes && json.edges) {
            setNodes(json.nodes);
            setEdges(json.edges);
            console.log("Flow data imported successfully.");
          } else {
            console.error("Invalid JSON structure.");
          }
        } catch(error) {
          console.error("Failed to parse JSON file: ", error);
        }
      };
      reader.readAsText(file);
    }

    const triggerFileInput = () => {
      document.getElementById('fileInput').click();
    }

  return (
    <div className='-mt-4'>
        <div className='flex flex-col mx-auto'>
          <div className='flex justify-between w-full rounded-md bg-white p-2'>
            <div className='xs:flex xs:w-[80%] xs:justify-around'>
             <div class="flex items-center gap-x-1">
                <div className="dropdown">
                  <input
                    tabIndex={0} role="button" 
                    required={true}
                    type='text'
                    name='text'
                    value={search} 
                    onClick={handleList}
                    onChange={(e) => setSearch(e.target.value)}
                    className='p-2 w-60 text-sm border px-2 rounded-md'
                    placeholder='Search files..'
                    isDropdownVisible={true}
                  />
                  {isDropdownVisible && (
                      <ul tabIndex={0} className="dropdown-content flex flex-col z-10 overflow-y-auto bg-base-100 rounded-box w-56 p-2 shadow absolute mt-1  text-black border max-h-48 text-sm">
                        {loading ? (
                          <li className="p-2 text-gray-500">Loading...</li> // Loading text
                        ) : blueprints.length > 0 ? (
                          blueprints.map((blueprint) => (
                            <li key={blueprint.id} onClick={() => handleSelectBlueprint(blueprint.replace('.json', ''))}>
                              <p className="p-2 hover:bg-gray-100 cursor-pointer">
                                {blueprint.replace('.json', '')}
                              </p>
                            </li>
                          ))
                        ) : (
                          <li className="p-2 text-gray-500">No Blueprints Created</li> // No blueprints text
                        )}
                      </ul>
                    )}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg>
                <div className='font-semibold'>
                  {version}
                </div>   
               </div>
            </div> 
              
             <div className='xs:hidden md:flex md:flex-row md:gap-x-2'>
              {filename? (
                  <div id='filename' className=' md:block xs:hidden font-semibold p-1 rounded-md'>{`${filename}.deploybydesign`}</div>
              ): ('')}
                <button className="p-2 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer" onClick={handleOpenModal1}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9" />
                </svg>
                </button>
                <dialog id="file_name_modal" className="modal">
                  <div className="modal-box">
                    <h3 className="text-lg mb-2">Enter File Name</h3>
                    <input
                      type="text"
                      value={filename}
                      onChange={(e) => setFilename(e.target.value)}
                      className="border rounded-md p-2 w-full"
                      placeholder="Enter file name..."
                    />
                    <div className="modal-action">
                      <button className="p-2 bg-blue-500 text-white rounded-md" onClick={handleSaveFilename}>
                        Create
                      </button>
                      <button className="p-2 bg-gray-500 text-white rounded-md" onClick={() => document.getElementById('file_name_modal').close()}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </dialog>
              <button onClick={handleUpdate} className='p-2 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </button>
                
             <div onClick={handleDownload} className='p-2 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
              </div>
              <div className='p-1 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer'>
              <input
                  type='file'
                  id='fileInput'
                  onChange={handleImport}
                  accept='application/json' 
                  className='hidden'/>

                <div onClick={triggerFileInput} className='p-1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                  </svg>
                </div>
              </div>
             
             <div className='p-2 flex gap-x-1 cursor-pointer bg-gray-50 hover:bg-gray-100 rounded-md' onClick={() => document.getElementById('my_modal_5').showModal()} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                </svg>
             </div>
             <dialog id="my_modal_5" className="modal z-20 modal-bottom sm:modal-middle">
                <div className="modal-box">
                     <div className='flex justify-between items-center'>
                      <div className='flex flex-col'>
                        <h3 className="text-sm">Review and Deploy</h3>
                        <p className='text-gray-600 text-xs'>{filename ? (`${filename}.deploybydesign`) : ('')}</p>
                    </div>
                    <div className="modal-action">
                    <form method="dialog">
                    <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                      </button>
                     </form>
                     </div>
                     </div>
                     <ul className="steps mt-6 font-semibold mb-7 w-full">
                      <li className={`text-sm cursor-pointer step ${step === 0 ? 'step-primary' : ''}`} onClick={() => setStep(0)}>Environment</li>
                      <li className={`text-sm cursor-pointer step ${step === 1 ? 'step-primary' : ''}`} onClick={() => setStep(1)}>Deployment</li>
                      <li className={`text-sm cursor-pointer step ${step === 2 ? 'step-primary' : ''}`} onClick={() => setStep(2)}>Notification</li>
                    </ul>
                  <div className="p-5 mt-5">
                     {/* Env  */}
                    {step === 0 && (
                      <form class="w-[90%] mx-auto"> 
                        <div class="flex gap-x-8">
                            <div class="relative text-sm z-0 w-full mb-8 group">
                                <input type="text" name="envName" value={envName} onChange={(e) => setEnvName(e.target.value)} id="first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="envName" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Environment Name</label>
                            </div>
                            <div class="relative z-0 w-full mb-8 group">
                                <input type="text" name="envDesc" value={envDesc} onChange={(e) => setEnvDesc(e.target.value)} id="last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="envDesc" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description</label>
                            </div>
                          </div>
                          <div className='flex gap-x-8'>
                          <div class="relative z-0 w-full mb-8 group">
                              <input type="text" name="connection" id="connection" value={connection} onChange={(e) => setConnection(e.target.value)} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                              <label for="connection" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Connection</label>
                          </div>
                          <div class="relative z-0 w-full mb-8 group">
                              <input type="text" name="provider" value={provider} onChange={(e) => setProvider(e.target.value)} id="provider" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                              <label for="provider" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Provider</label>
                          </div>
                          </div>
                        
                          <div class="flex gap-x-8">
                            <div class="relative z-0 w-full mb-8 group">
                                <input type="text" name="awsRegion" value={awsRegion} onChange={(e) => setAwsRegion(e.target.value)} id="awsRegion" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="awsRegion" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">AWS Region</label>
                            </div>
                          </div>                          
                          <div className='mt-[6%] flex justify-between'>
                            <div></div>
                            <button className='btn bottom-0' onClick={()=> setStep(1)}>Next</button>
                          </div>
                        </form>
                    )}
                     {/* Deploy */}
                    {step === 1 && (
                    <form class="w-[90%] mx-auto"> 
                    <div class="flex gap-x-8">
                        <div class="relative text-sm z-0 w-full mb-8 group">
                            <input type="text" name="deployName" value={deployName} onChange={(e) => setDeployName(e.target.value)} id="first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="deployName" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Deployment Name</label>
                        </div>
                       
                      </div>
                      <div className='flex gap-x-8'>
                      <div class="relative z-0 w-full mb-8 group">
                            <input type="text" name="deployDesc" value={deployDesc} onChange={(e) => setDeployDesc(e.target.value)} id="last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="deployDesc" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Deployment Description</label>
                        </div>
                      </div>
                      <div>
                        <div className='flex mt-[25%] justify-between'>
                          <button className='btn' onClick={()=> setStep(1)}>Back</button>
                          <button className='btn' onClick={()=> setStep(2)}>Next</button>
                        </div>
                      </div>
                    </form>
                    )}
                     {/* Notif  */}
                    {step === 2 && (
                      <form class="w-[90%] mx-auto"> 
                      <div class="flex gap-x-8">
                       {/* onChange={(e) => setEmail(e.target.value)}  */}
                          <div class="relative text-sm z-0 w-full mb-8 group">
                              <input type="email" name="email" value={email}  id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                              <label for="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                          </div>
                        </div>

                        <div className='flex mt-[25%] justify-between'>
                            <button className='btn' onClick={()=> setStep(2)}>Back</button>
                            <button
                            data-modal-hide="small-modal"
                            type="button"
                            className="btn"
                            onClick={() => setIsOpen(false)}
                          >
                            Deploy
                          </button>
                        </div>                        
                      </form>    
                    )}
                 </div>
               </div>
             </dialog>

             <div onClick={handleOpenModal2} className='p-2 bg-gray-50 cursor-pointer text-sm hover:bg-gray-100 rounded-md'>
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>

            </div>
            <dialog id="delete_file_modal" className="modal">
                  <div className="modal-box">
                    <h3 className="text-lg mb-2">Are you sure you want to delete the blueprint ?</h3>
                    <div className="modal-action">
                      <button className="p-2 bg-blue-500 text-white rounded-md" onClick={handleDelete}>
                        Yes
                      </button>
                      <button className="p-2 bg-gray-500 text-white rounded-md" onClick={() => document.getElementById('delete_file_modal').close()}>
                        No
                      </button>
                    </div>
                  </div>
                </dialog>
             </div>
             
          </div>
          <div className='dndflow-wrapper flex'>
          <button
             onClick={() => setIsCollapsed(!isCollapsed)}
              className="absolute -mt-11 left-2 bg-gray-50 hover:bg-gray-100 rounded-md p-2 xs:block"
            >
              {isCollapsed ? 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
              </svg>
              : 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              }
            </button>
              <div className={`${isCollapsed ? 'w-0  xs:w-[50%]  md:w-[25%]' : ''} md:block`}>
                {isCollapsed && <Sidebar/>}
              </div>
              <FlowCanvas className={`flex-1 ${isCollapsed ? 'w-full' : ''}`} filename={filename} version={version} metadataResponse={metadataResponse}/>
              {/* xs:flex xs:flex-col xs:sticky xs:right-0 xs:top-50  md:flex md:flex-row md:gap-x-2 */}
              <div className='xs:block md:hidden xs:flex xs:flex-col xs:sticky xs:right-0 gap-y-2 xs:h-full justify-center items-center'>
                <button className="p-2 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer" onClick={handleOpenModal1}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9" />
                </svg>
                </button>
                <dialog id="file_name_modal" className="modal">
                  <div className="modal-box">
                    <h3 className="text-lg mb-2">Enter File Name</h3>
                    <input
                      type="text"
                      value={filename}
                      onChange={(e) => setFilename(e.target.value)}
                      className="border rounded-md p-2 w-full"
                      placeholder="Enter file name..."
                    />
                    <div className="modal-action">
                      <button className="p-2 bg-blue-500 text-white rounded-md" onClick={handleSaveFilename}>
                        Create
                      </button>
                      <button className="p-2 bg-gray-500 text-white rounded-md" onClick={() => document.getElementById('file_name_modal').close()}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </dialog>
              <button onClick={handleUpdate} className='p-2 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </button>
                
             <div onClick={handleDownload} className='p-2 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
              </div>
              <div className='p-1 text-sm bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer'>
              <input
                  type='file'
                  id='fileInput'
                  onChange={handleImport}
                  accept='application/json' 
                  className='hidden'/>

                <div onClick={triggerFileInput} className='p-1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                  </svg>
                </div>
              </div>
             
             <div className='p-2 flex gap-x-1 cursor-pointer bg-gray-50 hover:bg-gray-100 rounded-md' onClick={() => document.getElementById('my_modal_5').showModal()} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                </svg>
             </div>
             <dialog id="my_modal_5" className="modal z-20 modal-bottom sm:modal-middle">
                <div className="modal-box">
                     <div className='flex justify-between items-center'>
                      <div className='flex flex-col'>
                        <h3 className="text-sm">Review and Deploy</h3>
                        <p className='text-gray-600 text-xs'>{filename ? (`${filename}.deploybydesign`) : ('')}</p>
                    </div>
                    <div className="modal-action">
                    <form method="dialog">
                    <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                      </button>
                     </form>
                     </div>
                     </div>
                     <ul className="steps mt-6 font-semibold mb-7 w-full">
                      <li className={`text-sm cursor-pointer step ${step === 0 ? 'step-primary' : ''}`} onClick={() => setStep(0)}>Environment</li>
                      <li className={`text-sm cursor-pointer step ${step === 1 ? 'step-primary' : ''}`} onClick={() => setStep(1)}>Deployment</li>
                      <li className={`text-sm cursor-pointer step ${step === 2 ? 'step-primary' : ''}`} onClick={() => setStep(2)}>Notification</li>
                    </ul>
                  <div className="p-5 mt-5">
                     {/* Env  */}
                    {step === 0 && (
                      <form class="w-[90%] mx-auto"> 
                        <div class="flex gap-x-8">
                            <div class="relative text-sm z-0 w-full mb-8 group">
                                <input type="text" name="envName" value={envName} onChange={(e) => setEnvName(e.target.value)} id="first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="envName" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Environment Name</label>
                            </div>
                            <div class="relative z-0 w-full mb-8 group">
                                <input type="text" name="envDesc" value={envDesc} onChange={(e) => setEnvDesc(e.target.value)} id="last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="envDesc" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description</label>
                            </div>
                          </div>
                          <div className='flex gap-x-8'>
                          <div class="relative z-0 w-full mb-8 group">
                              <input type="text" name="connection" id="connection" value={connection} onChange={(e) => setConnection(e.target.value)} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                              <label for="connection" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Connection</label>
                          </div>
                          <div class="relative z-0 w-full mb-8 group">
                              <input type="text" name="provider" value={provider} onChange={(e) => setProvider(e.target.value)} id="provider" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                              <label for="provider" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Provider</label>
                          </div>
                          </div>
                        
                          <div class="flex gap-x-8">
                            <div class="relative z-0 w-full mb-8 group">
                                <input type="text" name="awsRegion" value={awsRegion} onChange={(e) => setAwsRegion(e.target.value)} id="awsRegion" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="awsRegion" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">AWS Region</label>
                            </div>
                          </div>                          
                          <div className='mt-[6%] flex justify-between'>
                            <div></div>
                            <button className='btn bottom-0' onClick={()=> setStep(1)}>Next</button>
                          </div>
                        </form>
                    )}
                     {/* Deploy */}
                    {step === 1 && (
                    <form class="w-[90%] mx-auto"> 
                    <div class="flex gap-x-8">
                        <div class="relative text-sm z-0 w-full mb-8 group">
                            <input type="text" name="deployName" value={deployName} onChange={(e) => setDeployName(e.target.value)} id="first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="deployName" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Deployment Name</label>
                        </div>
                       
                      </div>
                      <div className='flex gap-x-8'>
                      <div class="relative z-0 w-full mb-8 group">
                            <input type="text" name="deployDesc" value={deployDesc} onChange={(e) => setDeployDesc(e.target.value)} id="last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label for="deployDesc" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Deployment Description</label>
                        </div>
                      </div>
                      <div>
                        <div className='flex mt-[25%] justify-between'>
                          <button className='btn' onClick={()=> setStep(1)}>Back</button>
                          <button className='btn' onClick={()=> setStep(2)}>Next</button>
                        </div>
                      </div>
                    </form>
                    )}
                     {/* Notif  */}
                    {step === 2 && (
                      <form class="w-[90%] mx-auto"> 
                      <div class="flex gap-x-8">
                       {/* onChange={(e) => setEmail(e.target.value)}  */}
                          <div class="relative text-sm z-0 w-full mb-8 group">
                              <input type="email" name="email" value={email}  id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                              <label for="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                          </div>
                        </div>

                        <div className='flex mt-[25%] justify-between'>
                            <button className='btn' onClick={()=> setStep(2)}>Back</button>
                            <button
                            data-modal-hide="small-modal"
                            type="button"
                            className="btn"
                            onClick={() => setIsOpen(false)}
                          >
                            Deploy
                          </button>
                        </div>                        
                      </form>    
                    )}
                 </div>
               </div>
             </dialog>

             <div onClick={handleOpenModal2} className='p-2 bg-gray-50 cursor-pointer text-sm hover:bg-gray-100 rounded-md'>
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>

            </div>
            <dialog id="delete_file_modal" className="modal">
                  <div className="modal-box">
                    <h3 className="text-lg mb-2">Are you sure you want to delete the blueprint ?</h3>
                    <div className="modal-action">
                      <button className="p-2 bg-blue-500 text-white rounded-md" onClick={handleDelete}>
                        Yes
                      </button>
                      <button className="p-2 bg-gray-500 text-white rounded-md" onClick={() => document.getElementById('delete_file_modal').close()}>
                        No
                      </button>
                    </div>
                  </div>
                </dialog>
             </div>
          </div>
       </div>
    </div>
  )
}


export default Workflow
