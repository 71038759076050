import { randomString } from './client-utils';
// import { AccessToken, AccessTokenOptions, VideoGrant } from 'livekit-server-sdk';

const API_KEY = process.env.REACT_APP_LIVEKIT_API_KEY;
const API_SECRET = process.env.REACT_APP_LIVEKIT_API_SECRET;
const LIVEKIT_URL = process.env.REACT_APP_LIVEKIT_URL;
const COOKIE_KEY = 'random-participant-postfix';

export async function getConnectionDetails(roomName, participantName, metadata = '', region = null) {
    try {
        // const livekitServerUrl = region ? getLiveKitURL(region) : LIVEKIT_URL;
        const livekitServerUrl = LIVEKIT_URL;
        // let randomParticipantPostfix = localStorage.getItem(COOKIE_KEY);
        if (!livekitServerUrl) {
            throw new Error('Invalid region');
        }
        if (!roomName) {
            throw new Error('Missing required parameter: roomName');
        }
        if (!participantName) {
            throw new Error('Missing required parameter: participantName');
        }

        // Generate participant token
        // if (!randomParticipantPostfix) {
        //     randomParticipantPostfix = randomString(4);
        //     localStorage.setItem(COOKIE_KEY, randomParticipantPostfix);
        // }
        // const participantToken = await createParticipantToken(
        //     {
        //         identity: `${participantName}__${randomParticipantPostfix}`,
        //         name: participantName,
        //         metadata,
        //     },
        //     roomName
        // );
        // replace it with Dynamic when Backend is ready
        const participantToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NDIyMjM0NTAsImlzcyI6IkFQSUt5emR1UEI1eW5aaiIsIm5iZiI6MTc0MjIxNjI1MCwic3ViIjoicXVpY2tzdGFydCB1c2VyIHYxYXZ0ayIsInZpZGVvIjp7ImNhblB1Ymxpc2giOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsInJvb20iOiJxdWlja3N0YXJ0IHJvb20iLCJyb29tSm9pbiI6dHJ1ZX19.TGjtNN-EfFdOr8n-Jm1uVDs-SlcjrkmJeE_Sok2SM98'

        // Return connection details 
        return {
            serverUrl: livekitServerUrl,
            roomName,
            participantToken,
            participantName,
        };
    } catch (error) {
        console.error('Error fetching connection details:', error);
        throw error;
    }
}

// function createParticipantToken(userInfo, roomName) {
//     const at = new AccessToken(API_KEY, API_SECRET, userInfo);
//     at.ttl = '5m';
//     const grant = {
//         room: roomName,
//         roomJoin: true,
//         canPublish: true,
//         canPublishData: true,
//         canSubscribe: true,
//     };
//     at.addGrant(grant);
//     return at.toJwt();
// }
