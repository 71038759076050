import React, { useState } from 'react';

const Sidebar = () => {
  const categories = {
    '': [
      { id: '14', label: 'user input' },
    ],
    Databases: [
      { id: '1', label: 'mongodb' },
      { id: '2', label: 'mysql' },
      { id: '3', label: 'postgres' },
      { id: '4', label: 's3 bucket' },
    ],
    Models: [
      { id: '5', label: 'anthropic claude v2' },
      { id: '6', label: 'openai chatgpt 4.0' },
      { id: '7', label: 'cohere' }
    ],
    EmbeddingModels: [
      { id: '8', label: 'amazon titan text embedding' },
      { id: '9', label: 'cohere embeddings' }
    ],
    Indexing: [
      { id: '10', label: 'elasticsearch' },
      { id: '11', label: 'opensearch cluster' },
      { id: '12', label: 'opensearch serverless' },
    ],
    Cache: [
      { id: '13', label: 'redis' },
    ],
  };

  function capitalizeFirstLetter(word) {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const onDragStart = (e, nodeLabel, category) => {
    e.dataTransfer.setData('application/reactflow', JSON.stringify({ label: nodeLabel, category }));
    e.dataTransfer.effectAllowed = 'move';
  };

  const [search, setSearch] = useState("");

  const filteredCategories = Object.keys(categories).reduce((acc, category) => {
    const filteredNodes = categories[category].filter((node) =>
      node.label.toLowerCase().includes(search.toLowerCase())
    );
    if (filteredNodes.length > 0) {
      acc[category] = filteredNodes;
    }
    return acc;
  }, {});

  const isEmpty = Object.keys(filteredCategories).length === 0;

  return (
    <aside>
      <div className='font-semibold -mt-4 mb-4'>Resources</div>
      
      <div className="w-fit mx-auto flex mb-3 -mt-2 items-center gap-x-2">
        <input
          required={true}
          type='text'
          name='text'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='p-1 w-full text-sm border px-2 rounded-md'
          placeholder='Search resources...'
        />
      </div>
      <div className='h-[70%] overflow-y-scroll' id='scrollbar'>
        {isEmpty ? (
          <div className="text-center text-gray-500">No resource found</div>
        ) : (
          Object.keys(filteredCategories).map((category) => (
            <div key={category} className="mb-2">
              <h3 className="text-xs text-gray-500 mb-2">{capitalizeFirstLetter(category)}</h3>
              {filteredCategories[category].map((node) => (
                <div
                  key={node.id}
                  className="flex justify-center gap-x-1 dndnode rounded-md text-sm"
                  onDragStart={(event) => onDragStart(event, node.label, category)}
                  draggable
                >
                  {capitalizeFirstLetter(node.label)}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
